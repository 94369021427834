export default class Identity {
  constructor({ name, adminID = null, operatorID = null }) {
    this._name = name;
    this._adminID = adminID;
    this._operatorID = operatorID;
  }

  get adminID() {
    return this._adminID;
  }

  get operatorID() {
    return this._operatorID;
  }

  get operatorFlag() {
    return !!this._operatorID;
  }

  get name() {
    return this._name;
  }

  set name(val) {
    this._name = val;
  }
}
