import Alert from 'Components/Alert';
import { show, hide } from 'Components/domHelpers';
import { wrapSubmitHandler } from 'Components/FormComponents';

import Subpage from './Subpage';
import SubpagePanel from './SubpagePanel';
import EmailBridgeInfoForm from './EmailBridgeInfoForm';

import s from './strings';

export default class EmailBridgeInfo extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
      },

      options: {
      }
    };
  }

  init() {
    const root = (
      <div class="subpage">
        <div class="subpage-content">
          <SubpagePanel title={s.lblEmailMyInfo}>
            <Alert type="success" ref={this._successMessage}>{s.lblEmailSent}</Alert>

            <form onsubmit={wrapSubmitHandler(() => this._form.send())}>
              <EmailBridgeInfoForm onSuccess={() => show(this._successMessage)} ref={this._form} />
              <div class="btn-toolbar-discrete">
                <button type="submit" class="btn btn-primary ml-auto">{s.lblSend}</button>
              </div>
            </form>
          </SubpagePanel>
        </div>
      </div>
    );

    super.init(root);
  }

  activate() {
    return this.ctrl.fetchBridgeData()
      .then(bridgeData => {
        hide(this._successMessage);
        this._form.render();
      });
  }
}
