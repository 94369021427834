import sessionStorage from './sessionStorage';
import localStorage from './localStorage';
import ClientStorageObject from './ClientStorageObject';

const SESSION_ONLY_KEY = '__sessionOnly';

export default class ClientStorage {
  static read(key) {
    return this.storage.read(key);
  }

  static readJSON(key) {
    return this.storage.readJSON(key);
  }

  static write(key, value) {
    this.storage.write(key, value);
  }

  static writeJSON(key, value) {
    this.storage.writeJSON(key, value);
  }

  static delete(key) {
    this.storage.delete(key);
  }

  static get storage() {
    return this.sessionOnly
      ? this.sessionStorage
      : this.localStorage;
  }

  static get sessionOnly() {
    return !!sessionStorage[SESSION_ONLY_KEY];
  }

  static set sessionOnly(flag) {
    if (flag)
      sessionStorage[SESSION_ONLY_KEY] = '1';
    else
      delete sessionStorage[SESSION_ONLY_KEY];
  }
}

ClientStorage.localStorage = new ClientStorageObject(localStorage);
ClientStorage.sessionStorage = new ClientStorageObject(sessionStorage);
