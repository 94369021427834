export const KEYCODE_BACKSPACE = 8;
export const KEYCODE_TAB = 9;
export const KEYCODE_ENTER = 13;
export const KEYCODE_SHIFT = 16;
export const KEYCODE_CTRL = 17;
export const KEYCODE_ALT = 18;
export const KEYCODE_PAUSE = 19;
export const KEYCODE_CAPS_LOCK = 20;
export const KEYCODE_ESCAPE = 27;
export const KEYCODE_SPACE = 32;
export const KEYCODE_PAGE_UP = 33;
export const KEYCODE_PAGE_DOWN = 34;
export const KEYCODE_END = 35;
export const KEYCODE_HOME = 36;
export const KEYCODE_LEFT = 37;
export const KEYCODE_UP = 38;
export const KEYCODE_RIGHT = 39;
export const KEYCODE_DOWN = 40;
export const KEYCODE_INSERT = 45;
export const KEYCODE_DELETE = 46;
export const KEYCODE_0 = 48;
export const KEYCODE_1 = 49;
export const KEYCODE_2 = 50;
export const KEYCODE_3 = 51;
export const KEYCODE_4 = 52;
export const KEYCODE_5 = 53;
export const KEYCODE_6 = 54;
export const KEYCODE_7 = 55;
export const KEYCODE_8 = 56;
export const KEYCODE_9 = 57;
export const KEYCODE_A = 65;
export const KEYCODE_B = 66;
export const KEYCODE_C = 67;
export const KEYCODE_D = 68;
export const KEYCODE_E = 69;
export const KEYCODE_F = 70;
export const KEYCODE_G = 71;
export const KEYCODE_H = 72;
export const KEYCODE_I = 73;
export const KEYCODE_J = 74;
export const KEYCODE_K = 75;
export const KEYCODE_L = 76;
export const KEYCODE_M = 77;
export const KEYCODE_N = 78;
export const KEYCODE_O = 79;
export const KEYCODE_P = 80;
export const KEYCODE_Q = 81;
export const KEYCODE_R = 82;
export const KEYCODE_S = 83;
export const KEYCODE_T = 84;
export const KEYCODE_U = 85;
export const KEYCODE_V = 86;
export const KEYCODE_W = 87;
export const KEYCODE_X = 88;
export const KEYCODE_Y = 89;
export const KEYCODE_Z = 90;
export const KEYCODE_LEFT_META = 91;
export const KEYCODE_RIGHT_META = 92;
export const KEYCODE_SELECT = 93;
export const KEYCODE_NUMPAD_0 = 96;
export const KEYCODE_NUMPAD_1 = 97;
export const KEYCODE_NUMPAD_2 = 98;
export const KEYCODE_NUMPAD_3 = 99;
export const KEYCODE_NUMPAD_4 = 100;
export const KEYCODE_NUMPAD_5 = 101;
export const KEYCODE_NUMPAD_6 = 102;
export const KEYCODE_NUMPAD_7 = 103;
export const KEYCODE_NUMPAD_8 = 104;
export const KEYCODE_NUMPAD_9 = 105;
export const KEYCODE_MULTIPLY = 106;
export const KEYCODE_ADD = 107;
export const KEYCODE_SUBTRACT = 109;
export const KEYCODE_DECIMAL = 110;
export const KEYCODE_DIVIDE = 111;
export const KEYCODE_F1 = 112;
export const KEYCODE_F2 = 113;
export const KEYCODE_F3 = 114;
export const KEYCODE_F4 = 115;
export const KEYCODE_F5 = 116;
export const KEYCODE_F6 = 117;
export const KEYCODE_F7 = 118;
export const KEYCODE_F8 = 119;
export const KEYCODE_F9 = 120;
export const KEYCODE_F10 = 121;
export const KEYCODE_F11 = 122;
export const KEYCODE_F12 = 123;
export const KEYCODE_NUM_LOCK = 144;
export const KEYCODE_SCROLL_LOCK = 145;
export const KEYCODE_SEMI_COLON = 186;
export const KEYCODE_EQUAL = 187;
export const KEYCODE_COMMA = 188;
export const KEYCODE_DASH = 189;
export const KEYCODE_PERIOD = 190;
export const KEYCODE_FORWARD_SLASH = 191;
export const KEYCODE_GRAVE_ACCENT = 192;
export const KEYCODE_OPEN_BRACKET = 219;
export const KEYCODE_BACK_SLASH = 220;
export const KEYCODE_CLOSE_BRACKET = 221;
export const KEYCODE_SINGLE_QUOTE = 222;
