import { Hooks } from 'Components/Hooks';

import {
  Backdrop,
  ViewConnected,

  ConfMeters,

  ToggleHoldButton,
  ToggleMuteButton,
  ToggleHandRaisedButton,
  ToggleMusicOnHoldButton,
  SettingsIconButton,
  ToggleDialpadButton,
  DisconnectIconButton,

  FinishNameRecordingButton,

  ViewConnecting,
  QualityMeterModal,
  MediaErrorModal,
  SettingsModal,
  DialpadModal,

  registerDrawLoop,
} from './UIComponents';

export default function EmbeddedConfCallUI({ ctrl }) {
  const hooks = new Hooks();

  const ctx = {
    hooks,
    ctrl,
  };

  const root = (
    <div>
      <Backdrop ctx={ctx} />
      <ViewConnected ctx={ctx}>
        <FinishNameRecordingButton ctx={ctx} />

        <ConfMeters ctx={ctx} />

        <div class="btn-group btn-group-justified btn-group-no-collapse" role="group">
          <ToggleHoldButton ctx={ctx} />
          <ToggleMuteButton ctx={ctx} />
          <ToggleHandRaisedButton ctx={ctx} />
          <ToggleMusicOnHoldButton ctx={ctx} />
          <SettingsIconButton ctx={ctx} />
          <ToggleDialpadButton ctx={ctx} />
          <DisconnectIconButton ctx={ctx} />
        </div>
      </ViewConnected>

      <ViewConnecting ctx={ctx} />

      <QualityMeterModal ctx={ctx} anchored={false} />
      <MediaErrorModal ctx={ctx} anchored={false} />
      <SettingsModal ctx={ctx} anchored={false} />
      <DialpadModal ctx={ctx} anchored={false} />
    </div>
  );

  registerDrawLoop(ctrl, root);

  ctrl.on('update', () => hooks.run(ctrl));
  hooks.run(ctrl);

  return root;
}
