import Loader from 'Loader';

import Api from 'Api/Api';
import ApiContextAuthToken from 'Api/ApiContextAuthToken';
import ApiContextAuthNone from 'Api/ApiContextAuthNone';
import ClientStorage from 'Browser/ClientStorage';

import C from './Constants';
import LcmPortal from './LcmPortal';
import getColumnConfig from './getColumnConfig';
import parseCustomColumns from './parseCustomColumns';

const STORED_FIELDS_LOCAL_STORAGE_KEY = 'lcm_saveFields';
const SESSION_PARAMS_STORAGE_KEY = 'lcm_sessionParams';

export default function main() {
  const location = new URL(document.location.href);
  const { searchParams } = location;

  function reload() {
    searchParams.delete('authToken');
    searchParams.delete('conferenceID');
    searchParams.delete('bridgeNum');
    searchParams.delete('pin');
    searchParams.delete('name');

    window.open(location.href, '_self');
  }

  function setSessionParamsAndReload(params) {
    ClientStorage.sessionStorage.writeJSON(SESSION_PARAMS_STORAGE_KEY, {
      ...params,
      name: searchParams.get('name'),
    });
    reload();
  }

  if (searchParams.has('authToken')) {
    setSessionParamsAndReload({
      authToken: searchParams.get('authToken'),
    });
    return;
  }

  const username = searchParams.get('conferenceID') || searchParams.get('bridgeNum');
  if (username) {
    setSessionParamsAndReload({
      username,
      password: searchParams.get('pin'),
    });
    return;
  }

  let compact = false;
  let showTabs = true;
  if (searchParams.has('compact')) {
    compact = true;
    showTabs = searchParams.has('tabs');
  }

  Loader.load()
    .then(siteConfig => {
      if (!siteConfig.API_URL) {
        throw new Error('invalid siteConfig');
      }

      const { API_URL, API_TIMEOUT } = siteConfig;
      const apiConfig = {
        API_URL,
        API_TIMEOUT,
        localStorageKey: C.AUTH_TOKEN_LOCAL_STORAGE_KEY,
      };

      Api.addContext(new ApiContextAuthToken('default', apiConfig));
      Api.addContext(new ApiContextAuthNone('authNone', apiConfig));

      const siteSettings = siteConfig.settings;

      const {
        partnerID,
        lcmSiteID,
      } = siteConfig;

      const useAccountAuth = !!siteSettings.LCM_USE_ACCOUNT_AUTH;
      const enableChat = !!siteSettings.LCM_CHAT_ENABLE;

      let sessionParams = null;

      const storedSession = ClientStorage.sessionStorage.readJSON(SESSION_PARAMS_STORAGE_KEY);
      ClientStorage.sessionStorage.delete(SESSION_PARAMS_STORAGE_KEY);
      if (storedSession && typeof storedSession === 'object') {
        const {
          authToken = null,
          username = null,
          password = null,
          name = null,
        } = storedSession;

        if (authToken) {
          sessionParams = {
            authToken,
            name,
          };
        } else if (username && !useAccountAuth) {
          sessionParams = {
            username,
            password,
            name,
          };
        }
      }

      const enableSubconferences = siteConfig.subconferencesFlag || false;

      let notesFields = null;
      if (siteConfig.notesFields && siteConfig.notesFields instanceof Array) {
        notesFields = siteConfig.notesFields.reduce((acc, val) => {
          acc[val.name] = val;
          return acc;
        }, {});
      }

      let columns = null;
      if (siteSettings.LCM_COLUMNS) {
        let dec;
        try {
          dec = JSON.parse(siteSettings.LCM_COLUMNS);
        } catch (e) {
        }
        if (Array.isArray(dec)) {
          columns = dec;
        }
      }

      const customColumns = parseCustomColumns(siteSettings.customColumns);

      const colConfig = getColumnConfig({
        columns,
        customColumns,
      });

      const {
        LCM_HIDE_CONFERENCE_ID: hideConferenceID = false,
      } = siteSettings;

      const allTabs = (siteConfig.tabs || [])
        // ignore old server generated internal tabs
        .filter(tab => tab.moduleName !== 'confDetails' && tab.moduleName !== 'wallet');

      if (allTabs.find(tab => tab.moduleName === 'report')) {
        allTabs.push({
          type: 'internal',
          moduleName: 'confDetails',
          hash: 'conference',
          parent: 'report',
        });

        allTabs.push({
          type: 'internal',
          moduleName: 'conferenceRecording',
          hash: 'recording',
          parent: 'report',
        });
      }

      if (allTabs.find(tab => tab.moduleName === 'recordings')) {
        allTabs.push({
          type: 'internal',
          moduleName: 'uploadRecording',
          hash: 'uploadRecording',
          parent: 'recordings',
        });
      }

      if (siteConfig.showPrintWalletCardsFlag && allTabs.find(tab => tab.moduleName === 'info')) {
        allTabs.push({
          type: 'internal',
          moduleName: 'wallet',
          hash: 'wallet',
          parent: 'info',
        });
      }

      if (allTabs.find(tab => tab.moduleName === 'users')) {
        allTabs.push({
          type: 'internal',
          moduleName: 'userID',
          hash: 'userID',
          parent: 'users',
        });
      }

      if (allTabs.find(tab => tab.moduleName === 'addressBook')) {
        allTabs.push({
          type: 'internal',
          moduleName: 'addressBookEntry',
          hash: 'addressBookEntry',
          parent: 'addressBook',
        });
      }

      const moduleConfig = {
        portal: {
          hideConferenceID,
          noticeMarkup: siteSettings.LCM_NOTICE_MESSAGE || null,
          login: {
            sessionParams,
            apiConfig,
            baseParams: {
              partnerID,
              lcmSiteID,
            },
            storedFieldsKey: STORED_FIELDS_LOCAL_STORAGE_KEY,
            useAccountAuth,
            showName: enableChat,
            requireName: !!(enableChat && siteSettings.LCM_REQUIRE_NAME),
          },
        },
        confController: {
          apiConfig,
          partnerID,
          notesFields,
          customColumns,
          colConfig,
          enableSubconferences,
          disableRecording: siteSettings.LCM_DISABLE_RECORDING,
          disableMakeCall: siteSettings.LCM_DISABLE_MAKE_CALL,
          enableBroadcast: !!siteSettings.LCM_ENABLE_BROADCAST,
          enableChat,
          enableOperatorWebCall: !siteSettings.LCM_DISABLE_OPERATOR_WEBCALL,
          broadcastUserID: siteSettings.LCM_BROADCAST_USER_ID || null,
          settingsController: {
            musicOnHoldOptions: siteSettings.MOH_INFO || null,
            welcomePromptOptions: siteSettings.WELCOME_PROMPT_INFO || null,
            welcomePromptRecordingNumber: siteSettings.WELCOME_PROMPT_RECORDING_NUMBER || null,
            welcomePromptRecordingMaxDuration: siteSettings.WELCOME_PROMPT_RECORDING_MAX_DURATION || null,
            ttsVoices: siteSettings.TTS_VOICES || null,
          },
        },
        lcm: {
          colConfig,
          fillerRowTotal: siteConfig.FILLER_ROW_TOTAL,
          confPendingMessage: siteSettings.LCM_CONF_PENDING_MESSAGE,
        },
        settings: {
          enableUserIDValidation: siteSettings.enableUserIDValidation,
          allowPinChangeFlag: siteConfig.allowPinChangeFlag,
          allowBridgeNameChangeFlag: siteSettings.allowBridgeNameChangeFlag,
          allowEmailListChangeFlag: siteConfig.allowEmailListChangeFlag,
          allowEnableReportsChangeFlag: siteConfig.allowEnableReportsChangeFlag,
          bridgePinLength: siteConfig.bridgePinLength,
          musicOnHoldOptions: siteSettings.MOH_INFO || null,
          accessNumberOptions: siteSettings.ACCESS_NUMBER_INFO || null,
        },
        report: {
          confDetailsCsvExtraColumns: siteSettings.LCM_CONF_DETAILS_CSV_EXTRA_COLUMNS,
        },
        confDetails: {
          allowEmailListChangeFlag: siteConfig.allowEmailListChangeFlag,
        },
        info: {
          showPrintWalletCardsFlag: siteConfig.showPrintWalletCardsFlag,
          showEmailMyInfoFlag: siteConfig.showEmailMyInfoFlag,
          hideConferenceID,
        },
        wallet: {
          walletCardLogoPath: siteConfig.walletCardLogoPath,
          hideConferenceID,
        },
        webCallConfApp: {
          wsSipURI: siteSettings.WEBCALL_SIP_URI,
          toUriPrefix: siteSettings.WEBCALL_TO_URI_PREFIX,
          toUriSuffix: siteSettings.WEBCALL_TO_URI_SUFFIX,
          fetchLocationURL: siteSettings.WEBCALL_LOCATION_URL || null
        },
        webCallPlayer: {
          wsSipURI: siteSettings.WEBCALL_SIP_URI,
          toUriPrefix: siteSettings.WEBCALL_PLAYER_TO_URI_PREFIX,
          toUriSuffix: siteSettings.WEBCALL_PLAYER_TO_URI_SUFFIX,
          fetchLocationURL: siteSettings.WEBCALL_LOCATION_URL || null,

          fromSipURI: siteSettings.WEBCALL_PLAYER_FROM_ADDRESS ?
            'sip:' + siteSettings.WEBCALL_PLAYER_FROM_ADDRESS :
            'sip:caller@invalid',
          fromName: siteSettings.WEBCALL_PLAYER_FROM_NAME || null,
        },
      };

      const portal = new LcmPortal({
        compact,
        showTabs,
        moduleConfig,
        appMainMaxWidth: siteConfig.settings.LCM_APP_MAIN_MAX_WIDTH,
        enableReportError: !!siteConfig.settings.LCM_ENABLE_ERROR_REPORT,
        helpURL: siteConfig.helpURL || null,
        internalTabs: allTabs.filter(tab => tab.type === 'internal'),
        externalTabs: allTabs.filter(tab => tab.type === 'external'),
      });

      return portal.start();
    })
    .then(() => Loader.setLogo())
    .then(() => Loader.loadComplete())
    .catch(err => Loader.loadError(err));
}
