import { ApiError, ApiErrorResult } from './ApiErrors';

export default class ApiResponse {
  constructor(envl) {
    this._envl = envl;
  }

  get data() {
    return this._envl;
  }

  get error() {
    return this._envl.error;
  }

  get authToken() {
    return this._envl.authToken;
  }

  get scope() {
    return this._envl.scope;
  }

  getRequestItem(idx) {
    return this._envl.responseList &&
      this._envl.responseList.requestItem && this._envl.responseList.requestItem &&
      this._envl.responseList.requestItem[idx] && this._envl.responseList.requestItem[idx].result;
  }

  getResult(idx = 0) {
    const result = this.getRequestItem(idx);
    if (!result) {
      throw new ApiError('API_ERROR_RESULT_MISSING');
    }

    if (result.error) {
      throw new ApiErrorResult(result.error);
    }

    return result;
  }
}
