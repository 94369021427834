import EventEmitter from 'events';
import * as MOS from './MOS';

const GET_STATS_INTERVAL_MS = 1000;

const SAMPLE_BUFFER_SIZE = 5;
const SAMPLE_IGNORE_COUNT = 10;

const SAMPLE_COUNT_CLEAR_WARNING = 0;
const SAMPLE_COUNT_SET_WARNING = 3;

const WARNING_TTL = 5000;

const DEFAULT_WARNING_CONDITIONS = {
  ppl : {
    max: 1
  },
  jitter : {
    max: 0.03
  },
  rtt : {
    max: 400
  },
  mos: {
    min: 3
  }
};

export default class StatsMonitor extends EventEmitter {
  constructor() {
    super();

    this._warningConditions = DEFAULT_WARNING_CONDITIONS;

    this._reset();
  }

  start(pc) {
    this._pc = pc;

    this._totalSamples = 0;
    this._resetPcTotals();

    this._prevCallTotals = {
      ...this._callTotals,
    };

    if (!this._interval)
      this._interval = setInterval(this._poll.bind(this), GET_STATS_INTERVAL_MS);
  }

  stop() {
    clearInterval(this._interval);
    this._interval = null;

    this._reset();
  }

  _reset() {
    this._pc = null;
    this._buffer = [];
    this._warnings = {};
    this._resetPcTotals();
    this._resetCallTotals();
    this._resetPrevCallTotals();
  }

  _resetPcTotals() {
    this._pcTotals = createTotalsObject();
  }

  _resetCallTotals() {
    this._callTotals = createTotalsObject();
  }

  _resetPrevCallTotals() {
    this._prevCallTotals = createTotalsObject();
  }

  _getStats(getExtended) {
    const pc = this._pc;
    if (!pc)
      return Promise.resolve(null);

    return Promise.resolve()
      .then(() => pc.getStats())
      .then(report => {
        if (this._pc !== pc)
          return null;

        const getCodec = id => {
          const cat = report.get(id);
          if (cat && cat.mimeType) {
            const pos = cat.mimeType.indexOf('audio/');
            if (pos === 0) {
              return cat.mimeType.substr(6);
            }
          }

          return false;
        };

        let ts;
        let transportId = null;
        let inboundCodecId = null;
        let outboundCodecId = null;

        const stats = {
          packetsReceived: 0,
          bytesReceived: 0,
          packetsLost: 0,
          jitter: 0,
          rtt: 0,

          packetsSent: 0,
          bytesSent: 0,
        };

        let hasInbound = false;

        report.forEach(cur => {
          ts = ts || cur.timestamp;

          if (cur.isRemote) {
            return;
          }

          switch (cur.type) {
          case 'inbound-rtp':
            if (cur.ssrc === '0') // Edge contains useless inbound-rtp/outbound-rtp categories, skip them
              return;

            hasInbound = true;

            stats.timestamp = stats.timestamp || cur.timestamp;
            stats.packetsReceived = cur.packetsReceived;
            stats.bytesReceived = cur.bytesReceived;
            stats.packetsLost  = cur.packetsLost;
            stats.jitter = cur.jitter;

            if (getExtended && cur.codecId)
              inboundCodecId = cur.codecId;

            break;

          case 'outbound-rtp':
            if (cur.ssrc === '0')
              return;

            stats.timestamp = stats.timestamp || cur.timestamp;
            stats.packetsSent = cur.packetsSent;
            stats.bytesSent = cur.bytesSent;

            if (getExtended && cur.codecId)
              outboundCodecId = cur.codecId;

            break;

          case 'transport':
            if (cur.dtlsState == 'connected') {
              transportId = cur.id;
            }
            break;
          }
        });

        if (!hasInbound) {
          return null;
        }

        stats.timestamp = stats.timestamp || ts;

        if (getExtended) {
          if (inboundCodecId)
            stats.decodeCodec = getCodec(inboundCodecId);

          if (outboundCodecId)
            stats.encodeCodec = getCodec(outboundCodecId);
        }

        if (transportId) {
          const transport = report.get(transportId);
          if (transport) {
            const candidatePair = report.get(transport.selectedCandidatePairId);
            if (candidatePair) {
              stats.rtt = candidatePair.currentRoundTripTime * 1000;
            }
          }
        }

        return stats;
      });
  }

  _poll() {
    this._getStats(true)
      .then(stats => this._processSample(stats))
      .catch(error => this._error(error));
  }

  _processSample(stats) {
    // ignore null samples
    if (!stats)
      return;

    const sample = this._createSample(stats);

    this._totalSamples++;

    this._addSample(sample);
    if (this._totalSamples > SAMPLE_IGNORE_COUNT) {
      this._processWarnings();
    }
    this.emit('sample', sample);
  }

  _addSample(sample) {
    const buffer = this._buffer;

    buffer.push(sample);

    if (buffer.length > SAMPLE_BUFFER_SIZE) {
      buffer.splice(0, buffer.length - SAMPLE_BUFFER_SIZE);
    }
  }

  _createSample(stats) {
    const packetsSent = stats.packetsSent - this._pcTotals.packetsSent;
    const packetsReceived = stats.packetsReceived - this._pcTotals.packetsReceived;
    const packetsLost = stats.packetsLost - this._pcTotals.packetsLost;
    const inboundPackets = packetsReceived + packetsLost;
    const lossRatio = (inboundPackets > 0) ?
      (packetsLost / inboundPackets) : 0;
    const ppl = lossRatio * 100;

    const bytesReceived = stats.bytesReceived - this._pcTotals.bytesReceived;
    const bytesSent = stats.bytesSent - this._pcTotals.bytesSent;

    const totals = {
      packetsReceived : stats.packetsReceived,
      packetsLost : stats.packetsLost,
      packetsSent : stats.packetsSent,
      bytesReceived : stats.bytesReceived,
      bytesSent : stats.bytesSent,
      maxJitter : Math.max(stats.jitter, this._pcTotals.maxJitter),
    };

    // copy totals into this._pcTotals
    this._pcTotals = {
      ...totals,
    };

    // adjust totals
    totals.packetsReceived += this._prevCallTotals.packetsReceived;
    totals.packetsLost += this._prevCallTotals.packetsLost;
    totals.packetsSent += this._prevCallTotals.packetsSent;
    totals.bytesReceived += this._prevCallTotals.bytesReceived;
    totals.bytesSent += this._prevCallTotals.bytesSent;

    // copy adjusted totals to this._callTotals
    this._callTotals = {
      ...totals,
    };

    const totalInboundPackets = totals.packetsReceived + totals.packetsLost;
    totals.totalPpl = (totalInboundPackets > 0)
      ? (totals.packetsLost / totalInboundPackets) * 100
      : 100;

    const mos = MOS.calculate(ppl, stats.rtt);

    const ret = {
      totals,
      ppl,
      timestamp : stats.timestamp,
      packetsReceived,
      packetsLost,
      packetsSent,
      bytesReceived,
      bytesSent,
      jitter : stats.jitter,
      rtt : stats.rtt,
      mos : mos.mos,
      rFactor : mos.rFactor,
      qualityLevel: getQualityLevel(mos.mos),
    };

    if (stats.encodeCodec)
      ret.encodeCodec = stats.encodeCodec;

    if (stats.decodeCodec)
      ret.decodeCodec = stats.decodeCodec;

    return ret;
  }

  _processWarnings() {
    for (var stat in this._warningConditions) {
      this._checkWarningConditions(stat);
    }
  }

  _checkWarningConditions(stat) {
    var opts = this._warningConditions[stat],
        samples = this._buffer,
        values = samples.map(function (sample) { return sample[stat]; });

    // skip check if we have invalid values fir that stat in any of the samples
    if (values.some(function (value) { return value === undefined || value === null; })) {
      return;
    }

    var count,
        stateChanged = false;

    if (opts.max !== undefined) {
      count = values.reduce(function(total, value) { return total += (value > opts.max) ? 1 : 0; }, 0);
      if (this._processWarningCondition(stat, 'max', count)) {
        stateChanged = true;
      }
    }

    if (opts.min !== undefined) {
      count = values.reduce(function(total, value) { return total += (value < opts.min) ? 1 : 0; }, 0);
      if (this._processWarningCondition(stat, 'min', count)) {
        stateChanged = true;
      }
    }

    if (stateChanged) {
      this.emit('warningUpdate', Object.assign({}, this._warnings));
    }
  }

  // returns true if warning has been added or cleared
  _processWarningCondition(stat, conditionType, count) {
    let stateChanged = false;

    if (count >= SAMPLE_COUNT_SET_WARNING) {
      stateChanged = this._addWarning(stat, conditionType);
    } else if (count <= SAMPLE_COUNT_CLEAR_WARNING) {
      stateChanged = this._clearWarning(stat, conditionType);
    }

    return stateChanged;
  }

  _addWarning(stat, conditionType) {
    const name = stat + ':' + conditionType;
    if (this._warnings[name]) {
      return false;
    }
    this._warnings[name] = {
      timestamp : Date.now()
    };

    return true;
  }

  _clearWarning(stat, conditionType) {
    const name = stat + ':' + conditionType;
    if (!this._warnings[name] || Date.now() - this._warnings[name].ts < WARNING_TTL) {
      return false;
    }

    delete this._warnings[name];

    return true;
  }

  _error(error) {
    this.stop();
    this.emit('error', error);
  }
}

function getQualityLevel(mos) {
  if (mos >= 3.8) {
    return 4;
  } else if (mos >= 3.3) {
    return 3;
  } else if (mos >= 2.8) {
    return 2;
  } else if (mos >= 2.4) {
    return 1;
  }

  return 0;
}

function createTotalsObject() {
  return {
    packetsReceived: 0,
    packetsLost: 0,
    packetsSent: 0,
    bytesReceived: 0,
    bytesSent: 0,
    maxJitter: 0,
  };
}
