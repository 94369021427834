export default function ClipboardCopy(options) {
  this.container      = options.container || document.body;
  this.text           = options.text;
  this.resultCallback = options.resultCallback;

  this.selectFake();
}

ClipboardCopy.supported = false;

ClipboardCopy.prototype.selectFake = function() {
  this.removeFake();

  this.fakeElem = document.createElement('textarea');

  // Prevent zooming on iOS
  this.fakeElem.style.fontSize = '12pt';
  // Reset box model
  this.fakeElem.style.border = '0';
  this.fakeElem.style.padding = '0';
  this.fakeElem.style.margin = '0';
  // Move element out of screen horizontally
  this.fakeElem.style.position = 'absolute';
  this.fakeElem.style.left = '-9999px';
  // Move element to the same position vertically
  var yPosition = window.pageYOffset || document.documentElement.scrollTop;
  this.fakeElem.style.top = yPosition + 'px';

  this.fakeElem.value = this.text;

  this.container.appendChild(this.fakeElem);

  this.fakeElem.focus();
  this.fakeElem.setSelectionRange(0, this.text.length);

  this.copyText();
  this.removeFake();
};

ClipboardCopy.prototype.removeFake = function() {
  if (this.fakeElem) {
    this.container.removeChild(this.fakeElem);
    this.fakeElem = null;
  }
};

ClipboardCopy.prototype.copyText = function() {
  var succeeded;

  try {
    succeeded = document.execCommand('copy');
  } catch (err) {
    succeeded = false;
  }

  this.resultCallback(succeeded);
};

(() => {
  try {
    ClipboardCopy.supported = !!(document.queryCommandSupported && document.queryCommandSupported('copy'));
  } catch (err) {
  }
})();
