import axios from 'axios';

import { AuthError } from 'CommonExceptions';
import { ApiErrorNetwork, ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';
import ApiContext from 'Api/ApiContext';
import createQueryString from 'Browser/createQueryString';

import Identity from './Identity';

const REQUEST_URL = 'ValidateLogin.php';
const REQUEST_TIMEOUT = 30000;

export function sendLoginRequest(partnerID, lcmSiteID, username, password, name) {
  const paramStr = createQueryString({
    validateLogin: 1,
    partnerID,
    lcmSiteID,
    username,
    password,
  });

  return axios.post(REQUEST_URL, paramStr, { timeout: REQUEST_TIMEOUT })
    .catch(err => {
      // catch axios errors and wrap them in our ApiErrorNetwork class
      throw new ApiErrorNetwork(err);
    })
    .then(res => {
      const data = res && res.data;
      if (!data) {
        throw new Error('empty or invalid response');
      }

      if (data.error) {
        throw new ApiErrorResult(data.error);
      }

      if (!data.bridge) {
        throw new Error('missing bridge object in response');
      }

      let adminID = null;
      if (data.accountAdminID)
        adminID = data.accountAdminID;

      const identity = new Identity({ name, adminID });

      return {
        identity,
        authToken: data.bridge.authToken,
        bridge: data.bridge,
      };
    });
}

export function checkAuthToken(apiConfig, authToken, name) {
  if (!authToken)
    return undefined;

  const params = {
    dateFormat: 'longEnglishDate',
  };

  const opts = {
    returnFullResponse: true,
  };

  const ctx = new ApiContext('checkAuthToken', apiConfig);
  ctx.setAuthParams({
    authToken: {
      token: authToken,
    },
  });

  return Api.get('Bridge', 'getBridges', params, opts, ctx)
    .then(response => {
      const res = response.getResult();

      const bridge = res.bridge && res.bridge[0];
      if (!bridge)
        throw new AuthError('no bridge result');

      let adminID = null;
      if (response.data.authn && response.data.authn.accountAdminID)
        adminID = response.data.authn.accountAdminID;

      const {
        operatorID = null,
      } = response.data;

      const identity = new Identity({ name, adminID, operatorID });

      return {
        identity,
        authToken: bridge.authToken,
        bridge,
      };
    });
}
