import { emptyNode, toggleAll, updateContent } from 'Components/domHelpers';
import { Cond } from 'Components/FormComponents';

import Subpage from './Subpage';

import s from './strings';

export default class Wallet extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
      },

      options: {
      }
    };

    this._logoPath = null;
  }

  init(config) {
    const { hideConferenceID } = config;

    const PrintCancelToolbar = () => {
      return (
        <p class="btn-toolbar-discrete print-hide">
          <button type="button" class="btn btn-primary btn-print-confirm">{s.lblPrint}</button>
          <button type="button" class="btn btn-primary" onclick={() => this.back()}>{s.lblCancel}</button>
        </p>
      );
    };

    const root = (
      <div class="subpage subpage-wallet">
        <div class="subpage-content">
          <PrintCancelToolbar />

          <p>{s.lblWalletNotice}</p>

          <div class="wallet-container">
            <WalletCard hideConferenceID={hideConferenceID} />
            <WalletCard hideConferenceID={hideConferenceID} />
          </div>

          <PrintCancelToolbar />
        </div>
      </div>
    );

    super.init(root, {
      defaultBackSubpage: 'info',
    });

    this._logoContainers = this.root.querySelectorAll('.logo');

    this.setLogo(config.walletCardLogoPath);
  }

  setLogo(path) {
    if (path !== this._logoPath) {
      this._logoContainers.forEach(_ => {
        emptyNode(_);
        if (path) {
          const img = document.createElement('img');
          img.src = path;
          _.appendChild(img);
        }
      });

      this._logoPath = path;
    }
  }

  activate() {
    return this.ctrl.fetchBridgeData()
      .then(bridgeData => {
        const {
          conferenceIDFormatted,
          pin,
          tollNumberFormatted,
          tollFreeNumberFormatted,
        } = bridgeData;

        updateContent(this.root, {
          conferenceIDFormatted,
          pin,
          tollNumberFormatted,
          tollFreeNumberFormatted,
        });

        toggleAll('.tr-tollNumberFormatted', tollNumberFormatted, this.root);
        toggleAll('.tr-tollFreeNumberFormatted', tollFreeNumberFormatted, this.root);

        if (bridgeData.walletCardLogoPath)
          this.setLogo(bridgeData.walletCardLogoPath);
      });
  }
}

function WalletCard({ hideConferenceID }) {
  return (
    <div class="pnlWallet">
      <div class="section">
        <div class="logo"></div>

        <div class="info-table-container">
          <table>
            <tbody>
              <tr class="tr-tollNumberFormatted">
                <td>{s.lblTollDialIn}</td>
                <td class="cell-data _tollNumberFormatted"></td>
              </tr>

              <tr class="tr-tollFreeNumberFormatted">
                <td>{s.lblTollFreeDialIn}</td>
                <td class="cell-data _tollFreeNumberFormatted"></td>
              </tr>

              <Cond test={!hideConferenceID}>
                <tr>
                  <td>{s.lblConferenceID}:</td>
                  <td class="cell-data _conferenceIDFormatted"></td>
                </tr>
              </Cond>

              <tr>
                <td>{s.lblHostPIN}:</td>
                <td class="cell-data _pin"></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="note">{s.WalletCard.HostNote}</div>
      </div>

      <div class="section keypad-commands">
        <table>
          <tbody>
            <tr>
              <td class="participant">
                <span class="keypad-key">*</span><span class="keypad-key">0</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s0}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">1</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s1}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">2</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s2}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">3</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s3_1}</td>
            </tr>
            <tr>
              <td class="participant">
                <span class="keypad-key">*</span><span class="keypad-key">3</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s3_2}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">4</span><span class="keypad-key">1</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s41}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">4</span><span class="keypad-key">2</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s42}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">4</span><span class="keypad-key">3</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s43}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">4</span><span class="keypad-key">4</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s44}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">4</span><span class="keypad-key">7</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s47}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="section keypad-commands">
        <table>
          <tbody>
            <tr>
              <td class="participant">
                <span class="keypad-key">*</span><span class="keypad-key">5</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s5}</td>
            </tr>
            <tr>
              <td class="participant">
                <span class="keypad-key">*</span><span class="keypad-key">6</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s6}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">7</span><span class="keypad-key">8</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s78}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">7</span><span class="keypad-key">9</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s79}</td>
            </tr>
            <tr>
              <td class="participant">
                <span class="keypad-key">*</span><span class="keypad-key">8</span><span class="keypad-key">4</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s84}</td>
            </tr>
            <tr>
              <td class="participant">
                <span class="keypad-key">*</span><span class="keypad-key">8</span><span class="keypad-key">5</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s85}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">9</span><span class="keypad-key">1</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s91}</td>
            </tr>
            <tr>
              <td>
                <span class="keypad-key">*</span><span class="keypad-key">9</span><span class="keypad-key">4</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_s94}</td>
            </tr>
            <tr>
              <td class="participant">
                <span class="keypad-key">*</span><span class="keypad-key">#</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_sp}</td>
            </tr>
            <tr>
              <td class="participant">
                <span class="keypad-key">*</span><span class="keypad-key">*</span>
              </td>
              <td class="description">{s.WalletCard.dtmf_ss}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
