import axios from 'axios';

import s from './strings';
import errors from './errors';

import Loader from 'Loader';

import Api from 'Api/Api';
import ApiContextAuthToken from 'Api/ApiContextAuthToken';
import createQueryString from 'Browser/createQueryString';
import Location from 'Browser/Location';
import { show, hide } from 'Components/domHelpers';
import { RefFormData, FormGroup, FormText, wrapSubmitHandler } from 'Components/FormComponents';

import C from './Constants';

const REQUEST_TIMEOUT = 30000;

export default function main() {
  Loader.load()
    .then(siteConfig => {
      const apiContext = new ApiContextAuthToken('default', {
        ...siteConfig,
        localStorageKey: C.AUTH_TOKEN_LOCAL_STORAGE_KEY
      });

      Api.addContext(apiContext);

      function sendRequest(params, success) {
        const url = `ValidateLogin.php?${createQueryString(params)}`;

        axios(url, { timeout: REQUEST_TIMEOUT })
          .then(res => {
            if (!res || !res.data) {
              throw new Error('no data in response');
            }

            success(res.data);
          })
          .catch(err => {
            errorHandler('ERR_UNKNOWN');
          });
      }

      let origComment;

      let panelError;
      let panelErrorText;

      let panelForm;
      let panelFormForm;
      let savedMessage;

      const form = new RefFormData();

      const save = () => {
        const params = {
          validateLoginCdr: 1,
          partnerID: siteConfig.partnerID,
          cdrID: Location.query.editID,
          signature: Location.query.s,
          comment: form.get('comment'),
        };

        sendRequest(params, function(data) {
          if (data.error) {
            errorHandler(data.error.code);
          } else {
            hide(panelError);
            hide(panelFormForm);
            show(savedMessage);
          }
        });
      };

      const cancel = () => {
        form.set('comment', origComment);
      };

      document.body.append(
        <div class="portal-container">
          <div class="portal-container-panel">
            <div class="portal-container-panel-inner">
              <div class="logo-container"></div>

              <div class="panel panel-danger" hidden ref={panelError}>
                <div class="panel-heading">
                  <h3 class="panel-title">{s.lblError}</h3>
                </div>
                <div class="panel-body" ref={panelErrorText} />
              </div>

              <div class="panel panel-primary" hidden ref={panelForm}>
                <div class="panel-heading">
                  <h3 class="panel-title">{s.lblEditReference}</h3>
                </div>
                <div class="panel-body">
                  <form
                    class="form-horizontal"
                    onsubmit={wrapSubmitHandler(save)}
                    ref={panelFormForm}
                  >
                    <FormText form={form} name="comment" label={s.lblComment} comment={s.lblConfCommentNote} />

                    <FormGroup>
                      <div class="btn-toolbar-discrete">
                        <button type="submit" class="btn btn-primary">{s.lblSave}</button>
                        <button type="button" class="btn btn-primary" onclick={cancel}>{s.lblCancel}</button>
                      </div>
                    </FormGroup>
                  </form>

                  <div hidden ref={savedMessage}>
                    {s.lblEditReferenceSaved}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

      function errorHandler(errorCode) {
        panelErrorText.textContent = errors[errorCode];
        show(panelError);
      }

      if (!Location.query.editID) {
        errorHandler('ERR_INVALID_REQUEST');
        return;
      }

      var initialParams = {
        validateLoginCdr : 1,
        partnerID        : siteConfig.partnerID,
        cdrID            : Location.query.editID
      };

      if (Location.query.s) {
        initialParams.signature = Location.query.s;
      }

      var authToken = apiContext.getStoredAuthToken();
      if (authToken) {
        initialParams.authToken = authToken;
      }

      sendRequest(initialParams, function(data) {
        if (data.error) {
          errorHandler(data.error.code);
        } else {
          if (data.authTokenValid) {
            window.open(`index.html#${C.CONF_DETAILS_HASH}/${encodeURIComponent(data.cdrID)}`, '_self');
            return;
          }

          apiContext.clearAuthToken();

          if (data.signatureValid) {
            origComment = data.comment;
            form.set('comment', data.comment);
            show(panelForm);
          } else {
            window.open(`index.html?conferenceID=${encodeURIComponent(data.conferenceID)}#${C.CONF_DETAILS_HASH}/${encodeURIComponent(data.cdrID)}`, '_self');
            return;
          }
        }
      });
    })
    .then(() => Loader.setLogo())
    .then(() => Loader.loadComplete())
    .catch(err => Loader.loadError(err));
}
