import $ from 'jquery';

export default class Form {
  constructor(fields, idPrefix) {
    this.fields   = [];
    this.idPrefix = '';
    this.locked   = false;

    if (fields) {
      if (fields instanceof Array) {
        this.fields = fields;
      } else if (fields instanceof Object) {
        this.fields = Object.keys(fields);
      } else {
        throw new TypeError('fields must be array or object');
      }
    }

    if (idPrefix)
      this.idPrefix = idPrefix;
  }

  setFields(fieldObject) {
    this.fields = [];

    for (var i in fieldObject) {
      this.fields.push(i);
    }
  }

  setFieldIDPrefix(prefix) {
    this.idPrefix = prefix;
  }

  populate(data) {
    if (this.prePopulateCallback)
      this.prePopulateCallback(data);

    this.fields.forEach(cur => this.setDataField(cur, data[cur]));

    if (this.postPopulateCallback !== undefined)
      this.postPopulateCallback(data);
  }

  setDataField(fieldName, val) {
    const field = this._getInputEl(fieldName);
    const fieldEl = field[0];

    if (!fieldEl)
      return;

    const tagName = fieldEl.tagName;

    if (field.hasClass('dropdown-select')) {
      var dropdownSelectLabel = field.find('a[href="' + val + '"]').text();
      field.val(val);
      field.find('.value').text(dropdownSelectLabel);
      return;
    }

    if (tagName == 'SPAN' || tagName == 'P') {
      field.text(val);
      return;
    }

    if (field.attr('type') == 'checkbox') {
      fieldEl.checked = !!val;
    } else {
      field.val(val);
    }
  }

  getData() {
    var data = {};

    this.fields.forEach(cur => {
      const val = this.getDataField(cur);

      if (val !== undefined)
        data[cur] = val;
    });

    return data;
  }

  getDataField(fieldName) {
    var field = this._getInputEl(fieldName);
    var type = field.attr('type');
    var ret;

    if (type == 'checkbox') {
      ret = field[0].checked;
    } else {
      ret = field.val();
    }

    return ret;
  }

  lock() {
    this.fields.forEach(cur => this.disableField(cur));

    this.locked = true;

    if (this.postLockCallback !== undefined)
      this.postLockCallback();
  }

  unlock() {
    this.fields.forEach(cur => this.enableField(cur));

    this.locked = false;

    if (this.postUnlockCallback !== undefined)
      this.postUnlockCallback();
  }

  setDisabled(fieldName, flag) {
    if (flag) {
      this.disableInput(this._getInputEl(fieldName));
    } else {
      this.enableInput(this._getInputEl(fieldName));
    }
  }

  disableField(fieldName) {
    this.disableInput(this._getInputEl(fieldName));
  }

  enableField(fieldName) {
    this.enableInput(this._getInputEl(fieldName));
  }

  disableInput(obj) {
    if (obj.hasClass('dropdown-select')) {
      obj.find('.btn').attr('disabled', true);
      return;
    }

    obj.attr('disabled', true);

    if (obj.hasClass('hasDatepicker'))
      obj.datepicker('disable');
  }

  enableInput(obj) {
    if (!obj.hasClass('disabledPermanently')) {
      if (obj.hasClass('dropdown-select')) {
        obj.find('.btn').attr('disabled', false);
        return;
      }

      obj.attr('disabled', false);

      if (obj.hasClass('hasDatepicker'))
        obj.datepicker('enable');
    }
  }

  setFieldVisibility(fieldName, flag) {
    this._getInputEl(fieldName).parents('.form-group').toggle(!!flag);
  }

  _getInputEl(fieldName) {
    return $('#' + this.idPrefix + fieldName);
  }

  getInput(fieldName) {
    return this._getInputEl(fieldName)[0];
  }
}
