import { Hooks } from 'Components/Hooks';

import ReconciledList from './ReconciledList';

export default class HookedList extends ReconciledList {
  static isClassComponent = true;

  constructor({ itemKey, itemElementType, ref, children = null, createItemNode = null, createEmptyNode = null }) {
    super({
      itemKey,
      itemElementType,
      ref,
    });

    if (ref) {
      ref(this);
    }

    if (children)
      this.root = this.parent = children;

    if (createItemNode)
      this.createItemNode = createItemNode;

    this._emptyNode = null;
    if (createEmptyNode)
      this._emptyNode = createEmptyNode();
  }

  initState() {
    super.initState();
    this._hookMap = new Map();
    this._hooksExtra = new Hooks();
  }

  renderStart(data, extra) {
    if (this._emptyNode && this._emptyNode.parentElement) {
      this._emptyNode.remove();
    }

    this._hooksExtra.run(extra);

    return new Map();
  }

  renderEnd(newHookMap, data, extra) {
    this._hookMap = newHookMap;

    if (!data.length && this._emptyNode && !this._emptyNode.parentNode) {
      this.parent.appendChild(this._emptyNode);
    }
  }

  createItem(newHookMap, key, itemIdx, itemData, extra) {
    const hooks = new Hooks();

    const node = this.createItemNode(key, hooks, this._hooksExtra);

    hooks.run(itemData);

    newHookMap.set(key, hooks);

    return node;
  }

  updateItem(newHookMap, node, key, itemIdx, itemData, extra) {
    const hooks = this._hookMap.get(key);

    hooks.run(itemData);

    newHookMap.set(key, hooks);
  }
}
