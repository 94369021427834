import { closestUntil, getChildNodeIndex } from 'Components/domHelpers';
import { Table } from './Tables';
import dropper from './dropper';

import { ToggleButton } from './LcmComponents';

import s from './strings';

const DRAG_SRC_ATTR = 'data-drag-src';
const DRAG_DEST_ATTR = 'data-drag-dest';
const DRAG_ACTIVE_BODY_CLASS = 'drag-active';

export default class HandRaisingTable extends Table {
  constructor({ ref, onMove, onCellButtonClick }) {
    super({
      ref,
      onCellButtonClick,
      className: 'nowrap dataTable hand-raising-table',
      thead: false,
      itemKey: 'participantID',
      columns: [
        {
          className: 'drag-handle drag-handle-bg',
        },
        {
          id: 'handRaisingChecked',
          colKey: 'handRaisingChecked',
          className: 'hover-cell',
          create(cell) {
            return <ToggleButton checked={cell} />;
          },
        },
        {
          colKey: 'handRaisedIndexDisplay',
          className: 'handRaisedIndexDisplay text-right',
          create(cell) {
            return <strong>{cell}</strong>;
          },
        },
        {
          colKey: 'callerNameCallerID',
        },
        {
          id: 'actions',
          className: 'actions',
          create() {
            return (
              <div class="btn-toolbar-discrete" role="group">
                <button type="button" class="btn btn-primary" data-action="handSelect">{s.handRaising.select}</button>
                <button type="button" class="btn btn-primary" data-action="handLower">{s.handRaising.lower}</button>
              </div>
            );
          },
        },
        {
          // empty td prevents active drag border from applying to rounded portion
        },
      ],
    });

    this._isDragging = false;

    const checkDragDest = (tr, srcIdx, y, i, len) => {
      const rect = tr.getBoundingClientRect();
      const lastIdx = len - 1;

      let destDir = null;
      if (i === 0 && y < rect.top) {
        if (srcIdx !== 0)
          destDir = 'before';
      } else if (i === lastIdx && y > rect.bottom) {
        if (srcIdx !== lastIdx)
          destDir = 'after';
      } else if (y > rect.top && y <= rect.bottom) {
        if (i < srcIdx) {
          destDir = 'before';
        } else if (i > srcIdx) {
          destDir = 'after';
        }
      }

      return destDir;
    };

    dropper({
      parent: this.parent,

      onDragInitialClick: e => {
        if (!this.root.classList.contains('draggable')) return;

        const { el, isTouch } = e;

        const tr = closestUntil(el, this.parent, 'tr');
        if (!tr) return;

        if (isTouch && !(tr.children[0].contains(el) || tr.children[1].contains(el))) {
          // for touch events, only allow drag to be initiated on first 2 columns
          return;
        }

        return {
          tr,
          idx: getChildNodeIndex(tr),
          key: this.getKeyByElement(el),
        };
      },

      onDragStart: e => {
        const { data } = e;
        const { tr } = data;

        this._isDragging = true;

        document.body.classList.add(DRAG_ACTIVE_BODY_CLASS);
        tr.setAttribute(DRAG_SRC_ATTR, '');
      },

      onDragMove: e => {
        const {
          data: { idx: srcIdx },
          position: { y },
        } = e;

        const trs = this.parent.children;
        const len = trs.length;
        for (let i = 0; i < len; i++) {
          const tr = trs[i];
          const destDir = checkDragDest(tr, srcIdx, y, i, len);
          if (destDir === null) {
            tr.removeAttribute(DRAG_DEST_ATTR);
          } else {
            tr.setAttribute(DRAG_DEST_ATTR, destDir);
          }
        }
      },

      onDrop: e => {
        const {
          data: { idx: srcIdx, key: participantID },
          position: { y },
        } = e;

        this._isDragging = false;

        this._renderDrag();

        const trs = this.parent.children;
        const len = trs.length;
        for (let i = 0; i < len; i++) {
          const tr = trs[i];
          const destDir = checkDragDest(tr, srcIdx, y, i, len);
          if (destDir) {
            if (destDir === 'before' && i === 0) {
              onMove({
                participantID,
                direction: 'top',
              });
              return;
            }
            if (destDir === 'after' && i === len - 1) {
              onMove({
                participantID,
                direction: 'bottom',
              });
              return;
            }

            onMove({
              participantID,
              direction: destDir,
              targetParticipantID: this.getKeyByElement(tr),
            });
            break;
          }
        }
      },
    });
  }

  _renderDrag() {
    document.body.classList.remove(DRAG_ACTIVE_BODY_CLASS);
    const trs = this.parent.children;
    for (let i = 0; i < trs.length; i++) {
      const tr = trs[i];
      tr.removeAttribute(DRAG_SRC_ATTR);
      tr.removeAttribute(DRAG_DEST_ATTR);
    }
  }

  get isDragging() {
    return this._isDragging;
  }
}
