export default (() => {
  var ss;

  try {
    ss = window.sessionStorage; // throws an error when cookies are disabled
    ss.sessionStorageTest = 1; // throws an error in Safari10 private mode

    // cleanup
    delete ss.sessionStorageTest;
    delete ss.__unsupported__;

    return ss; // sessionStorage is accessible, return it as the module
  } catch (e) {
  }

  return { // unsupported, return dummy sessionStorage with unsupported flag
    __unsupported__ : true
  };
})();
