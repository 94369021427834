import { CallController } from './CallController';
import Client from './Client';

export class PlayerCallController extends CallController {
  constructor(opts) {
    super(opts);

    this._loadingState = 'unselected';
    this._fileName = '';

    this._playing = false;
    this._audioBuffer = null;

    this._startOffset = 0;
    this._startTime   = 0;

    this.client.on('stateChange', e => {
      if (e.state === Client.STATE.CONNECTED) {
        this._play();
      }
    });
  }

  onDisconnected() {
    this._startTime = this._startOffset = 0;
    this._stop();

    super.onDisconnected();
  }

  getLocalMediaStream() {
    this.userMediaManager.init();

    const context = this.userMediaManager.getAudioContext();

    this._audioDestination = context.createMediaStreamDestination();
    this.userMediaManager.attachVolumeMeter(this._audioDestination.stream);

    return this._audioDestination.stream;
  }

  loadFile(files, fileName) {
    this._fileName = fileName;
    this._loadingState = 'unselected';

    if (files.length) {
      this._loadingState = 'loading';

      const file = files[0];
      const reader = new FileReader();

      reader.onload = e => {
        this.log('reader.onload success');

        this._setAudioData(e.target.result)
          .then(() => {
            this._loadingState = 'decodeSuccess';
          })
          .catch(err => {
            this._loadingState = 'decodeFailure';
          })
          .then(() => {
            this.emit('update');
          });
      };

      reader.readAsArrayBuffer(file);
    }

    this.emit('update');
  }

  togglePause() {
    this.log('togglePause()');

    if (this._playing)
      this._pause();
    else
      this._play();

    this.emit('update');
  }

  get loadingState() {
    return this._loadingState;
  }

  get fileName() {
    return this._fileName;
  }

  get isPlaying() {
    return this._playing;
  }

  get isPaused() {
    return !this._playing;
  }

  get playbackPosition() {
    if (!this.isConnected)
      return 0;

    const context = this.userMediaManager.getAudioContext();
    return this._playing ?
      Math.floor((context.currentTime - this._startTime) + this._startOffset) :
      Math.floor(this._startOffset);
  }

  _setAudioData(data) {
    // create throwaway context for decodeAudioData
    const context = new this.userMediaManager.AudioContext();

    return new Promise((resolve, reject) => {
      const ret = context.decodeAudioData(data, buffer => {
        if (context && context.close) {
          context.close();
        }

        this._audioBuffer = buffer;
        resolve();
      }, err => {
        if (context && context.close) {
          context.close();
        }

        reject(err);
      });

      if (ret instanceof Promise) {
        ret.catch(err => this.log('decodeAudioData promise rejection', err));
      }
    });
  }

  _play() {
    this.log('_play()');

    const context = this.userMediaManager.getAudioContext();

    this._startTime = context.currentTime;

    // Create the sound source
    this._soundSource = context.createBufferSource();
    this._soundSource.buffer = this._audioBuffer;

    this._soundSource.onended = e => {
      this.log('soundSource.onended');

      this._stop();
      this.disconnect();
    };

    this._soundSource.connect(this._audioDestination);
    this._soundSource.start(0, this._startOffset);

    this._playing = true;
  }

  _stop() {
    this.log('_stop()');

    if (!this._playing)
      return;

    this._soundSource.onended = null;

    this._soundSource.stop();
    this._soundSource.disconnect();

    this._soundSource = null;

    this._playing = false;
  }

  _pause() {
    this.log('_pause()');

    const context = this.userMediaManager.getAudioContext();

    if (this._playing) {
      this._stop();

      this._startOffset += context.currentTime - this._startTime;
    }
  }

  _onTrack(e) {
    // dont actually do anything with remote audio
  }
}
