import { RefFormData } from 'Components/FormComponents';
import FormFile from 'Components/FormFile';
import { Hooks } from 'Components/Hooks';
import Modal from 'Components/Modal';

import {
  AppConnectionForm,
  AppPlayerConnectButton,
  AppConnectionErrorModal,
  PlayerAudioLoadState,
} from './UIComponents';
import EmbeddedPlayerCallUI from './EmbeddedPlayerCallUI';

import s from './strings';

export default function EmbeddedPlayerAppUI({ ctrl }) {
  const connectionForm = new RefFormData();

  const hooks = new Hooks();

  const ctx = {
    hooks,
    ctrl,

    connectionForm,
  };

  let connectModal;

  const root = (
    <div
      class="pnlWebCallApp webcall-compact-player"
    >
      <AppConnectionErrorModal ctx={ctx} anchored={false} />

      <Modal
        title={s.lblPlayAudioFile}
        small
        onClose={() => ctrl.cancelConnect()}
        ref={connectModal}
      >
        <AppConnectionForm ctx={ctx}>
          <div class="modal-body">
            <FormFile inline form={connectionForm} name="audioFile" label={s.lblAudioFile} />

            <PlayerAudioLoadState ctx={ctx} />
          </div>
          <div class="modal-footer">
            <AppPlayerConnectButton ctx={ctx} label={s.lblPlay} />
            <button type="button" class="btn btn-primary" onclick={() => ctrl.cancelConnect()}>
              {s.lblCancel}
            </button>
          </div>
        </AppConnectionForm>
      </Modal>

      <EmbeddedPlayerCallUI ctrl={ctrl.callController} />
    </div>
  );

  const fileInput = connectionForm.getInput('audioFile');
  fileInput.on('change', () => {
    ctrl.callController.loadFile(fileInput.files, fileInput.filename);
  });

  hooks.add('loadingState', state => fileInput.disabled = state === 'loading');

  hooks.add('isConnectModalOpen', val => {
    if (val)
      connectModal.show();
    else
      connectModal.hide();
  });

  ctrl.on('update', () => hooks.run(ctrl));
  hooks.run(ctrl);

  return root;
}
