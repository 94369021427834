import DOMEventEmitter from 'Components/DOMEventEmitter';

const STRINGS = {
  browse: 'Browse',
};

export default class FileInput extends DOMEventEmitter {
  static isClassComponent = true;

  constructor({ ref, id = null }) {
    const refs = {};
    const root = (
      <div class="input-group input-group-file">
        <input type="text" class="form-control" name="fileInputFilename" readonly ref={refs.label} />
        <span class="input-group-btn">
          <label class="btn btn-primary" ref={refs.browseButton}>{STRINGS.browse}&hellip; <input type="file" id={id} ref={refs.input} /></label>
        </span>
      </div>
    );

    super(refs.input);

    if (ref)
      ref(this);

    this.root = root;
    this.refs = refs;

    this._disabled = false;

    this.on('change', e => {
      if (!e.isSynthetic) {
        // only process this for browser-generated change
        // events. ignore events from setFile()
        this._file = null;
        this._updateLabel();
      }
    });
  }

  get files() {
    return this._file
      ? [ this._file ]
      : this.refs.input.files;
  }

  get filename() {
    return this._file
      ? this._file.name
      : this.refs.input.value.replace(/\\/g, '/').replace(/.*\//, '');
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(val) {
    const disabled = !!val;
    if (this._disabled === disabled)
      return;
    this._disabled = disabled;

    this.refs.input.disabled = disabled;
    this.refs.browseButton.classList.toggle('disabled', disabled);
  }

  setFile(file) {
    this._file = file;
    this._updateLabel();

    this.emit('change');
  }

  clear() {
    this._file = null;
    this.refs.input.value = '';
    this.refs.label.value = '';
  }

  _updateLabel() {
    this.refs.label.value = this.filename;
  }
}
