import { RefFormData, FormTextarea, wrapSubmitHandler } from 'Components/FormComponents';
import Modal from 'Components/Modal';
import errorReport from 'Error/report';

import s from './strings';

export default class ErrorReportModal {
  constructor() {
    this._form = new RefFormData();
    this._modal = new Modal({
      appendToBody: true,
      title: s.lblReportError,
      children: (
        <form onsubmit={wrapSubmitHandler(() => this._send())}>
          <div class="modal-body">
            <FormTextarea form={this._form} inline name="value" label={s.lblReportErrorText} inputAttributes={{rows: 3}} />
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{s.lblSend}</button>
            <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblCancel}</button>
          </div>
        </form>
      ),
    });
  }

  display() {
    this._form.set('value', '');
    this._modal.show();
  }

  _send() {
    errorReport.send('User Submitted Error', {
      userMessage: this._form.get('value'),
    });

    this._modal.hide();
  }
}
