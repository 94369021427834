export default class Overlay {
  constructor(parent, hideContent = false) {
    this._parent = parent;

    parent.classList.add('overlay-parent');
    if (hideContent) {
      parent.classList.add('overlay-hide-content');
    }
  }

  show() {
    this._parent.classList.add('overlay-active');
  }

  hide() {
    this._parent.classList.remove('overlay-active');
  }

  toggle(flag) {
    if (flag) {
      this.show();
    } else {
      this.hide();
    }
  }
}
