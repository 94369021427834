export default function parseCustomColumns(customColumnsString) {
  if (!customColumnsString) {
    return [];
  }

  return customColumnsString.split(',').map(_ => {
    const col = _.split(':');
    const type = col[0];
    const name = col[1];
    const keyName = 'custom_' + type + '_' + name;
    const label = col[2];
    const sum = col[3] && col[3] == 'sum';

    return {
      type,
      name,
      keyName,
      label,
      sum
    };
  });
}
