import Api from 'Api/Api';
import logger from 'Log/logger';

const log = logger('Lcm:ConfCommand');

export default class ConfCommand {
  static disconnect() {
    this.send('disconnect', 1);
  }

  static mergeSubConf(subConfID) {
    this.send('mergeSubConf', subConfID);
  }

  static setMute(subConfID, mute) {
    const params = subConfID
      ? { subConf: subConfID }
      : null;

    this.send('setMute', mute, params);
  }

  static lowerAllHands(subConfID) {
    const params = subConfID
      ? { subConf: subConfID }
      : null;

    this.send('lowerAllHands', 1, params);
  }

  static start() {
    this.send('start', '');
  }

  static send(command, value, params) {
    const msg = `send(${command}, ${value})`;
    const reqParams = {
      command,
      value,
    };

    if (params)
      reqParams.params = params;

    Api.get('LCM', 'changeConference', reqParams)
      .then(() => {
        log(`${msg} - Success`);
      })
      .catch(err => {
        // ignore cancelled
        if (err.cancelled) {
          return;
        }
        log(`${msg} - Error`, err);
      });
  }
}
