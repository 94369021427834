import { Cond } from 'Components/FormComponents';
import Modal from 'Components/Modal';

import s from './strings';

export default class HandRaisingModal {
  constructor({ ctrl }) {
    this._ctrl = ctrl;
    this._ctrl.on('update', () => {
      if (!this._ctrl.isConfActive && this._modal.isOpen)
        this._modal.hide();
    });

    this._modal = new Modal({
      appendToBody: true,
      title: s.handRaising.modalTitle,
      children: (
        <div class="modal-body" ref={this._modalContent} />
      ),
    });
  }

  open(participantID) {
    if (!this._ctrl.canManageHands) return;

    const { isSelectAllowed } = this._ctrl.hands;

    this._modalContent.textContent = '';
    this._modalContent.appendChild(
      <div class="modal-action-list">
        <Cond test={isSelectAllowed}>
          <div class="row">
            <div class="col-xs-4">
              <button type="button" class="btn btn-primary" onclick={() => this._select(participantID)}>{s.handRaising.select}</button>
            </div>
            <div class="col-xs-8 note">{s.handRaising.selectNote}</div>
          </div>
        </Cond>
        <div class="row">
          <div class="col-xs-4">
            <button type="button" class="btn btn-primary" onclick={() => this._lower(participantID)}>{s.handRaising.lower}</button>
          </div>
          <div class="col-xs-8 note">{s.handRaising.lowerNote}</div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <button type="button" class="btn btn-primary" onclick={() => this._move(participantID, 'top')}>{s.handRaising.moveToTop}</button>
          </div>
          <div class="col-xs-8 note">{s.handRaising.moveToTopNote}</div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <button type="button" class="btn btn-primary" onclick={() => this._move(participantID, 'up')}>{s.handRaising.moveUp}</button>
          </div>
          <div class="col-xs-8 note">{s.handRaising.moveUpNote}</div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <button type="button" class="btn btn-primary" onclick={() => this._move(participantID, 'down')}>{s.handRaising.moveDown}</button>
          </div>
          <div class="col-xs-8 note">{s.handRaising.moveDownNote}</div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <button type="button" class="btn btn-primary" onclick={() => this._move(participantID, 'bottom')}>{s.handRaising.moveToBottom}</button>
          </div>
          <div class="col-xs-8 note">{s.handRaising.moveToBottomNote}</div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblCancel}</button>
          </div>
          <div class="col-xs-8 note"></div>
        </div>
      </div>
    );

    this._modal.show();
  }

  _select(participantID) {
    if (this._modal.isOpen) {
      this._modal.hide();
    }

    this._ctrl.handSelect(participantID);
  }

  _lower(participantID) {
    if (this._modal.isOpen) {
      this._modal.hide();
    }

    this._ctrl.handLower(participantID);
  }

  _move(participantID, direction) {
    if (this._modal.isOpen) {
      this._modal.hide();
    }

    this._ctrl.handMove(participantID, direction);
  }
}
