import { hook } from 'Components/Hooks';

export default function HookedAlert(props) {
  const { hooks, type = 'danger', showProp = null, textContentProp = null, textContentTransform = null } = props;
  return (
    <div class={`alert alert-${type} alert-icon`} role="alert" use:hook={hooks.textContent(textContentProp, textContentTransform)} use:hook={hooks.show(showProp)}>
      {props.msg || props.children || ''}
    </div>
  );
}
