/*
  Copyright © 2023 TurboBridge®. All Rights Reserved.

  W3C® Software and Document License

  By obtaining and/or copying this work, you (the licensee) agree that
  you have read, understood, and will comply with the following terms
  and conditions.

  Permission to copy, modify, and distribute this work, with or
  without modification, for any purpose and without fee or royalty is
  hereby granted, provided that you include the following on ALL
  copies of the work or portions thereof, including modifications:

  The full text of this NOTICE in a location viewable to users of the
  redistributed or derivative work.

  This software or document includes material copied from or derived
  from Modal Dialog Example
  https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/examples/dialog/.
  Copyright © 2023 World Wide Web Consortium (W3C®).

  This work is distributed under the W3C® Software and Document License
  https://www.w3.org/Consortium/Legal/copyright-software in the hope
  that it will be useful, but WITHOUT ANY WARRANTY; without even the
  implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR
  PURPOSE.

  Disclaimers

  THIS WORK IS PROVIDED "AS IS," AND COPYRIGHT HOLDERS MAKE NO
  REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT
  LIMITED TO, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY
  PARTICULAR PURPOSE OR THAT THE USE OF THE SOFTWARE OR DOCUMENT WILL
  NOT INFRINGE ANY THIRD PARTY PATENTS, COPYRIGHTS, TRADEMARKS OR
  OTHER RIGHTS.

  COPYRIGHT HOLDERS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
  SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF ANY USE OF THE
  SOFTWARE OR DOCUMENT.

  The name and trademarks of copyright holders may NOT be used in
  advertising or publicity pertaining to the work without specific,
  written prior permission. Title to copyright in this work will at
  all times remain with copyright holders.
*/

/*
 * When util functions move focus around, set this true so the focus listener
 * can ignore the events.
 */
let ignoreUtilFocusChanges = false;

/**
 * @description Set focus on descendant nodes until the first focusable element is
 *       found.
 * @param element
 *          DOM node for which to find the first focusable descendant.
 * @returns {boolean}
 *  true if a focusable element is found and focus is set.
 */
export function focusFirstDescendant(element) {
  for (var i = 0; i < element.childNodes.length; i++) {
    var child = element.childNodes[i];
    if (
      attemptFocus(child) ||
        focusFirstDescendant(child)
    ) {
      return true;
    }
  }
  return false;
}

/**
 * @description Find the last descendant node that is focusable.
 * @param element
 *          DOM node for which to find the last focusable descendant.
 * @returns {boolean}
 *  true if a focusable element is found and focus is set.
 */
export function focusLastDescendant(element) {
  for (var i = element.childNodes.length - 1; i >= 0; i--) {
    var child = element.childNodes[i];
    if (
      attemptFocus(child) ||
        focusLastDescendant(child)
    ) {
      return true;
    }
  }
  return false;
}

/**
 * @description Attempt to set focus on the current node.
 * @param element
 *          The node to attempt to focus on.
 * @returns {boolean}
 *  true if element is focused.
 */
export function attemptFocus(element) {
  if (!isFocusable(element)) {
    return false;
  }

  ignoreUtilFocusChanges = true;
  try {
    element.focus();
  } catch (e) {
    // continue regardless of error
  }
  ignoreUtilFocusChanges = false;
  return document.activeElement === element;
}

export function isFocusable(element) {
  if (!element.focus) {
    return false;
  }

  if (element.tabIndex < 0) {
    return false;
  }

  if (element.disabled) {
    return false;
  }

  switch (element.nodeName) {
  case 'A':
    return !!element.href && element.rel != 'ignore';
  case 'INPUT':
    return element.type != 'hidden';
  case 'BUTTON':
  case 'SELECT':
  case 'TEXTAREA':
    return true;
  default:
    return false;
  }
}

export function trapFocusFactory(parent) {
  let lastFocus;

  return event => {
    if (ignoreUtilFocusChanges) {
      return;
    }

    if (parent.contains(event.target)) {
      lastFocus = event.target;
    } else {
      focusFirstDescendant(parent);
      if (lastFocus === document.activeElement) {
        focusLastDescendant(parent);
      }
      lastFocus = document.activeElement;
    }
  };
}
