import ModalConfirm from './ModalConfirm';
import s from './strings';

export default class ModalConfirmDelete {
  constructor({ title, messageTextPost, onConfirm }) {
    this._modal = new ModalConfirm({
      title,
      confirmLabel: s.lblYes,
      cancelLabel: s.lblNo,
      confirm: () => {
        onConfirm(this._params);
      },
    });

    this._modal.setMessageContent(
      <span>
        {s.lblAreYouSureDeletePre} <span class="text-primary item-label" ref={this._label}></span> {messageTextPost}
      </span>
    );
  }

  display(params, label) {
    this._params = params;

    this._label.textContent = label;
    this._modal.display();
  }
}
