import { Cond, RefFormData, FormText } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import Modal from 'Components/Modal';

import {
  AppConnectionForm,
  AppConnectButton,
  AppConnectionErrorModal,
} from './UIComponents';
import EmbeddedConfCallUI from './EmbeddedConfCallUI';

import s from './strings';

export default function EmbeddedConfAppUI({ ctrl }) {
  const connectionForm = new RefFormData();

  const hooks = new Hooks();

  const ctx = {
    hooks,
    ctrl,

    connectionForm,
  };

  let connectModal;

  const root = (
    <div
      class="pnlWebCallApp webcall-compact"
      class:always-listen-only={ctrl.alwaysListenOnly}
    >
      <AppConnectionErrorModal ctx={ctx} anchored={false} />

      <div use:hook={hooks.hide('isActive')}>
        <button type="button" class="btn btn-primary btn-startConnect" onclick={() => ctrl.startConnect()}>{s.lblWebCall}</button>
      </div>

      <Modal
        title={s.lblWebCall}
        small
        onClose={() => ctrl.cancelConnect()}
        ref={connectModal}
      >
        <AppConnectionForm ctx={ctx}>
          <div class="modal-body">
            <FormText inline form={connectionForm} name="fromName" label={s.lblName} inputAttributes={{ autocomplete: 'name' }} />
            <FormText inline form={connectionForm} type="email" name="email" label={s.lblEmail} inputAttributes={{ autocomplete: 'email' }} />
            <div use:hook={hooks.show('userIDEnabled')}>
              <FormText inline form={connectionForm} type="tel" name="userID" label={s.lblUserID} />
            </div>

            <div class="disconnected-menu">
              <Cond test={ctrl.enableSettings}>
                <button type="button" class="btn btn-link" onclick={() => ctrl.openSettings()}>{s.lblSettings}</button>
              </Cond>
            </div>
          </div>
          <div class="modal-footer">
            <AppConnectButton ctx={ctx} />
            <button type="button" class="btn btn-primary" onclick={() => ctrl.cancelConnect()}>
              {s.lblCancel}
            </button>
          </div>
        </AppConnectionForm>
      </Modal>

      <div use:hook={hooks.show('currentCall', val => val === 'conf')}>
        <EmbeddedConfCallUI ctrl={ctrl.callController} />
      </div>
    </div>
  );

  hooks.add('isConnectModalOpen', val => {
    if (val)
      connectModal.show();
    else
      connectModal.hide();
  });

  hooks.add('formDefaults', values => connectionForm.setValues(values));

  ctrl.on('update', () => hooks.run(ctrl));
  hooks.run(ctrl);

  return root;
}
