import EventEmitter from 'events';
import { toggleClass } from 'Components/domHelpers';
import RangeInput from 'Components/RangeInput';
import { MediaElement } from 'Components/MediaElement';
import MediaElementSupport from 'Components/MediaElementSupport';

const LAYOUT_TYPE = 'split';
const CONTROL_BAR_CLASS = 'media-element-player-control-bar';

const LOADED_EVENT = 'canplay';

export default class MediaElementPlayer extends EventEmitter {
  constructor(container) {
    const { volume: volumeSupported } = MediaElementSupport;
    super();

    this._loadComplete = this._loadComplete.bind(this);

    this._mediaElement = new MediaElement();
    this._mediaElement
      .on('timeupdate seeking seeked', e => {
        this._updateCurrentTime();
        if (e.type === 'timeupdate' && !this._mediaElement.seeking) {
          this._updateScrubber();
        }
      })
      .on('durationchange loadeddata loadedmetadata', e => {
        this._updateDuration();
      })
      .on('progress playing', e => {
        this._updateProgress();
      })
      .on('volumechange', e => {
        this._updateVolume();
      })
      .on('playing play pause ended emptied timeupdate', e => {
        this._updatePlaying();
        if (e.type === 'ended') {
          this._mediaElement.currentTime = 0;
        }
      })
      .on('waiting stalled canplay canplaythrough seeked playing', e => {
        const isBuffering = [ 'stalled', 'waiting' ].includes(e.type);
        this._updateBuffering(isBuffering);
      })
      .on('error', e => {
        const { error } = this._mediaElement;

        this.emit('loaderror', {
          error,
        });
      });

    this._createElements(container);

    this._elements.scrubber
      .on('mousedown mouseup keydown keyup touchstart touchend', e => {
        if (e instanceof KeyboardEvent) {
          const code = e.which;
          if (code !== 37 && code !== 39) {
            // ignore key events that are not left or right arrow
            return;
          }
        }

        const done = [ 'mouseup', 'touchend', 'keyup' ].includes(e.type);
        if (done && this._wasPlaying) {
          this._wasPlaying = false;
          this._mediaElement.play();
        } else if (!done && this._mediaElement.playing) {
          this._wasPlaying = true;
          this._mediaElement.pause();
        }
      })
      .on('input', e => {
        const { valueAsNumber: value } = e.target;

        this._mediaElement.currentTime = value * this._mediaElement.duration;
      });

    this._elements.play.onclick = () => {
      const mediaElement = this._mediaElement;

      if (mediaElement.playing) {
        mediaElement.pause();
      } else {
        mediaElement.play();
      }
    };

    if (volumeSupported) {
      this._elements.volume
        .on('input', e => {
          const { valueAsNumber: value } = e.target;

          this.volume = value;
        });

      this.volume = 1;
    } else {
      this._elements.volumeIcon.style.display = 'none';
      this._elements.volume.element.style.display = 'none';
    }

    this._refresh();
  }

  _refresh() {
    this._wasPlaying = false;
    this._updatePlaying();
    this._updateCurrentTime();
    this._updateScrubber();
    this._updateDuration();
    this._updateProgress();
    this._updateVolume();
    this._updateBuffering(false);
  }

  _createElements(container) {
    const play = document.createElement('button');
    play.type = 'button';
    play.className = 'btn btn-primary btn-toggle-playback';

    const scrubber = new RangeInput();
    const progress = document.createElement('progress');
    progress.min = 0;
    progress.max = 100;

    const scrubberControl = document.createElement('div');
    scrubberControl.className = 'scrubber-control';
    scrubberControl.appendChild(scrubber.element);
    scrubberControl.appendChild(progress);

    const position = document.createElement('span');
    const duration = document.createElement('span');
    const timeDisplay = document.createElement('span');
    timeDisplay.appendChild(position);
    timeDisplay.appendChild(document.createTextNode(' / '));
    timeDisplay.appendChild(duration);

    const volumeIcon = document.createElement('span');
    volumeIcon.className = 'volume-icon';
    const volume = new RangeInput();
    volume.element.className = 'volume';

    if (LAYOUT_TYPE === 'split') {
      const toolbar = document.createElement('div');
      toolbar.classList.add(CONTROL_BAR_CLASS);

      toolbar.appendChild(play);
      toolbar.appendChild(scrubberControl);
      container.appendChild(toolbar);

      const toolbar2 = document.createElement('div');
      toolbar2.classList.add(CONTROL_BAR_CLASS);
      toolbar2.classList.add('bottom');

      toolbar2.appendChild(timeDisplay);
      toolbar2.appendChild(volumeIcon);
      toolbar2.appendChild(volume.element);

      container.appendChild(toolbar2);
    } else {
      // LAYOUT_TYPE === 'inline'
      container.appendChild(play);
      container.appendChild(scrubberControl);
      container.appendChild(timeDisplay);
      container.appendChild(volumeIcon);
      container.appendChild(volume.element);
      container.classList.add(CONTROL_BAR_CLASS);
    }

    this._elements = {
      play,
      scrubber,
      progress,
      position,
      duration,
      volumeIcon,
      volume,
    };
  }

  load(url) {
    // refresh DOM. the main reason this is needed is because Safari
    // is inconsistent about emitting timeupdate events when media is
    // loaded and unloaded. it is slightly overkill to update all DOM
    // elements but it can't hurt.
    this._refresh();

    this._mediaElement
      .once(LOADED_EVENT, this._loadComplete)
      .load(url);
  }

  _loadComplete() {
    this.emit('loaded');
  }

  get volume() {
    return this._mediaElement.volume;
  }

  set volume(value) {
    this._mediaElement.volume = value;
  }

  get title() {
    return this._mediaElement.title;
  }

  set title(value) {
    this._mediaElement.title = value;
  }

  unload() {
    this._mediaElement
      .off(LOADED_EVENT, this._loadComplete)
      .load(MediaElementSupport.zeroLengthURL);

    // see note in load()
    this._refresh();
  }

  _updatePlaying() {
    toggleClass(this._elements.play, 'paused', !this._mediaElement.playing);
  }

  _updateDuration() {
    this._elements.duration.textContent = this._formatTimestamp(this._mediaElement.duration);
  }

  _updateCurrentTime() {
    this._elements.position.textContent = this._formatTimestamp(this._mediaElement.currentTime);
  }

  _updateScrubber() {
    this._elements.scrubber.value = (this._mediaElement.currentTime / this._mediaElement.duration) || 0;
  }

  _updateProgress() {
    this._elements.progress.value = this._mediaElement.bufferedTotal * 100;
  }

  _updateBuffering(value) {
    toggleClass(this._elements.progress, 'buffering', value);
  }

  _updateVolume() {
    this._elements.volume.value = this.volume;
  }

  _formatTimestamp(secs) {
    let mins = Math.floor(secs / 60);
    let seconds = Math.floor(secs - mins * 60);

    if (mins < 10)
      mins = '0' + mins;

    if (seconds < 10)
      seconds = '0' + seconds;

    return `${mins}:${seconds}`;
  }
}
