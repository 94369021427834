// calculates R factor
//
// G.107 equation 7-1
// R = R0 - Is - Id - IeEff + A
//
// R0 = basic signal to noise ratio
// Is = simultaneous impairments (not used)
// Id = delay impairments
// IeEff = effective equipment impairment factor
// A = advantage factor (not used)
//
// ppl = packet-loss probability
// rtt = packet round-trip-time (ms)

function get_rFactor(ppl, rtt) {
  var R0 = 93.2, // G.107 Section 7.7

      // from table I.3 of G.113 - G.711 with PLC
      bpl = 25.1, // packet loss robustness factor
      Ie = 0; // equipment impairment factor

  // Effective equipment impairment factor
  // Modified G.107 equation 7-29 to exclude burstR
  //
  // the formula for IeEff with burstR is
  //     IeEff = Ie + (95 - Ie) * (ppl / (ppl/burstR + bpl))

  var IeEff = Ie + (95 - Ie) * (ppl / (ppl + bpl));

  // Ta = absolute delay
  const LOCAL_DELAY = 20;
  var Ta = rtt + LOCAL_DELAY;

  // from G.107 Table 1
  var mT = 100; //Minimum perceivable delay
  var sT = 1; //Delay sensitivity

  var sT6 = sT * 6;

  var Id = 0;
  // G.107 equations 7-27 and 7-28
  if (Ta > mT) {
    var X = Math.log(Ta/mT)/Math.log(2);
    Id = 25 * (
      Math.pow(1 + Math.pow(X, sT6), 1 / sT6) -
        3 * Math.pow(1 + Math.pow(Math.abs(X / 3), sT6), 1 / sT6) +
        2
    );
  }

  return R0 - Id - IeEff;
}

// G.107 equation B-4
function get_mos(r) {
  if (r < 0)
    return 1;
  if (r > 100)
    return 4.5;

  return 1 + 0.035 * r + r * (r - 60) * (100 - r) * 7e-6;
}

export function calculate(ppl, rtt) {
  var rFactor = get_rFactor(ppl, rtt),
      mos = get_mos(rFactor);

  return {
    mos,
    rFactor
  };
}
