import { LoadError } from 'CommonExceptions';

const DEFAULT_COLUMNS = [
  {
    type: 'internal',
    id: 'actionSelect',
  },
  {
    type: 'internal',
    id: 'starred',
  },
  {
    type: 'internal',
    id: 'callerID',
  },
  {
    type: 'internal',
    id: 'callerName',
  },
  {
    type: 'internal',
    id: 'nameRecorded',
  },
  {
    type: 'internal',
    id: 'userID',
  },
  {
    type: 'internal',
    id: 'location',
  },
  {
    type: 'internal',
    id: 'started',
  },
  {
    type: 'internal',
    id: 'durationMinutes',
  },
  {
    type: 'internal',
    id: 'activity',
  },
  {
    type: 'internal',
    id: 'host',
  },
  {
    type: 'internal',
    id: 'muted',
  },
  {
    type: 'internal',
    id: 'handRaised',
  },
];

export default function getColumnConfig(config) {
  if (config.columns && config.columns[0] && typeof config.columns[0] !== 'string')
    return getColumnConfigNew(config.columns);

  if (config.columns || config.customColumns.length)
    return getColumnConfigLegacy(config.columns, config.customColumns);

  return DEFAULT_COLUMNS;
}

function getColumnConfigNew(columns) {
  throw new Error('not implemented');
}

function getColumnConfigLegacy(columns, customColumns) {
  const columnAliases = {
    handRaisedIdx: 'handRaised',
    callerIDDisplay: 'callerID',
    callerNameDisplay: 'callerName',
    lastTalked: 'activity',
  };

  let ret;
  if (columns) {
    ret = columns.map(_ => {
      const id = columnAliases[_] || _;

      if (!DEFAULT_COLUMNS.find(dcol => dcol.id === id))
        throw new LoadError(`unknown col ${id}`);

      return {
        type: 'internal',
        id,
      };
    });
  } else {
    ret = DEFAULT_COLUMNS;
  }

  customColumns.forEach(col => {
    const { label, keyName, sum } = col;
    ret.push({
      type: 'custom',
      prop: keyName,
      title: label,
      sum: !!sum,
    });
  });

  return ret;
}
