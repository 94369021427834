import { AppController } from './AppController';
import { PlayerCallController } from './PlayerCallController';
import Exceptions from './Exceptions';

export class PlayerAppController extends AppController {
  constructor({
    usePinFromForm = false,
  }) {
    super({});

    this.usePinFromForm = usePinFromForm;

    this.callController = new PlayerCallController({
      instanceName: 'PlayerApp',
    });
    this.addCallController(this.callController);

    this.callController.on('update', () => this.emit('update'));
  }

  connect(formData) {
    this.initiateConnection(this.callController, formData, formData => this._getConnectParams(formData));
  }

  get loadingState() {
    return this.callController.loadingState;
  }

  _getConnectParams(formData) {
    let conferenceID;
    if (this._getConferenceID) {
      // used by embedded
      conferenceID = this._getConferenceID();
    } else {
      // used by standalone
      conferenceID = formData.conferenceID.replace(/[^\d#*]/g, '');

      if (!conferenceID.length)
        throw new Exceptions.ConnectionError('ERR_INVALID_CONFERENCE_ID');

      if (this.usePinFromForm) {
        const { pin } = formData;
        if (!pin.match(/^\d+$/)) {
          throw new Exceptions.ConnectionError('ERR_INVALID_PIN');
        }

        conferenceID += `*${pin}`;
      }
    }

    return {
      wsSipURI: this._appConfig.wsSipURI,
      fetchLocationURL: this._appConfig.fetchLocationURL,
      fetchRTCConfigurationURL: this._appConfig.fetchRTCConfigurationURL,

      toSipURI: this._appConfig.toUriPrefix + conferenceID + this._appConfig.toUriSuffix,
      fromSipURI: this._appConfig.fromSipURI
        ? `sip:${this._appConfig.fromSipURI}`
        : 'sip:caller@invalid',
      fromName: this._appConfig.fromName,
    };
  }
}
