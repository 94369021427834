import $ from 'jquery';

import { cachedUpdate } from 'Components/domHelpers';
import Form from 'Components/Form';
import { hook, Hooks } from 'Components/Hooks';

import s from './strings';

export default class ConfSettings {
  static isClassComponent = true;

  constructor({ ctrl, ref }) {
    ref(this);

    const hooks = new Hooks();
    this.hooks = hooks;

    this._ctrl = ctrl;
    this.root = (
      <div>
        <div class="lcm-toolbar conf-settings btn-group btn-group-justified in-main-conf rw-only" role="group" ref={this._toolbar}>
          <div class="btn-group dropdown-select" role="group" id="s_confMode">
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span>
                <span class="setting-label">{s.lblMode}:</span> <span class="value"></span>
              </span>
            </button>
            <ul class="dropdown-menu">
              <li><a href="qa">{s.CONF_MODE_MAP.qa}</a></li>
              <li><a href="conversation">{s.CONF_MODE_MAP.conversation}</a></li>
              <li><a href="presentation">{s.CONF_MODE_MAP.presentation}</a></li>
              <li><a href="hostsOnly">{s.CONF_MODE_MAP.hostsOnly}</a></li>
              <li
                use:hook={hooks.show('confMode', confMode => confMode === 'broadcast')}
              >
                <a href="broadcast">{s.CONF_MODE_MAP.broadcast}</a>
              </li>
            </ul>
          </div>

          <div class="btn-group dropdown-select" role="group" id="s_locked">
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span>
                <span class="setting-label">{s.lblSecurity}:</span> <span class="value"></span>
              </span>
            </button>
            <ul class="dropdown-menu">
              <li><a href="0">{s.lblUnlocked}</a></li>
              <li><a href="1">{s.lblLocked}</a></li>
            </ul>
          </div>

          <div class="btn-group dropdown-select" role="group" id="s_entryChimes">
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span>
                <span class="setting-label">{s.lblEntryAlert}:</span> <span class="value"></span>
              </span>
            </button>
            <ul class="dropdown-menu">
              <li><a href="chime">{s.lblChime}</a></li>
              <li><a href="name">{s.lblName}</a></li>
              <li><a href="none">{s.lblNone}</a></li>
            </ul>
          </div>

          <div class="btn-group dropdown-select" role="group" id="s_exitChimes">
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span>
                <span class="setting-label">{s.lblExitAlert}:</span> <span class="value"></span>
              </span>
            </button>
            <ul class="dropdown-menu">
              <li><a href="chime">{s.lblChime}</a></li>
              <li><a href="name">{s.lblName}</a></li>
              <li><a href="none">{s.lblNone}</a></li>
            </ul>
          </div>

          <div class="btn-group dropdown-select" role="group" id="s_recordCalls" ref={this._recordCallsDropdown}>
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span>
                <span class="setting-label">{s.lblRecording}:</span> <span class="value"></span>
              </span>
            </button>
            <ul class="dropdown-menu">
              <li><a href="2">{s.lblOff}</a></li>
              <li><a href="1">{s.lblOn}</a></li>
            </ul>
          </div>
        </div>
        <div hidden ref={this._disabledDropdowns} />
      </div>
    );

    this.form = new Form();

    this.form.setFieldIDPrefix('s_');
    this.form.setFields({
      confMode    : null,
      entryChimes : null,
      exitChimes  : null,
      locked      : null,
      recordCalls : null
    });

    $(this.root).find('.dropdown-select').on('show.bs.dropdown', function(event) {
      var val = $(this).val();
      var $li = $(this).find('a[href="' + val + '"]').parent('li');

      $(this).find('ul').css('width', $(this).width());

      $(this).find('.active').removeClass('active');
      $li.addClass('active');
    });

    $(this.root).find('a').click(e => {
      e.preventDefault();

      const name = $(e.target).parents('.dropdown-select').attr('id').substr(2);
      const val = $(e.target).attr('href');

      this._ctrl.setConfSetting(name, val);
    });

    this._updaters = {
      isConfActive: cachedUpdate(val => {
        if (val) {
          this.form.unlock();
        } else {
          this.form.lock();
        }
      }),
      recordingAllowed: cachedUpdate(val => {
        if (!val) {
          this._disabledDropdowns.append(this._recordCallsDropdown);
          return;
        }

        this._toolbar.append(this._recordCallsDropdown);
      }),

      confMode: cachedUpdate(val => {
        this.form.setDataField('confMode', val);

        this.form.setDisabled('confMode', !this._ctrl.isConfActive || val === 'broadcast');
      }),
      locked: cachedUpdate(val => {
        this.form.setDataField('locked', val);
      }),
      entryChimes: cachedUpdate(val => {
        this.form.setDataField('entryChimes', val);
      }),
      exitChimes: cachedUpdate(val => {
        this.form.setDataField('exitChimes', val);
      }),
      recordCalls: cachedUpdate(val => {
        this.form.setDataField('recordCalls', val === 0 ? 2 : val);
      }),
    };
  }

  render() {
    const {
      isConfActive,
      settings: {
        confMode,
        locked,
        entryChimes,
        exitChimes,
        recordCalls,
      },
    } = this._ctrl;

    this._updaters.isConfActive(isConfActive);
    this._updaters.recordingAllowed(this._ctrl.features.recording);

    this._updaters.confMode(confMode);
    this._updaters.locked(locked);
    this._updaters.entryChimes(entryChimes);
    this._updaters.exitChimes(exitChimes);
    this._updaters.recordCalls(recordCalls);

    this.hooks.run(this._ctrl);
  }
}
