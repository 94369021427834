import Loader from 'Loader';
import Location from 'Browser/Location';

import s from './strings';
import errors from './errors';

export default function main() {
  Loader.load()
    .then(siteConfig => {
      let errorCode = 'ERR_UNKNOWN';
      const query = Location.query;

      if (query.error && query.error.match(/^[A-Z_]+$/) && query.error in errors) {
        errorCode = query.error;
      }

      document.body.append(
        <div class="portal-container">
          <div class="portal-container-panel">
            <div class="portal-container-panel-inner">
              <div class="logo-container" />

              <div class="panel panel-danger">
                <div class="panel-heading">
                  <h3 class="panel-title">{s.lblError}</h3>
                </div>
                <div class="panel-body">
                  {errors[errorCode]}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })
    .then(() => Loader.setLogo())
    .then(() => Loader.loadComplete())
    .catch(err => Loader.loadError(err));
}
