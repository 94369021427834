import { Cond, wrapSubmitHandler } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import TbIcons from 'Components/TbIcons';

import s from './strings';

export function PagingToolbar({ ctrl }) {
  const hooks = new Hooks();

  ctrl.on('update', () => hooks.run(ctrl));

  let form;
  const onPageChange = () => ctrl.pageSet(form.page.value);

  return (
    <form
      ref={form}
      class="paging-toolbar"
      onsubmit={wrapSubmitHandler(e => e.target.page.blur())}
    >
      <div class="paging-toolbar-nav btn-group" role="group">
        <button
          type="button"
          class="btn btn-primary"
          title={s.lblFirst}
          onclick={() => ctrl.pageFirst()}
        >
          {TbIcons.ARROW_DOUBLE_LEFT}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          title={s.lblPrevious}
          onclick={() => ctrl.pagePrev()}
        >
          {TbIcons.ARROW_LEFT}
        </button>
        <input
          type="text"
          class="form-control"
          name="page"
          use:hook={hooks.prop('!page', 'value')}
          onblur={onPageChange}
        />
        <div use:hook={hooks.text('totalPages')} />
        <button
          type="button"
          class="btn btn-primary"
          title={s.lblNext}
          onclick={() => ctrl.pageNext()}
        >
          {TbIcons.ARROW_RIGHT}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          title={s.lblLast}
          onclick={() => ctrl.pageLast()}
        >
          {TbIcons.ARROW_DOUBLE_RIGHT}
        </button>
      </div>

      <Cond test={ctrl.resultCountOpts}>
        <div class="paging-toolbar-resultCount">
          <span>{s.lblResultCount}</span>

          <div class="btn-group" role="group">
            {ctrl.resultCountOpts.map(rc => <button type="button" class="btn btn-primary" onclick={() => ctrl.setResultCount(rc)} use:hook={hooks.toggleClass('resultCount', 'active', val => val === rc)}>{rc}</button>)}
          </div>
        </div>
      </Cond>
    </form>
  );
}
