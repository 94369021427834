import ClientStorage from 'Browser/ClientStorage';

const STORAGE_KEY = 'lcm_ident';

export default class StoredIdentity {
  static get data() {
    const data = ClientStorage.readJSON(STORAGE_KEY);
    if (data && typeof data === 'object')
      return data;

    return {};
  }

  static set data(value) {
    if (Object.keys(value).length)
      ClientStorage.writeJSON(STORAGE_KEY, value);
    else
      this.delete();
  }

  static get name() {
    const { data } = this;
    if (data.name)
      return data.name;

    return null;
  }

  static set name(value) {
    const { data } = this;
    if (value)
      data.name = value;
    else
      delete data.name;

    this.data = data;
  }

  static delete() {
    ClientStorage.delete(STORAGE_KEY);
  }
}
