import EventEmitter from 'events';

import Api from 'Api/Api';
import { ApiErrorResult } from 'Api/ApiErrors';
import { AuthError } from 'CommonExceptions';
import ClientStorage from 'Browser/ClientStorage';
import logger from 'Log/logger';

import StoredIdentity from './StoredIdentity';
import { sendLoginRequest, checkAuthToken } from './AuthRequest';

import appErrorHandler from './appErrorHandler';

export const LOGIN_LOGGED_OUT      = 0;
export const LOGIN_ENTER_NAME      = 1;
export const LOGIN_FORGOT_PASSWORD = 2;

const BROADCAST_QUEUE_KEY = 'lcm_broadcastQueue';

export class LoginController extends EventEmitter {
  constructor(config) {
    super();

    this.log = logger('LoginController');

    this._apiContext = Api.defaultContext;

    this._loginData = null;
    this._config = config;
    this._sessionParams = config.sessionParams;
    this._apiConfig = config.apiConfig;
    this._storedFieldsKey = config.storedFieldsKey;
    this._baseParams = config.baseParams;
    this._requireName = config.requireName;
    this._storedFieldsMap = {
      username: 'conferenceID',
      password: 'pin',
      ...(config.showName && { name: 'name' }),
    };

    this._initLoginState();
  }

  _initLoginState() {
    this._loginState = {
      state: LOGIN_LOGGED_OUT,
      loggedIn: false,
      errorCode: null,
      loading: false,
      useStoredFields: !this.useAccountAuth,
      showName: this._config.showName,
      forgotPasswordSuccess: false,
      data: {
        username: '',
        password: '',
        name: '',
        saveLoginInfo: false,
      },
      forgotPasswordData: {
        username: '',
      },
      enterNameData: {
        name: '',
      },
    };
  }

  start() {
    const useSessionParams = !ClientStorage.sessionOnly && this._sessionParams;
    const storedAuthToken = this._apiContext.getStoredAuthToken();

    return Promise.resolve()
      .then(() => checkAuthToken(this._apiConfig, storedAuthToken, StoredIdentity.name))
      .catch(err => {
        if (useSessionParams)
          return undefined;

        throw err;
      })
      .then(loginData => {
        if (useSessionParams) {
          // sessionParams operation
          const {
            authToken = null,
            username = null,
            password = null,
            name = null,
          } = this._sessionParams;

          if (loginData) {
            // valid session exists in localStorage, use sessionOnly
            // mode
            ClientStorage.sessionOnly = true;
          }

          this._clearStorage();

          if (authToken) {
            return checkAuthToken(this._apiConfig, authToken, name);
          } else {
            this._loginState.data.username = username;
            this._loginState.data.password = '';
            this._loginState.data.name = name;

            if (password)
              return sendLoginRequest(this._baseParams.partnerID, this._baseParams.lcmSiteID, username, password, name);
          }

          return undefined;
        } else {
          // normal operation
          if (loginData) {
            // pre-existing session exists
            return loginData;
          }

          // no pre-existing session
          this._loadStoredFields();
        }
      })
      .then(loginData => {
        if (loginData) {
          this._loginSuccess(loginData);
        }
      })
      .catch(err => {
        let errorCode;
        if (err.tokenError) {
          // don't display error for login request when authToken is
          // expired
          this._clearStorage();
          errorCode = null;
        } else if (err instanceof AuthError) {
          this._clearStorage();
          errorCode = 'ERR_AUTHENTICATION';
        } else {
          errorCode = appErrorHandler(err);
        }

        this._loginState.errorCode = errorCode;
      })
      .then(() => this._endOperation());
  }

  _loginSuccess(loginData) {
    this.log('_loginSuccess()');

    this._loginData = loginData;

    this._apiContext.logoutCallback = () => this.logout();
    this._apiContext.sessionTimeoutCallback = () => this._sessionTimeoutCallback();
    this._apiContext.setAuthToken(loginData.authToken);

    if (this._requireName && !loginData.identity.name) {
      this._loginState.state = LOGIN_ENTER_NAME;
      return;
    }

    this._loginFinish();
  }

  _loginFinish() {
    StoredIdentity.name = this._loginData.identity.name;

    this._loginState.loggedIn = true;

    this.emit('login', this._loginData);
  }

  logout() {
    if (this.useStoredFields) {
      const storedFields = this.storedFields;
      delete storedFields.password;
      this.storedFields = storedFields;
    }

    this._logout();
  }

  logoutWithError(errorCode) {
    this._logout();
    this._loginState.errorCode = errorCode;
    this._endOperation();
  }

  _logout() {
    this.log('_logout()');

    this.emit('logout');

    this._apiContext.logoutCallback = null;
    this._apiContext.sessionTimeoutCallback = null;
    this._clearStorage();
    if (ClientStorage.sessionOnly) {
      ClientStorage.sessionOnly = false;
      this._clearStorage();
    }
    this._loginData = null;

    this._initLoginState();
    this._loadStoredFields();
    this.emit('update');
  }

  _clearStorage() {
    this._apiContext.clearAuthToken();
    StoredIdentity.delete();
    ClientStorage.delete(BROADCAST_QUEUE_KEY);
  }

  _sessionTimeoutCallback() {
    this.log('_sessionTimeoutCallback()');
    this.logoutWithError('ERR_SESSION_EXPIRED');
  }

  login(authParams) {
    this.log('login()');

    const storedAuthToken = this._apiContext.getStoredAuthToken();

    this._loginState.data = authParams;

    this._beginOperation();

    const { username, password, name = '' } = authParams;

    Promise.resolve()
      .then(() => {
        if (!username)
          throw new ApiErrorResult({
            code: this.useAccountAuth
              ? 'ERR_INVALID_USERNAME_REQUIRED'
              : 'ERR_INVALID_CONFERENCE_ID_REQUIRED'
          });

        if (!password)
          throw new ApiErrorResult({
            code: this.useAccountAuth
              ? 'ERR_INVALID_PASSWORD_REQUIRED'
              : 'ERR_INVALID_PIN_REQUIRED'
          });

        if (!name && this._requireName)
          throw new ApiErrorResult({
            code: 'ERR_INVALID_NAME_REQUIRED'
          });
      })
      .then(() => checkAuthToken(this._apiConfig, storedAuthToken))
      .then(loginData => {
        if (loginData) {
          // valid session exists in localStorage, use sessionOnly
          // mode
          ClientStorage.sessionOnly = true;
        }

        this._clearStorage();

        return sendLoginRequest(this._baseParams.partnerID, this._baseParams.lcmSiteID, username, password, name);
      })
      .then(loginData => {
        this._loginSuccess(loginData);

        this._updateStoredFields(authParams);

        this._loginState.errorCode = null;
      })
      .catch(err => {
        this._loginState.errorCode = appErrorHandler(err);
      })
      .then(() => this._endOperation());
  }

  _loadStoredFields() {
    const { storedFields } = this;
    this._loginState.data = {
      ...storedFields,
      saveLoginInfo: !!storedFields.username,
    };
  }

  _updateStoredFields(authParams) {
    if (!this.useStoredFields)
      return;

    if (authParams.saveLoginInfo) {
      // save stored fields
      this.storedFields = authParams;
    } else {
      // delete stored fields
      this.storedFields = null;
    }
  }

  toggleForgotPassword() {
    this._loginState.errorCode = null;
    this._loginState.state = this._loginState.state === LOGIN_FORGOT_PASSWORD
      ? LOGIN_LOGGED_OUT
      : LOGIN_FORGOT_PASSWORD;
    this._loginState.forgotPasswordSuccess = false;
    this._loginState.forgotPasswordData.username = '';
    this.emit('update');
  }

  submitForgotPassword(data) {
    this._loginState.forgotPasswordData = data;

    this._beginOperation();

    const params = {
      partnerID: this._baseParams.partnerID,
      ...data,
    };

    Api.get('Account', 'forgotPassword', params, {}, 'authNone')
      .then(res => {
        this._loginState.errorCode = null;
        this._loginState.forgotPasswordSuccess = true;
      })
      .catch(err => {
        let errorCode;
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'username') {
            errorCode = 'ERR_INVALID_EMAIL';
          }
        }
        if (!errorCode)
          errorCode = appErrorHandler(err);

        this._loginState.errorCode = errorCode;
      })
      .then(() => this._endOperation());
  }

  submitEnterName(data) {
    this._loginState.enterNameData = data;

    this._beginOperation();

    const { name } = data;

    Promise.resolve()
      .then(() => {
        if (!name)
          throw new ApiErrorResult({
            code: 'ERR_INVALID_NAME_REQUIRED'
          });

        this._loginData.identity.name = name;
        this._loginFinish();
      })
      .catch(err => {
        this._loginState.errorCode = appErrorHandler(err);
      })
      .then(() => this._endOperation());
  }

  get loginState() {
    return this._loginState;
  }

  get useAccountAuth() {
    return this._config.useAccountAuth;
  }

  get useStoredFields() {
    return this._loginState.useStoredFields;
  }

  get storedFields() {
    let stored = {};

    if (this.useStoredFields) {
      const parsed = ClientStorage.readJSON(this._storedFieldsKey);
      if (parsed && typeof parsed === 'object')
        stored = parsed;
    }

    const ret = {};
    for (const [ field, key ] of Object.entries(this._storedFieldsMap)) {
      ret[field] = key in stored && typeof stored[key] === 'string'
        ? stored[key]
        : '';
    }

    return ret;
  }

  set storedFields(value) {
    if (!this.useStoredFields)
      return;

    if (value === null || !Object.keys(value).length) {
      ClientStorage.delete(this._storedFieldsKey);
      return;
    }

    const fields = {};
    for (const [ field, key ] of Object.entries(this._storedFieldsMap)) {
      fields[key] = value[field];
    }

    ClientStorage.writeJSON(this._storedFieldsKey, fields);
  }

  _beginOperation() {
    this._loginState.loading = true;
    this.emit('update');
  }

  _endOperation() {
    this._loginState.loading = false;
    this.emit('update');
  }
}
