import { Cond } from 'Components/FormComponents';
import { toggle } from 'Components/domHelpers';
import { hook, Hooks } from 'Components/Hooks';

import s from './strings';

export default class BridgeInfoTable {
  static isClassComponent = true;

  constructor({ ctrl, maskPin = false, hideConferenceID = false }) {
    this.ctrl = ctrl;
    this.ctrl.on('update', () => this.render());

    this.hooks = new Hooks();
    const hooks = this.hooks;

    this.root =
      <div class="bridge-info-table">
        <div class="bridge-info-name" use:hook={hooks.textContent('name')} />

        <div>
          <div class="info-table-container" use:hook={hooks.show('twoColumns')}>
            <table>
              <tbody>
                <tr>
                  <td>{s.lblToll}:</td>
                  <td class="cell-data" use:hook={hooks.textContent('tollNumberFormatted')} />
                </tr>
                <tr>
                  <td>{s.lblTollFree}:</td>
                  <td class="cell-data" use:hook={hooks.textContent('tollFreeNumberFormatted')} />
                </tr>
              </tbody>
            </table>
          </div>

          <div class="info-table-container">
            <table>
              <tbody>
                <tr use:hook={hooks.show('showSingleNumber')}>
                  <td>{s.lblDialInNumber}:</td>
                  <td class="cell-data" use:hook={hooks.textContent('singleNumber')} />
                </tr>
                <Cond test={!hideConferenceID}>
                  <tr>
                    <td>{s.lblConferenceID}:</td>
                    <td class="cell-data" use:hook={hooks.textContent('conferenceIDFormatted')} />
                  </tr>
                </Cond>
                <tr class="rw-only">
                  <td>{s.lblHostPIN}:</td>
                  <td class="cell-data">
                    <span use:hook={hooks.textContent('pin')} ref={this._pinSpan}></span>
                    <Cond test={maskPin}>
                      {' '}
                      <button type="button" class="btn btn-link" onclick={() => this._togglePinVisibility()} ref={this._btnTogglePin}>{s.lblShow}</button>
                    </Cond>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>;

    if (maskPin) {
      this._setPinVisibility(false);

      this.hooks.add('conferenceIDFormatted', val => {
        if (val === null) {
          this._setPinVisibility(false);
        }
      });
    }
  }

  render() {
    const { bridgeInfo } = this.ctrl;
    const {
      tollNumberFormatted,
      tollFreeNumberFormatted,
    } = bridgeInfo;

    const twoColumns = tollNumberFormatted && tollFreeNumberFormatted;
    const noNumbers = !tollNumberFormatted && !tollFreeNumberFormatted;

    this.hooks.run({
      ...bridgeInfo,
      twoColumns,
      showSingleNumber: !twoColumns && !noNumbers,
      singleNumber: tollNumberFormatted || tollFreeNumberFormatted,
    });
  }

  _togglePinVisibility() {
    this._setPinVisibility(!this._pinVisibility);
  }

  _setPinVisibility(flag) {
    this._pinVisibility = flag;

    toggle(this._pinSpan, flag);
    this._btnTogglePin.textContent = flag
      ? s.lblHide
      : s.lblShow;
  }
}
