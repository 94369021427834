import { hook } from 'Components/Hooks';
import HookedList from 'Components/HookedList';

import { closestUntil, getChildNodeIndex } from 'Components/domHelpers';
import { Cond } from 'Components/FormComponents';
import { formatTimestamp } from 'DateTime';
import ModalAlert from './ModalAlert';
import appErrorHandler from './appErrorHandler';

import { BroadcastTogglePauseButton, BroadcastDisconnectButton, EqSpectrumSvgDynamic } from './BroadcastComponents';

import dropper from './dropper';

import s from './strings';
import TbIcons from 'Components/TbIcons';

const DRAG_SRC_ATTR = 'data-drag-src';
const DRAG_DEST_ATTR = 'data-drag-dest';
const DRAG_ACTIVE_BODY_CLASS = 'drag-active';

const CHECKBOX_ENABLED = false;

export class BroadcastQueueList {
  static isClassComponent = true;

  constructor({ ref, ctrl, onTogglePause, onEndBroadcast }) {
    if (ref) ref(this);

    ctrl.on('update', () => this._list.render(ctrl.broadcastQueue, ctrl));

    this._ctrl = ctrl;

    const getParticipantID = broadcastID => ctrl.getItem(broadcastID).participantID;

    this.root = (
      <HookedList
        ref={this._list}
        itemKey="broadcastID"
        itemElementType="li"
        createItemNode={(broadcastID, hooks, hooksExtra) => {
          return (
            <li class="broadcast-queue-item draggable">
              <Cond test={CHECKBOX_ENABLED}>
                <div class="hover-cell">
                  <CheckboxButton
                    hooks={hooks}
                    checkedProp="isItemSelected"
                    onclick={() => ctrl.toggleItemProperty(broadcastID, 'isItemSelected')}
                  />
                </div>
              </Cond>

              <span class="broadcast-queue-item-count" use:hook={hooks.hide('isInProgress')} />

              <div class="eq-spectrum" use:hook={hooks.show('isInProgress')}>
                <EqSpectrumSvgDynamic hooks={hooks} />
              </div>

              <span use:hook={hooks.textContent('customID')} />

              <span
                class="position-duration ml-auto"
                use:hook={hooks.textContent('duration', duration => formatTimestamp(duration * 1000))}
              />

              <span use:hook={hooksExtra.show('writable')}>
                <span use:hook={hooks.hide('isInProgress')}>
                  <button
                    type="button"
                    class="btn-flat"
                    title={s.Broadcast.play}
                    onclick={() => this._startBroadcast(broadcastID)}
                  >
                    {TbIcons.PLAY}
                  </button>
                  <button
                    type="button"
                    class="btn-flat"
                    title={s.Broadcast.remove}
                    onclick={() => this._ctrl.removeFromQueue(broadcastID)}
                  >
                    {TbIcons.CROSS_CIRCLE}
                  </button>
                </span>

                <span use:hook={hooks.show('isInProgress')}>
                  <BroadcastTogglePauseButton hooks={hooks} onclick={() => onTogglePause(getParticipantID(broadcastID))} />
                  <BroadcastDisconnectButton onclick={() => onEndBroadcast(getParticipantID(broadcastID))} />
                </span>
              </span>
            </li>
          );
        }}
      >
        <ol class="managed-queue-list broadcast-queue" />
      </HookedList>
    );

    this._isDragging = false;

    const checkDragDest = (node, srcIdx, y, i, len) => {
      const rect = node.getBoundingClientRect();
      const lastIdx = len - 1;

      let destDir = null;
      if (i === 0 && y < rect.top) {
        if (srcIdx !== 0)
          destDir = 'before';
      } else if (i === lastIdx && y > rect.bottom) {
        if (srcIdx !== lastIdx)
          destDir = 'after';
      } else if (y > rect.top && y <= rect.bottom) {
        if (i < srcIdx) {
          destDir = 'before';
        } else if (i > srcIdx) {
          destDir = 'after';
        }
      }

      return destDir;
    };


    dropper({
      parent: this.root,

      onDragInitialClick: e => {
        if (!this.root.classList.contains('draggable')) return;

        const { el, isTouch } = e;

        const node = closestUntil(el, this.root, 'li');
        if (!node) return;

        if (isTouch && !(node.children[0].contains(el) || node.children[1].contains(el))) {
          // for touch events, only allow drag to be initiated on first 2 columns
          return;
        }

        return {
          node,
          idx: getChildNodeIndex(node),
          key: this._list.getKeyByElement(el),
        };
      },

      onDragStart: e => {
        const { data } = e;
        const { node } = data;

        this._isDragging = true;

        document.body.classList.add(DRAG_ACTIVE_BODY_CLASS);
        node.setAttribute(DRAG_SRC_ATTR, '');
      },

      onDragMove: e => {
        const {
          data: { idx: srcIdx },
          position: { y },
        } = e;

        const nodes = this.root.children;
        const len = nodes.length;
        for (let i = 0; i < len; i++) {
          const node = nodes[i];
          const destDir = checkDragDest(node, srcIdx, y, i, len);
          if (destDir === null) {
            node.removeAttribute(DRAG_DEST_ATTR);
          } else {
            node.setAttribute(DRAG_DEST_ATTR, destDir);
          }
        }
      },

      onDrop: e => {
        const {
          data: { idx: srcIdx, key },
          position: { y },
        } = e;

        this._isDragging = false;

        this._renderDrag();

        const nodes = this.root.children;
        const len = nodes.length;
        for (let i = 0; i < len; i++) {
          const node = nodes[i];
          const destDir = checkDragDest(node, srcIdx, y, i, len);
          if (destDir) {
            if (destDir === 'before' && i === 0) {
              this._onMove({
                key,
                direction: 'top',
              });
              return;
            }
            if (destDir === 'after' && i === len - 1) {
              this._onMove({
                key,
                direction: 'bottom',
              });
              return;
            }

            this._onMove({
              key,
              direction: destDir,
              targetKey: this._list.getKeyByElement(node),
            });
            break;
          }
        }
      },
    });
  }

  _startBroadcast(broadcastID) {
    this._ctrl.startBroadcast(broadcastID)
      .catch(err => {
        ModalAlert.display(appErrorHandler(err));
      });
  }

  _renderDrag() {
    document.body.classList.remove(DRAG_ACTIVE_BODY_CLASS);
    const nodes = this.root.children;
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      node.removeAttribute(DRAG_SRC_ATTR);
      node.removeAttribute(DRAG_DEST_ATTR);
    }
  }

  _onMove({ key, direction, targetKey = null }) {
    this._ctrl.itemMove(key, direction, targetKey);
  }

  get isDragging() {
    return this._isDragging;
  }
}

function CheckboxButton({ hooks, checkedProp, iconType = 'checkbox', className = null, onclick = null }) {
  let fullClass = 'btn-toggle';
  if (className)
    fullClass += ` ${className}`;

  return (
    <button
      type="button"
      class={fullClass}
      data-icon-type={iconType}
      use:hook={hooks.attr(checkedProp, 'data-checked', null, true)}
      onclick={onclick}
    />
  );
}
