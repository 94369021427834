export default (() => {
  var ls;

  try {
    ls = window.localStorage; // throws an error when cookies are disabled
    ls.localStorageTest = 1; // throws an error in Safari10 private mode

    // cleanup
    delete ls.localStorageTest;
    delete ls.__unsupported__;

    return ls; // localStorage is accessible, return it as the module
  } catch (e) {
  }

  return { // unsupported, return dummy localStorage with unsupported flag
    __unsupported__ : true
  };
})();
