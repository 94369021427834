const MIN_PASSWORD_LEN = 6;
const MAX_PASSWORD_LEN = 64;

export default function validateNewPassword(password, passwordConfirm) {
  const len = password.length;
  if (len < MIN_PASSWORD_LEN || len > MAX_PASSWORD_LEN)
    return 'ERR_INVALID_NEW_PASSWORD';

  if (password !== passwordConfirm)
    return 'ERR_INVALID_NEW_PASSWORD_MATCH';

  return null;
}
