import EventEmitter from 'events';

import localStorage from 'Browser/localStorage';
import MediaElementController from 'Components/MediaElementController';
import logger from 'Log/logger';
import report from 'Error/report';

import Client from './Client';
import {
  DebugClientStatusController,
  DebugCallStatsController,
  DebugWarningStatusController,
} from './DebugController';
import UserMediaManager from './UserMediaManager';
import Exceptions from './Exceptions';

import s from './strings';

const CLIENT_STATE = Client.STATE;

const INITIAL_MOS = 5;
const INITIAL_QUALITY_LEVEL = 4;

const BACKDROP_MIN_WAIT = 100;

export const MIC_STATE_NO_MIC = 0;
export const MIC_STATE_HOLD   = 1;
export const MIC_STATE_MUTED  = 2;
export const MIC_STATE_ACTIVE = 3;

export const DEFAULT_AUDIO_OPTIONS = {
  autoGainControl: true,
  noiseSuppression: true,
  echoCancellation: true,
};

export class CallController extends EventEmitter {
  constructor({ instanceName }) {
    super();

    this._instanceName = instanceName;
    this._listenOnlyDisabled = false;

    this.log = logger(`WebCall:${this._instanceName}:CallController`);

    this._debugMode = false;

    this._muted = false;
    this._hold = false;

    this._isSettingsOpen = false;
    this._isDialpadOpen = false;
    this._isQualityMeterOpen = false;

    this._showBackdrop = false;
    this._backdropTimer = null;

    this._mediaErrorMessage = null;

    this._timerID  = null;
    this._callTime = 0;

    // create <audio> element for WebRTC audio
    const audioElement = new Audio();
    audioElement.title = s.lblAudioTitleRtc;
    audioElement.autoplay = true;

    this.userMediaManager = new UserMediaManager(audioElement);

    this._initClient();

    this.client
      .on('stateChange', this._stateChange.bind(this))
      .on('track', this._onTrack.bind(this))
      .on('statsSample', this._onStatsSample.bind(this))
      .on('statsWarningUpdate', this._onStatsWarningUpdate.bind(this))
      .on('error', this._onError.bind(this));

    this._deviceConfig = this.userMediaManager.deviceConfig;

    this._mosFixed = INITIAL_MOS.toFixed(2);
    this._qualityLevel = INITIAL_QUALITY_LEVEL;
    this._hasWarnings = false;

    this.debugClientStatusController = new DebugClientStatusController();
    this.on('update', () => this._updateDebugClientStatusController());

    this.debugCallStatsController = new DebugCallStatsController();
    this.debugWarningStatusController = new DebugWarningStatusController();
  }

  _initClient() {
    this.client = new Client(this._instanceName);
  }

  setConnectParams(connectParams) {
    this.client.setConnectParams(connectParams);
  }

  connect(listenOnly = false) {
    this.log(`connect() | listenOnly=${listenOnly}`);

    this.onConnectStart();

    return Promise.resolve()
      .then(() => this.getLocalMediaStream(listenOnly))
      .then(mediaStream => this.client.changeMediaStream(mediaStream))
      .then(() => this.client.connect());
  }

  disconnect() {
    if (!this.client.isDisconnected())
      this.client.disconnect();
  }

  onConnectStart() {
    this.debugCallStatsController.clear();
    this.debugWarningStatusController.clear();
  }

  onDisconnected() {
    this._mediaErrorMessage = null;

    this._hasWarnings = false;

    this._isSettingsOpen = false;
    this._isDialpadOpen = false;
    this._isQualityMeterOpen = false;

    this._muted = false;
    this._hold = false;
    this._applyMuteHold();
  }

  toggleMute() {
    this.log('toggleMute()');

    this._muted = !this._muted;
    this._applyMuteHold();

    this.emit('update');
  }

  toggleHold() {
    this.log('toggleHold()');

    this._hold = !this._hold;
    this._applyMuteHold();

    this.emit('update');
  }

  _applyMuteHold() {
    if (!this._isSettingsOpen) {
      this.userMediaManager.micEnabled = !(this._muted || this._hold);
    }
    this.userMediaManager.speakerEnabled = !this._hold;
  }

  toggleDialpad() {
    this._isDialpadOpen = !this._isDialpadOpen;

    this.emit('update');
  }

  sendDtmf(digit) {
    this.client.sendDTMF(digit);

    this._playAudio('audio/beep.wav', s.lblAudioTitleDTMF);
  }

  playTestSound() {
    this._playAudio('audio/testSound.wav', s.lblAudioTitleTestSound);
  }

  openQualityMeter() {
    this._isQualityMeterOpen = true;
    this.emit('update');
  }

  closeQualityMeter() {
    this._isQualityMeterOpen = false;
    this.emit('update');
  }

  getLocalMediaStream(listenOnly = false) {
    this.userMediaManager.init();

    if (listenOnly)
      return null;

    this._setBackdrop(true);

    return this.userMediaManager.getUserMediaInitial(this._storedDeviceConfig)
      .then(() => this._setBackdrop(false))
      .then(() => this.userMediaManager.localMediaStream)
      .catch(err => {
        this._setBackdrop(false);
        throw err;
      });
  }

  mediaErrorRetry() {
    this._mediaErrorMessage = null;

    this.openSettings();
  }

  mediaErrorListenOnly() {
    this._mediaErrorMessage = null;

    this._closeSettings();
  }

  mediaErrorAbort() {
    this._mediaErrorMessage = null;

    if (this.client.isConnected()) {
      this.client.disconnect('mediaError');
    } else {
      this._closeSettings();
    }
  }

  get isActive() {
    return !this.client.isDisconnected();
  }

  get isConnected() {
    return this.client.isConnected();
  }

  get isConnecting() {
    return this.client.isConnecting();
  }

  get hold() {
    return this._hold;
  }

  get muted() {
    return this._muted;
  }

  get muteLocked() {
    return false;
  }

  get micState() {
    if (!this.userMediaManager.localMediaStream)
      return MIC_STATE_NO_MIC;

    if (this._hold)
      return MIC_STATE_HOLD;

    if (this._muted)
      return MIC_STATE_MUTED;

    return MIC_STATE_ACTIVE;
  }

  get inputVolume() {
    return this.userMediaManager.getInputVolume();
  }

  get deviceConfig() {
    return this._deviceConfig;
  }

  get callTime() {
    return this._callTime;
  }

  get isSettingsOpen() {
    return this._isSettingsOpen;
  }

  get isDialpadOpen() {
    return this._isDialpadOpen;
  }

  get isQualityMeterOpen() {
    return this._isQualityMeterOpen;
  }

  get showBackdrop() {
    return this._showBackdrop;
  }

  get mediaErrorMessage() {
    return this._mediaErrorMessage;
  }

  get hasWarnings() {
    return this._hasWarnings;
  }

  get listenOnlyDisabled() {
    return this._listenOnlyDisabled;
  }

  get mosFixed() {
    return this._mosFixed;
  }

  get qualityLevel() {
    return this._qualityLevel;
  }

  get debugMode() {
    return this._debugMode;
  }

  get devicesRaw() {
    return this.userMediaManager.devicesRaw;
  }

  _stateChange(e) {
    this.log(`_stateChange(${e.stateName})`);

    const state = e.state;

    if (state !== CLIENT_STATE.CONNECTED && state !== CLIENT_STATE.CONNECTING && state !== CLIENT_STATE.DISCONNECTED) {
      return;
    }

    switch (e.state) {
    case CLIENT_STATE.CONNECTED:
      this._startTimer();

      break;

    case CLIENT_STATE.DISCONNECTED:
      this.userMediaManager.closeLocalMediaStream();
      this.userMediaManager.setOutputStream(null);

      this._stopAudio();

      this._stopTimer();
      this._setBackdrop(false);

      this.onDisconnected();

      if (e.error) {
        this.emit('error', e.error);
      }

      break;
    }

    this.emit('update');
  }

  _onTrack(e) {
    this.userMediaManager.setOutputStream(e.streams[0]);
  }

  _onStatsSample(sample) {
    const {
      packetsReceived,
      packetsLost,
      packetsSent,
      totalPpl,
      bytesReceived,
      bytesSent,
      maxJitter,
    } = sample.totals;

    const {
      timestamp,

      qualityLevel,
      mos,
      rFactor,
      ppl,
      rtt,
    } = sample;

    const mosFixed = mos.toFixed(2);
    let changed = false;
    if (this._mosFixed !== mosFixed) {
      this._mosFixed = mosFixed;
      changed = true;
    }
    if (this._qualityLevel !== qualityLevel) {
      this._qualityLevel = qualityLevel;
      changed = true;
    }
    if (changed)
      this.emit('update');

    this.debugCallStatsController.update({
      timestamp,

      packetsReceived,
      packetsLost,
      packetsSent,
      totalPpl,
      bytesReceived,
      bytesSent,
      maxJitter,

      qualityLevel,
      mos,
      rFactor,
      ppl,
      rtt,
    });
  }

  _onStatsWarningUpdate(warnings) {
    const hasWarnings = !!Object.keys(warnings).length;
    if (this._hasWarnings !== hasWarnings) {
      this._hasWarnings = hasWarnings;
      this.emit('update');
    }

    this.debugWarningStatusController.update(
      Object.entries(warnings).map(([ name, props ]) => ({
        name,
        ...props,
      }))
    );
  }

  _onError(error) {
    report.send('Client error', null, error);
  }

  get _storedDeviceConfig() {
    return {
      inputDeviceId: localStorage.webCallInputDevice,
      outputDeviceId: localStorage.webCallOutputDevice,
      audioOptions: {
        ...DEFAULT_AUDIO_OPTIONS,
        ...this._storedAudioOptions
      }
    };
  }

  get _storedAudioOptions() {
    return Object.keys(DEFAULT_AUDIO_OPTIONS).reduce((acc, k) => {
      if (localStorage['webCall_ao_' + k] !== undefined) {
        acc[k] = localStorage['webCall_ao_' + k] === '1';
      }
      return acc;
    }, {});
  }

  _startTimer() {
    this._callTime = 0;

    this._timerID = setInterval(() => {
      this._callTime++;
      this.emit('update');
    }, 1000);
  }

  _stopTimer() {
    if (this._timerID)
      clearInterval(this._timerID);
    this._timerID = null;
  }

  _setBackdrop(show) {
    if (show) {
      this._backdropTimer = setTimeout(() => {
        this._showBackdrop = true;
        this.emit('update');
      }, BACKDROP_MIN_WAIT);
    } else {
      if (this._backdropTimer) {
        clearTimeout(this._backdropTimer);
        this._backdropTimer = null;
      }

      this._showBackdrop = false;
      this.emit('update');
    }
  }

  _setMediaError(err) {
    this.log('_setMediaError()', err);
    if (err.cause)
      this.log('_setMediaError() | cause', err.cause);

    this._mediaErrorMessage = err.message;
    this._isSettingsOpen = false;
  }

  _playAudio(url, title) {
    this.log(`_playAudio(${url}, ${title})`);

    MediaElementController
      .init()
      .play(url, title, this.userMediaManager.getOutputDeviceId())
      .catch(() => {
        // swallow errors
      });
  }

  _stopAudio() {
    MediaElementController
      .stop()
      .catch(() => {
        // swallow errors
      });
  }

  openSettings() {
    this.log('openSettings()');

    this._setBackdrop(true);

    this.userMediaManager.init();

    this.client.changeMediaStream(null)
      .then(() => this.userMediaManager.closeLocalMediaStream())
      .then(() => this.userMediaManager.getUserMediaInitial(this._storedDeviceConfig))
      .then(() => {
        this._deviceConfig = this.userMediaManager.deviceConfig;

        // emit update before setting isSettingsOpen so that
        // SettingsForm is populated before modal is displayed
        this.emit('update');

        this._isSettingsOpen = true;
      })
      .catch(err => {
        if (err instanceof Exceptions.MediaError) {
          this._setMediaError(err);
        }
      })
      .then(() => {
        this._setBackdrop(false);
      });
  }

  saveSettings() {
    this.log('saveSettings()');

    const deviceConfig = this.userMediaManager.deviceConfig;

    localStorage.webCallInputDevice = deviceConfig.inputDeviceId;
    localStorage.webCallOutputDevice = deviceConfig.outputDeviceId;

    Object.keys(DEFAULT_AUDIO_OPTIONS).forEach(name => {
      localStorage['webCall_ao_' + name] = deviceConfig.options[name] ? 1 : 0;
    });

    this._setBackdrop(true);

    Promise.resolve()
      .then(() => {
        if (this.client.isConnected()) {
          return this.client.changeMediaStream(this.userMediaManager.localMediaStream);
        } else {
          this.userMediaManager.closeLocalMediaStream();
        }
      })
      .then(() => this._closeSettings())
      .catch(err => {
        // ignore nonMediaError errors. These are handled by Client
        if (err instanceof Exceptions.MediaError) {
          this._setMediaError(err);
        }
      })
      .then(() => {
        this._setBackdrop(false);
      });
  }

  cancelSettings() {
    this.log('cancelSettings()');

    this._setBackdrop(true);

    Promise.resolve()
      .then(() => {
        if (this.client.isConnected()) {
          return this.userMediaManager.getUserMediaInitial(this._storedDeviceConfig)
            .then(() => this.client.changeMediaStream(this.userMediaManager.localMediaStream));
        } else {
          this.userMediaManager.closeLocalMediaStream();
        }
      })
      .then(() => this._closeSettings())
      .catch(err => {
        // ignore nonMediaError errors. These are handled by Client
        if (err instanceof Exceptions.MediaError) {
          this._setMediaError(err);
        }
      })
      .then(() => {
        this._setBackdrop(false);
      });
  }

  _closeSettings() {
    this._isSettingsOpen = false;

    this._stopAudio();
    this._applyMuteHold();

    this.emit('update');
  }

  inputDeviceChange(deviceId, audioOptions) {
    this.log('_inputDeviceChange()');

    this._setBackdrop(true);

    this.userMediaManager.getUserMedia(deviceId, audioOptions)
      .then(() => {
        this.log('_inputDeviceChange() | success');
      })
      .catch(err => {
        this._setMediaError(err);
      })
      .then(() => {
        this._setBackdrop(false);
      });
  }

  outputDeviceChange(deviceId) {
    this.log('_outputDeviceChange()');

    this._setBackdrop(true);

    this.userMediaManager.setOutputDeviceId(deviceId)
      .then(() => {
        this.log('_outputDeviceChange() | success');
      })
      .catch(err => {
        this._setMediaError(err);
      })
      .then(() => {
        this._setBackdrop(false);
      });
  }

  setDebugMode(debugMode) {
    this._debugMode = debugMode;
    this.emit('update');
  }

  _updateDebugClientStatusController() {
    const {
      speakerEnabled,
      micEnabled,
    } = this.userMediaManager;
    this.debugClientStatusController.update({
      speakerEnabled,
      micEnabled,
    });
  }
}
