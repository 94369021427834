import 'shims';

import 'bootstrap/dropdown';

import Modal from 'Components/Modal';

import EditReferenceMain from './EditReferenceMain';
import ErrorMain from './ErrorMain';
import RecordingMain from './RecordingMain';
import ResetPasswordMain from './ResetPasswordMain';
import LcmMain from './LcmMain';

Modal.setScrollbarAdjustment(false);

const entryPointMainModuleName = window.preLoader.config.entryPointMainModuleName;

switch (entryPointMainModuleName) {
case 'EditReferenceMain':
  EditReferenceMain();
  break;

case 'ErrorMain':
  ErrorMain();
  break;

case 'RecordingMain':
  RecordingMain();
  break;

case 'ResetPasswordMain':
  ResetPasswordMain();
  break;

case 'LcmMain':
default:
  LcmMain();
  break;
}
