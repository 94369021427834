import { FormField } from 'Components/FormComponents';
import FileInput from 'Components/FileInput';

export default function FormFile(props) {
  const { form, inline = false, name, rootClass, label, comment = null } = props;
  const id = form.getFieldId(name);

  let root;
  const register = el => {
    form.addField(name, {
      root,
      el,
      label,
      getValue() {
        return el.files;
      },
      setValue(val) {
        throw new TypeError('not supported');
      },
    });
  };

  return (
    <FormField inline={inline} id={id} rootClass={rootClass} label={label} comment={comment} ref={root}>
      <FileInput id={id} ref={register} />
    </FormField>
  );
}
