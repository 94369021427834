import MediaElementController from 'Components/MediaElementController';
import MediaElementSupport from 'Components/MediaElementSupport';

import appErrorHandler from './appErrorHandler';
import ModalAlert from './ModalAlert';
import strings from './strings';

const wavSupported = MediaElementSupport.hasCodec('wav');

export default function playNameRecording(urls, stopOnly, playbackStateCallback) {
  if (!wavSupported) {
    window.open(urls.downloadURL, '_blank');
    return;
  }

  MediaElementController
    .init()
    .stop()
    .then(() => {
      if (!stopOnly) {
        playbackStateCallback(true);
        return MediaElementController.play(urls.srcURL, strings.lblRecording);
      }
    })
    .catch(err => {
      if (err.cancelled) {
        return;
      }

      ModalAlert.display(appErrorHandler(err));
    })
    .then(() => {
      playbackStateCallback(false);
    });
}
