export function emptyNode(node) {
  while (node.firstChild) {
    node.removeChild(node.firstChild);
  }
}

export function toggleClass(node, className, force) {
  if (force) {
    node.classList.add(className);
  } else {
    node.classList.remove(className);
  }
}

export function closestUntil(node, until, selector) {
  let curNode = node;

  for (;;) {
    if (curNode === null) {
      return null;
    }

    if (curNode.matches(selector)) {
      return curNode;
    }

    if (curNode === until || curNode === document.body) {
      return null;
    }

    curNode = curNode.parentNode;
  }
}

export function hide(node) {
  toggle(node, false);
}

export function show(node) {
  toggle(node, true);
}

export function toggle(node, flag) {
  if (flag) {
    node.removeAttribute('hidden');
  } else {
    node.setAttribute('hidden', 'hidden');
  }
}

export function hideAll(selector, root = null) {
  toggleAll(selector, false, root);
}

export function showAll(selector, root = null) {
  toggleAll(selector, true, root);
}

export function toggleAll(selector, flag, root = null) {
  (root || document).querySelectorAll(selector)
    .forEach(node => toggle(node, flag));
}

export function updateContent(root, data, propNames = null, prop = 'textContent') {
  (propNames || Object.keys(data)).forEach(name => {
    root
      .querySelectorAll(`._${name}`)
      .forEach(el => {
        const val = data[name];
        el[prop] = val !== undefined && val !== null
          ? val
          : '';
      });
  });
}

export function isScrolledToBottom(node) {
  return Math.abs(node.scrollHeight - Math.abs(node.scrollTop) - node.clientHeight) <= 1;
}

export function scrollToBottom(node) {
  node.scrollTop = node.scrollHeight - node.clientHeight;
}

export function cachedUpdate(updateFunc) {
  let curVal;
  return function(newVal) {
    if (curVal === newVal) return;

    curVal = newVal;
    updateFunc(curVal);
  };
}

export function getChildNodeIndex(node) {
  return Array.prototype.indexOf.call(node.parentNode.children, node);
}
