import Alert from 'Components/Alert';
import { show, hide } from 'Components/domHelpers';
import { RefFormData, FormText, wrapSubmitHandler } from 'Components/FormComponents';
import Modal from 'Components/Modal';

import s from './strings';

export class ModalForm {
  static isClassComponent = true;

  constructor({ title, onSubmit, children, ref, appendToBody = false }) {
    if (ref) {
      ref(this);
    }

    this.root = (
      <Modal
        title={title}
        appendToBody={appendToBody}
        ref={this._modal}
      >
        <form onsubmit={wrapSubmitHandler(onSubmit)}>
          <div class="modal-body">
            {children}
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{s.lblOK}</button>
            <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblCancel}</button>
          </div>
        </form>
      </Modal>
    );
  }

  show() {
    this._modal.show();
  }

  hide() {
    this._modal.hide();
  }
}

export class SingleInputModal {
  static isClassComponent = true;

  constructor({ title, label, invalidErrorMsg = null, onSave, ref }) {
    if (ref) {
      ref(this);
    }

    this._form = new RefFormData();

    const onSubmit = () => {
      const data = this._form.get('data');

      if (invalidErrorMsg && !data) {
        show(this._alert);
        return;
      }

      this._modalForm.hide();
      onSave(data);
    };

    this._modalForm = new ModalForm({
      title,
      onSubmit,
      appendToBody: true,
      children: (
        <>
          <Alert ref={this._alert} msg={invalidErrorMsg} />
          <FormText
            inline
            form={this._form}
            name="data"
            label={label}
          />
        </>
      ),
    });
  }

  display(initialValue = '') {
    hide(this._alert);
    this._form.set('data', initialValue);
    this._modalForm.show();
  }
}
