import { toggle, show, hide, emptyNode } from 'Components/domHelpers';
import { hook } from 'Components/Hooks';
import { Cond, RefFormData, FormSelect, FormCheckbox, wrapSubmitHandler } from 'Components/FormComponents';
import Modal from 'Components/Modal';
import RangeInput from 'Components/RangeInput';
import TbIcons from 'Components/TbIcons';
import { secondsToHHMMSS } from 'DateTime';

import {
  MIC_STATE_NO_MIC,
  MIC_STATE_HOLD,
  MIC_STATE_MUTED,
  MIC_STATE_ACTIVE,

  DEFAULT_AUDIO_OPTIONS,
} from './CallController';

import { OUT_GAIN_MIN, OUT_GAIN_MAX } from './ConfCallController';

import s from './strings';

const ALLOWED_CONF_MODES = [
  'qa',
  'conversation',
  'presentation',
  'hostsOnly',
];

export function AppConnectionForm({ ctx: { hooks, ctrl, connectionForm }, children }) {
  return (
    <form
      use:hook={hooks.hide('isActive')}
      onsubmit={wrapSubmitHandler(() => ctrl.connect(connectionForm.getAllValues()))}
    >
      {children}
    </form>
  );
}

export function AppConnectButton({ ctx: { ctrl } }) {
  return (
    <button
      type="submit"
      class="btn btn-primary"
    >
      {!ctrl.alwaysListenOnly ? s.lblCall : s.lblConnect}
    </button>
  );
}

export function AppConnectTestCallButton({ ctx: { ctrl, connectionForm } }) {
  return (
    <button
      type="button"
      class="btn btn-link"
      onclick={() => ctrl.connectTestCall(connectionForm.getAllValues())}
    >
      {s.lblTestApp}
    </button>
  );
}

function onClickBlur(action) {
  return e => {
    e.currentTarget.blur();
    action();
  };
}

function ToggleButton({ hooks, field, enabledField = null, disabledField = null, cssClass, tooltip, tooltipOn = null, toggledClass = 'toggled', onclick  }) {
  return (
    <button
      type="button"
      class={`btn btn-primary ${cssClass}`}
      onclick={onClickBlur(onclick)}
      use:hook={hooks.toggleClass(field, toggledClass)}
      use:hook={hooks.attr(field, 'title', val => val ? (tooltipOn !== null ? tooltipOn : tooltip) : tooltip)}
      use:hook={hooks.prop(enabledField, 'disabled', val => !val)}
      use:hook={hooks.prop(disabledField, 'disabled')}
    >
      <span class="tbicon"></span>
    </button>
  );
}

export function ToggleHoldButton({ ctx: { hooks, ctrl } }) {
  return <ToggleButton hooks={hooks} field="hold" cssClass="btnHold" tooltip={s.lblHold} tooltipOn={s.lblUnhold} onclick={() => ctrl.toggleHold()} />;
}

export function ToggleMuteButton({ ctx: { hooks, ctrl } }) {
  return <ToggleButton hooks={hooks} field="muted" disabledField="muteLocked" cssClass="btnMute" tooltip={s.lblMute} tooltipOn={s.lblUnMute} onclick={() => ctrl.toggleMute()} />;
}

export function ToggleHandRaisedButton({ ctx: { hooks, ctrl } }) {
  return <ToggleButton hooks={hooks} field="handRaised" cssClass="btnHand" tooltip={s.lblRaiseHand} tooltipOn={s.lblLowerHand} onclick={() => ctrl.toggleHandRaised()} />;
}

export function ToggleLockedButton({ ctx: { hooks, ctrl } }) {
  return <ToggleButton hooks={hooks} field="locked" enabledField="host" cssClass="btnLock" tooltip={s.lblLock} tooltipOn={s.lblUnlock} onclick={() => ctrl.toggleLocked()} />;
}

export function ToggleRecordingButton({ ctx: { hooks, ctrl } }) {
  return <ToggleButton hooks={hooks} field="recording" enabledField="host" cssClass="btnRecording" tooltip={s.lblStartRecording} tooltipOn={s.lblStopRecording} onclick={() => ctrl.toggleRecording()} />;
}

export function ToggleMusicOnHoldButton({ ctx: { hooks, ctrl } }) {
  return <ToggleButton hooks={hooks} field="musicOnHold" cssClass="btnMusic" tooltip={s.lblMusicOn} tooltipOn={s.lblMusicOff} onclick={() => ctrl.toggleMusicOnHold()} />;
}

export function ToggleDialpadButton({ ctx: { hooks, ctrl } }) {
  return <ToggleButton hooks={hooks} field="isDialpadOpen" cssClass="btnOpenDialpad" tooltip={s.lblToggleKeypad} onclick={() => ctrl.toggleDialpad()} />;
}

export function SettingsIconButton({ ctx: { ctrl } }) {
  return (
    <button
      type="button"
      class="btn btn-primary btnSettings"
      title={s.lblSettings}
      onclick={onClickBlur(() => ctrl.openSettings())}
    >
      <span class="tbicon"></span>
    </button>
  );
}

export function DisconnectIconButton({ ctx: { ctrl } }) {
  return (
    <button
      type="button"
      class="btn btn-primary btnDisconnect"
      title={s.lblEnd}
      onclick={onClickBlur(() => ctrl.disconnect())}
    >
      <span class="tbicon"></span>
    </button>
  );
}

export function DisconnectButton({ ctx: { ctrl } }) {
  return (
    <button
      type="button"
      class="btn btn-primary"
      onclick={onClickBlur(() => ctrl.disconnect())}
    >
      {s.lblEnd}
    </button>
  );
}

export function ChangeRoleButton({ ctx: { hooks, ctrl }, hideWhenHost = true }) {
  return (
    <button
      type="button"
      class="btn btn-primary btnChangeRole"
      title={s.lblChangeRole}
      use:hook={hideWhenHost && hooks.hide('host')}
      use:hook={hooks.prop('host', 'disabled')}
      onclick={onClickBlur(() => ctrl.changeRoleStart())}
    >
      <span class="tbicon"></span>
    </button>
  );
}

export function EndConferenceButton({ ctx: { hooks, ctrl } }) {
  return (
    <button
      type="button"
      class="btn btn-primary btnEndConference"
      title={s.lblEndConference}
      use:hook={hooks.show('host')}
      onclick={onClickBlur(() => ctrl.endConferenceStart())}
    >
      <span class="tbicon"></span>
    </button>
  );
}

export function FinishNameRecordingButton({ ctx: { hooks, ctrl } }) {
  return (
    <button
      type="button"
      class="btn btn-primary"
      use:hook={hooks.show('isNameRecording')}
      onclick={onClickBlur(() => ctrl.finishNameRecording())}
    >
      {s.lblFinishRecording}
    </button>
  );
}

export function StartConferenceButton({ ctx: { hooks, ctrl } }) {
  return (
    <button
      type="button"
      class="btn btn-primary"
      use:hook={hooks.show('canStartConf')}
      onclick={onClickBlur(() => ctrl.startConference())}
    >
      {s.lblStartConf}
    </button>
  );
}

export function StatusMessage({ ctx: { hooks } }) {
  return (
    <div class="status-message" use:hook={hooks.text('statusMessageText')} use:hook={hooks.show('statusMessageVisible')} />
  );
}

export function CallTimer({ ctx: { hooks } }) {
  return (
    <div class="timer" use:hook={hooks.text('callTime', val => secondsToHHMMSS(val))} />
  );
}

export function QualityWarning({ ctx: { hooks } }) {
  return (
    <div class="quality-warning-message" use:hook={hooks.toggleClass('hasWarnings', 'active')}>{s.lblCallQualityWarning}</div>
  );
}

function VolumeControlSlider({ hooks, ctrl }) {
  const input = new RangeInput({
    min: OUT_GAIN_MIN,
    max: OUT_GAIN_MAX,
    step: 1,
  });

  input.on('change', () => ctrl.setOutGain(input.value));
  hooks.add('outGain', val => input.value = val);

  return (
    <div class="meter-control-bar">
      {input.element}
    </div>
  );
}

function VolumeControl({ hooks, ctrl }) {
  return (
    <div class="meter-control volume-control">
      <span class="tbicon">{TbIcons.VOLUME_UP}</span>
      <VolumeControlSlider hooks={hooks} ctrl={ctrl} />
    </div>
  );
}

export function InputVolumeMeter({ ctx: { hooks }, alwaysShow = false }) {
  return (
    <div
      class="meter-control-bar input-meter-gauge"
      use:hook={!alwaysShow && hooks.show('micState', val => val === MIC_STATE_ACTIVE)}
    >
      <div class="input-meter-gauge-level" />
    </div>
  );
}

function MicStatusControl({ ctx: { hooks } }) {
  const MIC_STATUS_MAP = new Map([
    [ MIC_STATE_NO_MIC, s.lblListenOnlyMode ],
    [ MIC_STATE_MUTED,  s.lblMuted ],
    [ MIC_STATE_HOLD,   s.lblOnHold ],
    [ MIC_STATE_ACTIVE, '' ],
  ]);

  return (
    <div class="meter-control mic-status-control">
      <span
        class="tbicon"
        use:hook={hooks.toggleClass('micState', 'visibility-hidden', val => val === MIC_STATE_NO_MIC)}
        use:hook={hooks.text('micState', val => val === MIC_STATE_ACTIVE ? TbIcons.MIC : TbIcons.MIC_OFF)}
      />
      <InputVolumeMeter ctx={{hooks}} />
      <span
        class="mic-status-message"
        use:hook={hooks.show('micState', val => val !== MIC_STATE_ACTIVE)}
        use:hook={hooks.text('micState', val => MIC_STATUS_MAP.get(val))}
      />
    </div>
  );
}

export function registerDrawLoop(ctrl, root) {
  let curInputLevel;

  const drawLoop = () => {
    const newInputLevel = Math.floor(100 * ctrl.inputVolume);
    if (curInputLevel !== newInputLevel) {
      curInputLevel = newInputLevel;
      root.style.setProperty('--inputLevel', `${newInputLevel}%`);
    }

    window.requestAnimationFrame(drawLoop);
  };

  drawLoop();
}

function QualityMeterButton({ hooks, ctrl }) {
  return (
    <button
      type="button"
      class="btn btn-QualityMeterButton"
      title={s.lblCallQuality}
      use:hook={hooks.attr('qualityLevel', 'data-quality-level')}
      onclick={onClickBlur(() => ctrl.openQualityMeter())}
    >
      <span class="tbicon-meter-bar-1"></span>
      <span class="tbicon-meter-bar-2"></span>
      <span class="tbicon-meter-bar-3"></span>
      <span class="tbicon-meter-bar-4"></span>
    </button>
  );
}

export function Meters({ ctx: { hooks, ctrl } }) {
  return (
    <div class="meters">
      <div class="meters-left">
        <MicStatusControl ctx={{hooks}} />
      </div>

      <QualityMeterButton hooks={hooks} ctrl={ctrl} />
    </div>
  );
}

export function ConfMeters({ ctx: { hooks, ctrl } }) {
  return (
    <div class="meters">
      <div class="meters-left">
        <VolumeControl hooks={hooks} ctrl={ctrl} />
        <MicStatusControl ctx={{hooks}} />
      </div>

      <QualityMeterButton hooks={hooks} ctrl={ctrl} />
    </div>
  );
}

export function ConfModeLabel({ ctx: { hooks, ctrl } }) {
  return (
    <div
      class="confModeLabel"
      use:hook={hooks.text('confMode', val => s.CONF_MODE_MAP[val])}
      use:hook={!ctrl.hideHostControls && hooks.hide('canChangeConfMode')}
    />
  );
}

export function ConfModeDropdown({ ctx: { hooks, ctrl } }) {
  if (ctrl.hideHostControls)
    return;

  return (
    <select
      class="form-control confMode"
      name="confMode"
      use:hook={hooks.prop('confMode', 'value')}
      use:hook={hooks.show('canChangeConfMode')}
      onchange={e => ctrl.setConfMode(e.target.value)}
    >
      {ALLOWED_CONF_MODES.map(value => new Option(s.CONF_MODE_MAP[value], value))}
    </select>
  );
}

function DtmfButton({ ctrl, dtmf, label, alpha = null }) {
  return (
    <button type="button" class="btn btn-primary" onclick={onClickBlur(() => ctrl.sendDtmf(dtmf))}>
      <span class="digit">{label}</span>
      <span class="alpha">
        {alpha
          ? alpha
          : <span>&nbsp;</span>}
      </span>
    </button>
  );
}

export function DialpadCollapsible({ ctx: { hooks, ctrl } }) {
  return (
    <div class="sliding" use:hook={hooks.toggleClass('isDialpadOpen', 'toggled')}>
      <Dialpad ctx={{ ctrl }} />
    </div>
  );
}

export function Dialpad({ ctx: { ctrl } }) {
  return (
    <>
      <div class="btn-toolbar-full">
        <DtmfButton ctrl={ctrl} dtmf="1" label={s.lbl1} />
        <DtmfButton ctrl={ctrl} dtmf="2" label={s.lbl2} alpha={s.lblABC} />
        <DtmfButton ctrl={ctrl} dtmf="3" label={s.lbl3} alpha={s.lblDEF} />
      </div>

      <div class="btn-toolbar-full">
        <DtmfButton ctrl={ctrl} dtmf="4" label={s.lbl4} alpha={s.lblGHI} />
        <DtmfButton ctrl={ctrl} dtmf="5" label={s.lbl5} alpha={s.lblJKL} />
        <DtmfButton ctrl={ctrl} dtmf="6" label={s.lbl6} alpha={s.lblMNO} />
      </div>

      <div class="btn-toolbar-full">
        <DtmfButton ctrl={ctrl} dtmf="7" label={s.lbl7} alpha={s.lblPQRS} />
        <DtmfButton ctrl={ctrl} dtmf="8" label={s.lbl8} alpha={s.lblTUV} />
        <DtmfButton ctrl={ctrl} dtmf="9" label={s.lbl9} alpha={s.lblWXYZ} />
      </div>

      <div class="btn-toolbar-full">
        <DtmfButton ctrl={ctrl} dtmf="*" label={s.lblStar} />
        <DtmfButton ctrl={ctrl} dtmf="0" label={s.lbl0}/>
        <DtmfButton ctrl={ctrl} dtmf="#" label={s.lblPound} />
      </div>
    </>
  );
}

export function DialpadModal({ ctx: { hooks, ctrl }, anchored = true }) {
  return (
    <Modal
      ref={registerModalHook(hooks, 'isDialpadOpen')}
      title={s.lblDtmfKeypad}
      small
      anchored={anchored}
      useOverlay={!anchored}
      focusFirst={false}
      onClose={() => ctrl.toggleDialpad()}
    >
      <div class="modal-body">
        <div class="btn-panel">
          <Dialpad ctx={{ ctrl }} />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" onclick={() => ctrl.toggleDialpad()}>
          {s.lblClose}
        </button>
      </div>
    </Modal>
  );
}

export function Backdrop({ ctx: { hooks } }) {
  return (
    <div
      class="modal-backdrop disable-backdrop in"
      use:hook={hooks.show('showBackdrop')}
    />
  );
}

export function ViewConnected({ ctx: { hooks }, children }) {
  return (
    <div
      class="connected"
      use:hook={hooks.show('isConnected')}
    >
      {children}
    </div>
  );
}

export function ViewConnecting({ ctx: { hooks, ctrl } }) {
  let anim;

  hooks.add('isConnecting', isConnecting => {
    if (isConnecting)
      anim.start();
    else
      anim.stop();
  });

  return (
    <div
      class="connecting"
      use:hook={hooks.show('isConnecting')}
    >
      <div class="status">
        {s.lblConnecting}<DotAnimation ref={anim} />
      </div>
      <DisconnectButton ctx={{hooks, ctrl}} />
    </div>
  );
}

export function AppConnectionErrorModal({ ctx: { hooks, ctrl }, anchored = true }) {
  return (
    <Modal
      ref={registerModalHook(hooks, 'connectionErrorMessage')}
      title={s.lblError}
      small
      anchored={anchored}
      useOverlay={!anchored}
      dialogClass="modal-icon modal-dialog-danger"
      onClose={() => ctrl.connectionErrorDismiss()}
    >
      <div class="modal-body">
        <div class="icon">{TbIcons.ALERT_CIRCLE}</div>
        <div class="content" use:hook={hooks.text('connectionErrorMessage')} />
      </div>
      <div class="modal-footer">
        <div class="btn-toolbar-stacked" use:hook={hooks.show('connectionErrorType', val => val !== 'MediaError')}>
          <button
            type="button"
            class="btn btn-primary"
            onclick={() => ctrl.connectionErrorDismiss()}
          >
            {s.lblOK}
          </button>
        </div>
        <div class="btn-toolbar-stacked" use:hook={hooks.show('connectionErrorType', val => val === 'MediaError')}>
          <button
            type="button"
            class="btn btn-primary"
            onclick={() => ctrl.connectionErrorRetry()}
          >
            {s.lblTryAgain}
          </button>

          <button
            type="button"
            class="btn btn-primary"
            use:hook={hooks.hide('listenOnlyDisabled')}
            onclick={() => ctrl.connectionErrorRetry(true)}
          >
            {s.lblListenOnlyMode}
          </button>

          <button
            type="button"
            class="btn btn-primary"
            use:hook={hooks.text('listenOnlyDisabled', val => !val ? s.lblDisconnectFromCall : s.lblClose)}
            onclick={() => ctrl.connectionErrorDismiss()}
          />
        </div>
      </div>
    </Modal>
  );
}

export function MediaErrorModal({ ctx: { hooks, ctrl }, anchored = true }) {
  return (
    <Modal
      ref={registerModalHook(hooks, 'mediaErrorMessage')}
      title={s.lblError}
      anchored={anchored}
      useOverlay={!anchored}
      dialogClass="modal-icon modal-dialog-danger"
      onClose={() => ctrl.mediaErrorAbort()}
    >
      <div class="modal-body">
        <div class="icon">{TbIcons.ALERT_CIRCLE}</div>
        <div class="content" use:hook={hooks.text('mediaErrorMessage')} />
      </div>
      <div class="modal-footer">
        <div class="btn-toolbar-stacked" use:hook={hooks.show('isConnected')}>
          <button
            type="button"
            class="btn btn-primary"
            onclick={() => ctrl.mediaErrorRetry()}
          >
            {s.lblTryAgain}
          </button>

          <button
            type="button"
            class="btn btn-primary"
            use:hook={hooks.hide('listenOnlyDisabled')}
            onclick={() => ctrl.mediaErrorListenOnly()}
          >
            {s.lblListenOnlyMode}
          </button>

          <button
            type="button"
            class="btn btn-primary"
            use:hook={hooks.text('listenOnlyDisabled', val => !val ? s.lblDisconnectFromCall : s.lblClose)}
            onclick={() => ctrl.mediaErrorAbort()}
          />
        </div>
        <div class="btn-toolbar-stacked" use:hook={hooks.hide('isConnected')}>
          <button
            type="button"
            class="btn btn-primary"
            onclick={() => ctrl.mediaErrorRetry()}
          >
            {s.lblTryAgain}
          </button>

          <button
            type="button"
            class="btn btn-primary"
            onclick={() => ctrl.mediaErrorAbort()}
          >
            {s.lblClose}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export function SettingsModal({ ctx: { hooks, ctrl }, anchored = true }) {
  return (
    <Modal
      ref={registerModalHook(hooks, 'isSettingsOpen')}
      title={s.lblSettings}
      small
      anchored={anchored}
      useOverlay={!anchored}
      header={!anchored}
      panel={!anchored}
      onClose={() => ctrl.cancelSettings()}
    >
      <form onsubmit={wrapSubmitHandler(() => ctrl.saveSettings())}>
        <div class="modal-body">
          <SettingsForm hooks={hooks} ctrl={ctrl} />
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary">{s.lblOK}</button>
          <button type="button" class="btn btn-primary" onclick={() => ctrl.cancelSettings()}>{s.lblCancel}</button>
        </div>
      </form>
    </Modal>
  );
}

class SettingsForm extends RefFormData {
  static isClassComponent = true;

  constructor({ hooks, ctrl }) {
    super();

    const getAudioOptions = () => this.getValues(
      Object.keys(DEFAULT_AUDIO_OPTIONS)
    );

    const onInputDeviceChange = () => {
      ctrl.inputDeviceChange(this.get('inputDeviceId'), getAudioOptions());
    };
    const onOutputDeviceChange = () => ctrl.outputDeviceChange(this.get('outputDeviceId'));

    this.root = (
      <>
        <div ref={this._inputDevicesContainer}>
          <FormSelect inline form={this} name="inputDeviceId" label={s.lblInputDevice} />

          <InputVolumeMeter ctx={{hooks}} alwaysShow />
        </div>

        <br />

        <div ref={this._outputDevicesContainer}>
          <FormSelect inline form={this} name="outputDeviceId" label={s.lblOutputDevice} />
        </div>

        <br/>

        <button type="button" class="btn btn-link" onclick={() => ctrl.playTestSound()}>{s.lblPlayTestSound}</button>

        <div ref={this._advancedOff}>
          <br />
          <button type="button" class="btn btn-link" onclick={() => this.showAdvanced()}>{s.lblShowAdvancedSettings}</button>
        </div>

        <div ref={this._advancedOn}>
          <br />
          <FormCheckbox inline form={this} name="autoGainControl" labelRight={s.lblAutomaticGainControl} />
          <FormCheckbox inline form={this} name="noiseSuppression" labelRight={s.lblNoiseSuppression} />
          <FormCheckbox inline form={this} name="echoCancellation" labelRight={s.lblEchoCancellation} />
        </div>
      </>
    );

    this.getInput('inputDeviceId').onchange = onInputDeviceChange;
    this.getInput('outputDeviceId').onchange = onOutputDeviceChange;

    this.getInput('autoGainControl').onchange = onInputDeviceChange;
    this.getInput('noiseSuppression').onchange = onInputDeviceChange;
    this.getInput('echoCancellation').onchange = onInputDeviceChange;

    hooks.add('deviceConfig', deviceConfig => this.render(deviceConfig));
  }

  render(deviceConfig) {
    const {
      inputDevices,
      outputDevices,
      inputDeviceId,
      outputDeviceId,
      options
    } = deviceConfig;

    let inputCtr = 1;
    let outputCtr = 1;

    const inputDeviceSelect = this.getInput('inputDeviceId');
    emptyNode(inputDeviceSelect);
    inputDevices.forEach(device => {
      if (!device.label) {
        device.label = s.lblAudioInputLabelPrefix + ' ' + inputCtr++;
      }

      this._addAudioDevice(inputDeviceSelect, device);
    });

    const outputDeviceSelect = this.getInput('outputDeviceId');
    emptyNode(outputDeviceSelect);
    outputDevices.forEach(device => {
      if (!device.label) {
        device.label = s.lblAudioOutputLabelPrefix + ' ' + outputCtr++;
      }

      this._addAudioDevice(outputDeviceSelect, device);
    });

    this.setValues({
      ...options,
      inputDeviceId,
      outputDeviceId,
    });

    hide(this._advancedOn);
    toggle(this._advancedOff, inputDevices.length);
    toggle(this._inputDevicesContainer, inputDevices.length);

    toggle(this._outputDevicesContainer, outputDevices.length);
  }

  _addAudioDevice(select, device) {
    select.appendChild(
      new Option(device.label, device.deviceId)
    );
  }

  showAdvanced() {
    hide(this._advancedOff);
    show(this._advancedOn);
  }
}

class DotAnimation {
  static isClassComponent = true;

  constructor({ ref }) {
    ref(this);

    this._timer = null;

    this._interval = 250;
    this._maxDots = 3;

    this.root = (
      <span class="dots"></span>
    );
  }

  start() {
    this.stop();

    this.root.textContent = '';

    this._timer = setInterval(() => {
      if (this.root.textContent.length < this._maxDots)
        this.root.textContent += '.';
      else
        this.root.textContent = '';
    }, this._interval);
  }

  stop() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }
}

export function QualityMeterModal({ ctx: { hooks, ctrl }, anchored = true }) {
  return (
    <Modal
      ref={registerModalHook(hooks, 'isQualityMeterOpen')}
      title={s.lblCallQuality}
      small
      anchored={anchored}
      useOverlay={!anchored}
      header={!anchored}
      panel={!anchored}
      onClose={() => ctrl.closeQualityMeter()}
    >
      <div class="modal-body">
        <Cond test={anchored}>
          <div class="quality-meter-title">{s.lblCallQuality}</div>
        </Cond>
        <QualityMeterScore hooks={hooks} />
        <QualityMeterTable />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" onclick={() => ctrl.closeQualityMeter()}>
          {s.lblOK}
        </button>
      </div>
    </Modal>
  );
}

function QualityMeterScore({ hooks }) {
  return (
    <div
      class="quality-meter-score alert"
      role="alert"
      use:hook={hooks.attr('qualityLevel', 'data-quality-level')}
      use:hook={hooks.text('mosFixed')}
    />
  );
}

function QualityMeterTable() {
  return (
    <div class="quality-meter-table">
      <div class="success">
        <div class="score-label">{s.MOSScores.Range380}</div>
        <div>{s.MOSScores.Label380}</div>
      </div>
      <div class="success">
        <div class="score-label">{s.MOSScores.Range330}</div>
        <div>{s.MOSScores.Label330}</div>
      </div>
      <div class="warning">
        <div class="score-label">{s.MOSScores.Range280}</div>
        <div>{s.MOSScores.Label280}</div>
      </div>
      <div class="danger">
        <div class="score-label">{s.MOSScores.Range240}</div>
        <div>{s.MOSScores.Label240}</div>
      </div>
      <div class="danger">
        <div class="score-label">{s.MOSScores.Range200}</div>
        <div>{s.MOSScores.Label200}</div>
      </div>
      <div class="danger">
        <div class="score-label">{s.MOSScores.Range100}</div>
        <div>{s.MOSScores.Label100}</div>
      </div>
    </div>
  );
}

export function ChangeRoleModal({ ctx: { hooks, ctrl }, anchored = true }) {
  let pin;

  hooks.add('isChangeRoleOpen', val => {
    pin.value = '';
  });

  return (
    <Modal
      ref={registerModalHook(hooks, 'isChangeRoleOpen')}
      title={s.lblHostPin}
      small
      anchored={anchored}
      useOverlay={!anchored}
      onClose={() => ctrl.changeRoleCancel()}
    >
      <form onsubmit={wrapSubmitHandler(() => ctrl.changeRoleSubmit(pin.value))}>
        <div class="modal-body">
          <div class="form-group">
            <input type="tel" class="form-control" name="pin" value="" ref={pin} />
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary">{s.lblOK}</button>
          <button type="button" class="btn btn-primary" onclick={() => ctrl.changeRoleCancel()}>{s.lblCancel}</button>
        </div>
      </form>
    </Modal>
  );
}

export function EndConferenceModal({ ctx: { hooks, ctrl }, anchored = true }) {
  return (
    <Modal
      ref={registerModalHook(hooks, 'isEndConferenceOpen')}
      title={s.lblEndConference}
      small
      anchored={anchored}
      useOverlay={!anchored}
      focusFirst={false}
      dialogClass="modal-dialog-danger"
      onClose={() => ctrl.endConferenceCancel()}
    >
      <div class="modal-body">
        {s.lblAreYouSureEndConf}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" onclick={() => ctrl.endConferenceSubmit()}>
          {s.lblOK}
        </button>
        <button type="button" class="btn btn-primary" onclick={() => ctrl.endConferenceCancel()}>
          {s.lblCancel}
        </button>
      </div>
    </Modal>
  );
}

export function FinishTestRecordingButton({ ctx: { hooks, ctrl } }) {
  return (
    <button
      type="button"
      class="btn btn-primary"
      use:hook={hooks.show('isRecording')}
      onclick={onClickBlur(() => ctrl.finishNameRecording())}
    >
      {s.lblFinishRecording}
    </button>
  );
}

export function PlayerAudioLoadState({ ctx: { hooks } }) {
  const STATE_LABELS = {
    unselected: s.lblAnAudioFileMustBeSelected,
    loading: s.lblLoadingAudioFile,
    decodeSuccess: s.lblAudioFileLoaded,
    decodeFailure: s.lblAudioFileInvalid,
  };

  return (
    <div
      class="audioLoadState"
      use:hook={hooks.attr('loadingState', 'data-loading-state')}
      use:hook={hooks.text('loadingState', state => STATE_LABELS[state])}
    />
  );
}

export function AppPlayerConnectButton({ ctx: { hooks, ctrl }, label = s.lblPlayAudioFile }) {
  return (
    <button
      type="submit"
      class="btn btn-primary"
      use:hook={hooks.prop('loadingState', 'disabled', state => state !== 'decodeSuccess')}
    >
      {label}
    </button>
  );
}

export function PlayerPlaybackStatus({ ctx: { hooks } }) {
  return (
    <div
      class="status-message"
      use:hook={hooks.text('isPlaying', val => val
        ? s.lblPlaying
        : s.lblPaused
      )}
    />
  );
}

export function PlayerAudioFileName({ ctx: { hooks } }) {
  return (
    <span
      use:hook={hooks.text('fileName')}
    />
  );
}

export function PlayerPlaybackPosition({ ctx: { hooks } }) {
  return (
    <span
      use:hook={hooks.text('playbackPosition', val => secondsToHHMMSS(val))}
    />
  );
}

export function PlayerTogglePauseButton({ ctx: { hooks, ctrl } }) {
  return <ToggleButton hooks={hooks} field="isPaused" cssClass="btnTogglePause" tooltip={s.lblPause} tooltipOn={s.lblResume} toggledClass="play" onclick={() => ctrl.togglePause()} />;
}

export function EmbeddedPlayerViewConnecting({ ctx: { hooks, ctrl } }) {
  let anim;

  hooks.add('isConnecting', isConnecting => {
    if (isConnecting)
      anim.start();
    else
      anim.stop();
  });

  return (
    <div
      class="connecting"
      use:hook={hooks.show('isConnecting')}
    >
      <div class="status">
        {s.lblConnecting}<DotAnimation ref={anim} />
      </div>
      <DisconnectIconButton ctx={{hooks, ctrl}} />
    </div>
  );
}

export function UnsupportedMessage() {
  return (
    <div class="load-error">
      <div class="panel panel-danger">
        <div class="panel-heading">
          <h3 class="panel-title">{s.lblError}</h3>
        </div>
        <div class="panel-body">
          {s.lblPreLoaderUnsupported}
        </div>
      </div>
    </div>
  );
}

function registerModalHook(hooks, fieldName) {
  return modal => {
    hooks.add(fieldName, val => {
      if (val)
        modal.show();
      else
        modal.hide();
    });
  };
}
