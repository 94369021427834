import { closestUntil } from 'Components/domHelpers';
import reconcile from './reconcile';

export default class ReconciledList {
  constructor({ itemKey = null, itemElementType }) {
    this._itemKey = itemKey;
    this._itemElementType = itemElementType;
    this.initState();
  }

  initState() {
    this._prevData = [];
    this._prevExtra = {};
    this._nodeInfo = new Map();
  }

  render(data, extra = {}) {
    if (!Array.isArray(data))
      throw new TypeError('data must be array');

    const impData = this.renderStart(data, extra);

    const newData = this._itemKey
      ? data.map(item => item[this._itemKey])
      : data;
    const newNodeInfo = new Map();

    reconcile(
      this.parent,
      this._prevData,
      newData,
      (itemIdx, key) => {
        const node = this.createItem(impData, key, itemIdx, data[itemIdx], extra);

        newNodeInfo.set(node, {
          key,
          itemIdx,
        });

        return node;
      },
      (node, itemIdx, key) => {
        this.updateItem(impData, node, key, itemIdx, data[itemIdx], extra);

        newNodeInfo.set(node, {
          key,
          itemIdx,
        });
      },
    );

    this._prevData = newData;
    this._prevExtra = extra;
    this._nodeInfo = newNodeInfo;

    this.renderEnd(impData, data, extra);
  }

  clear() {
    this.parent.textContent = '';
    this.initState();
  }

  getKeyByElement(el) {
    const { key } = this.getNodeInfo(el);
    return key;
  }

  getNodeInfo(el) {
    const node = closestUntil(el, this.parent, this._itemElementType);
    if (!node)
      throw new TypeError('unknown element');

    const info = this._nodeInfo.get(node);
    if (!info)
      throw new TypeError('unknown element');

    return info;
  }
}
