import { ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';
import Alert from 'Components/Alert';
import { show, hide } from 'Components/domHelpers';
import { RefFormData, FormGroup, FormPassword, FormPassthru, wrapSubmitHandler } from 'Components/FormComponents';

import Subpage from './Subpage';
import SubpagePanel from './SubpagePanel';
import validateNewPassword from './validateNewPassword';

import appErrorHandler from './appErrorHandler';
import s from './strings';
import errors from './errors';

export default class AccountAdminSettings extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
      },

      options: {
      }
    };
  }

  init() {
    const root = (
      <div class="subpage">
        <div class="subpage-content">
          <SubpagePanel title={s.ResetPassword.titleAdminSettings}>
            <AccountAdminSettingsForm ref={this._form} />
          </SubpagePanel>
        </div>
      </div>
    );

    super.init(root);
  }

  activate() {
    this._form.render({ adminID: this.ctrl.adminID });
  }
}

class AccountAdminSettingsForm {
  static isClassComponent = true;

  constructor({ ref }) {
    ref(this);

    this._form = new RefFormData();

    this.root = (
      <>
        <Alert type="success" ref={this._successMessage}>{s.ResetPassword.finished}</Alert>
        <Alert ref={this._alert} />

        <form
          class="form-horizontal"
          onsubmit={wrapSubmitHandler(() => this.submit())}
        >
          <FormPassthru form={this._form} name="adminID" />
          <FormPassword form={this._form} name="passwordCurrent" label={s.ResetPassword.passwordCurrent} inputAttributes={{ autocomplete: 'current-password' }} />
          <FormPassword form={this._form} name="password" label={s.ResetPassword.password} inputAttributes={{ autocomplete: 'new-password' }} />
          <FormPassword form={this._form} name="passwordConfirm" label={s.ResetPassword.passwordConfirm} inputAttributes={{ autocomplete: 'new-password' }} />
          <FormGroup>
            <button type="submit" class="btn btn-primary">{s.ResetPassword.save}</button>
          </FormGroup>
        </form>
      </>
    );
  }

  render({ adminID }) {
    this._form.set('adminID', adminID);
    this._clear();
  }

  _clear() {
    hide(this._successMessage);
    hide(this._alert);

    this._form.setValues({
      passwordCurrent: '',
      password: '',
      passwordConfirm: '',
    });
  }

  submit() {
    const showError = errorCode => {
      this._alert.textContent = errors[errorCode];
      show(this._alert);
    };

    hide(this._successMessage);

    const {
      adminID,
      passwordCurrent,
      password,
      passwordConfirm,
    } = this._form.getAllValues();

    let errorCode = validateNewPassword(password, passwordConfirm);
    if (errorCode) {
      showError(errorCode);
      return;
    }

    const params = {
      adminID,
      passwordCurrent,
      password,
    };

    Api.get('Account', 'setAdminPassword', params, {}, 'authNone')
      .then(data => {
        this._clear();
        show(this._successMessage);
      })
      .catch(err => {
        let errorCode;
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'passwordCurrent') {
            errorCode = 'ERR_INVALID_PASSWORD_CURRENT';
          }
        }
        if (!errorCode)
          errorCode = appErrorHandler(err);

        showError(errorCode);
      });
  }
}
