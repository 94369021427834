import { ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';
import Alert from 'Components/Alert';
import { show, hide } from 'Components/domHelpers';
import { RefFormData, FormTextarea, wrapSubmitHandler } from 'Components/FormComponents';
import Modal from 'Components/Modal';
import validateEmail from 'Util/validateEmail';

import appErrorHandler from '../appErrorHandler';
import s from '../strings';
import errors from '../errors';

export default class SendConferenceReportModal {
  constructor() {
    this._form = new RefFormData();
    this._modal = new Modal({
      appendToBody: true,
      title: s.lblEmailConferenceReport,
      children: (
        <form onsubmit={wrapSubmitHandler(() => this._send())}>
          <div class="modal-body">
            <Alert ref={this._alert} />
            <FormTextarea form={this._form} inline name="email" label={s.BridgeFieldDescriptions.notificationList} inputAttributes={{ rows: 3, autocomplete: 'off' }} />
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{s.lblSend}</button>
            <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblCancel}</button>
          </div>
        </form>
      ),
    });
  }

  display(cdrID, notificationList) {
    this._cdrID = cdrID;

    hide(this._alert);
    this._form.set('email', notificationList || '');
    this._modal.show();
  }

  _send() {
    const params = {
      cdrID: this._cdrID,
      notificationList: [],
    };

    const list = this._form.get('email');

    if (list.length > 0) {
      params.notificationList =
        list.split(/,/).map(addr => addr.trim());

      if (params.notificationList.find(addr => !validateEmail(addr))) {
        this._alert.textContent = errors.ERR_INVALID_NOTIFICATION_LIST;
        show(this._alert);
        return;
      }
    }

    Api.get('CDR', 'sendConferenceReport', params)
      .then(result => {
        this._modal.hide();
      })
      .catch(err => {
        let errorCode = 'ERR_UNKNOWN';
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'notificationList') {
            errorCode = 'ERR_INVALID_NOTIFICATION_LIST';
          }
        }
        if (!errorCode) {
          errorCode = appErrorHandler(err);
        }

        this._alert.textContent = errors[errorCode];
        show(this._alert);
      });
  }
}
