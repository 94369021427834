export default class ClientStorageObject {
  constructor(storage) {
    this._storage = storage;
  }

  read(key) {
    return this._storage[key];
  }

  readJSON(key) {
    const json = this.read(key);
    if (json) {
      try {
        return JSON.parse(json);
      } catch (e) {
      }
    }

    return undefined;
  }

  write(key, value) {
    this._storage[key] = value;
  }

  writeJSON(key, value) {
    const json = JSON.stringify(value);
    this.write(key, json);
  }

  delete(key) {
    delete this._storage[key];
  }
}
