import Modal from 'Components/Modal';
import TbIcons from 'Components/TbIcons';

import s from './strings';

export default class ModalConfirm {
  constructor(options) {
    const { title, message, danger = false } = options;

    const confirm = () => {
      this._modal.hide();
      options.confirm(this._data);
    };

    let { icon = null } = options;
    if (!icon) {
      icon = !danger
        ? TbIcons.QUESTION_CIRCLE
        : TbIcons.ALERT_CIRCLE;
    }

    this._modal = new Modal({
      appendToBody: true,
      title,
      dialogClass: `modal-icon ${danger ? 'modal-dialog-danger' : ''}`,
      children: (
        <>
          <div class="modal-body">
            <div class="icon">{icon}</div>
            <div class="content" ref={this._message}></div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" onclick={() => confirm()}>{options.confirmLabel || s.lblOK}</button>
            <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{options.cancelLabel || s.lblCancel}</button>
          </div>
        </>
      ),
    });

    if (message) {
      this._message.innerText = message;
    }
  }

  display(data) {
    this._data = data;
    this._modal.show();
  }

  setMessageContent(content) {
    this._message.textContent = '';
    this._message.appendChild(content);
  }
}
