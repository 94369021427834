import { MediaElement } from './MediaElement';
import MediaElementSupport from 'Components/MediaElementSupport';

const LOADED_EVENT = 'canplaythrough';

function createCancellablePromise(executor) {
  let promiseReject;
  const promise = new Promise((resolve, reject) => {
    promiseReject = reject;
    executor(resolve, reject);
  });

  promise.cancel = () => {
    const err = new Error('cancelled');
    err.cancelled = true;
    promiseReject(err);
  };

  return promise;
}

const DEFER_BODY_EVENTS = [
  'touchstart',
  'touchend',
  'click',
  'keydown',
];

let audioObjectReady = false;
const deferredQueue = [];

const deferMediaElement = e => {
  DEFER_BODY_EVENTS.forEach(
    type => document.removeEventListener(type, deferMediaElement, true)
  );

  if (audioObjectReady)
    return;

  deferredQueue.forEach(fn => fn());
  audioObjectReady = true;
};

DEFER_BODY_EVENTS.forEach(
  type => document.addEventListener(type, deferMediaElement, true)
);

export class AudioObject {
  constructor() {
    deferredQueue.push(() => {
      this.element = new MediaElement();
    });
  }

  static get ready() {
    return audioObjectReady;
  }

  get ready() {
    return audioObjectReady;
  }

  play(url, title = null, sinkId = null) {
    if (this._pendingPromise) {
      this._pendingPromise.cancel();
      this._cleanup();
    }

    this._pendingPromise = createCancellablePromise((resolve, reject) => {
      Promise.resolve()
        .then(() => this.load(url, title))
        .then(() => {
          if (sinkId)
            return this.setSinkId(sinkId);
        })
        .then(() => this.element.play())
        .then(() => this._waitForEvent('ended'))
        .then(() => {
          this._cleanup();
          resolve();
        })
        .catch(err => {
          this._cleanup();
          reject(err);
        });
    });

    return this._pendingPromise;
  }

  stop() {
    if (!this._pendingPromise) {
      return Promise.resolve();
    }

    this._pendingPromise.cancel();
    this._cleanup();

    this._pendingPromise = createCancellablePromise((resolve, reject) => {
      Promise.resolve()
        .then(() => this._load(MediaElementSupport.zeroLengthURL))
        .then(() => {
          this._cleanup();
          resolve();
        })
        .catch(err => {
          this._cleanup();
          reject(err);
        });
    });

    return this._pendingPromise;
  }

  load(url, title = null) {
    const { element } = this;

    if (title) {
      element.title = title;
    }

    return new Promise((resolve, reject) => {
      element.once(`${LOADED_EVENT} error`, e => {
        if (e.type === 'error') {
          reject(element.error);
          return;
        }

        resolve();
      }, { tag: this });
      element.load(url);
    });
  }

  setSinkId(sinkId) {
    return this.element.setSinkId(sinkId)
      .catch(() => {
        // swallow errors
      });
  }

  get playing() {
    return this.ready && this.element.playing;
  }

  _waitForEvent(event) {
    return new Promise(resolve => {
      this.element
        .once(event, () => {
          resolve();
        }, { tag: this });
    });
  }

  _cleanup() {
    this._pendingPromise = null;
    this.element.removeAllListeners(this);
  }
}

export class AudioAlertController {
  constructor(url, title, minWaitTime = 3000) {
    this.audioObject = new AudioObject();
    this._url = url;
    this._title = title;
    this._minWaitTime = minWaitTime;
    this._lastPlayTS = 0;
  }

  play() {
    if (!this.audioObject.ready) {
      return;
    }
    if (this.audioObject.playing) {
      return;
    }

    const curTS = Date.now();
    if (this._lastPlayTS && curTS - this._lastPlayTS < this._minWaitTime) {
      return;
    }

    this.audioObject.play(this._url, this._title)
      .then(() => {
        this._lastPlayTS = Date.now();
      })
      .catch(err => {
        // swallow errors
      });
  }
}
