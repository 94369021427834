import axios from 'axios';

import createQueryString from 'Browser/createQueryString';

import ApiContext from './ApiContext';
import { ApiError, ApiErrorNetwork, ApiErrorEnvelope } from './ApiErrors';
import ApiResponse from './ApiResponse';

const contexts = {};

function sendRequest(ctx, requestGroup, data, opts) {
  const { cancelID = null, returnHttpErrorCode, onUploadProgress, timeout } = opts;

  const cancelToken = ctx.getCancelToken(cancelID);

  const axiosConfig = {
    cancelToken,
    // override transformResponse so that JSON.parse errors don't get
    // swallowed
    transformResponse: data => JSON.parse(data),
    ...(returnHttpErrorCode && {
      validateStatus() {
        return true;
      }
    }),
    ...(onUploadProgress && { onUploadProgress }),
    ...(timeout !== undefined && { timeout }),
  };

  return ctx.axiosInstance.post(requestGroup, data, axiosConfig)
    .catch(err => {
      if (cancelID && !axios.isCancel(err)) {
        ctx.clearCancelToken(cancelID);
      }

      // catch axios errors and wrap them in our ApiErrorNetwork class
      throw new ApiErrorNetwork(err);
    })
    .then(res => {
      if (cancelID) {
        ctx.clearCancelToken(cancelID);
      }

      const envl = res.data;

      if (!envl || typeof envl !== 'object') {
        throw new ApiError('API_ENVELOPE_INVALID');
      }

      const resp = new ApiResponse(envl);

      if (resp.error) {
        const newErr = new ApiErrorEnvelope(resp.error);
        ctx.onEnvelopeError(newErr);
        throw newErr;
      }

      ctx.onResponse(resp);

      if (resp.scope !== undefined) {
        ctx.setScope(resp.scope);
      }

      if (ctx.globalSuccessCallback) {
        ctx.globalSuccessCallback(resp);
      }

      return resp;
    });
}

export default class Api {
  static get(requestGroup, requestType, params, opts = {}, context = 'default') {
    const endpoint = `${requestGroup}/${requestType}`;
    const ctx = this._getContext(context);
    const { returnFullResponse = false, files } = opts;

    ctx.log(`get(${endpoint}) | sending`);

    const requestFormat = files ? 'post' : 'json';
    let data = getRequestData(ctx, requestType, params, opts, requestFormat);

    if (files) {
      const formData = new FormData();

      Object.entries({
        ...data,
        ...files,
      }).forEach(([ name, value ]) => {
        formData.append(name, value);
      });

      data = formData;
    }

    return Promise.resolve()
      .then(() => ctx.onSend())
      .then(() => sendRequest(ctx, requestGroup, data, opts))
      .then(resp => {
        ctx.log(`get(${endpoint}) | success`);

        return !returnFullResponse
          ? resp.getResult()
          : resp;
      })
      .catch(err => {
        ctx.log(`get(${endpoint}) | error`, err);

        throw err;
      });
  }

  static addContext(ctx) {
    contexts[ctx.name] = ctx;
  }

  static get defaultContext() {
    return contexts.default;
  }

  static _getContext(context) {
    if (context instanceof ApiContext)
      return context;

    const ctx = contexts[context];
    if (!ctx) {
      throw new ApiError('API_INVALID_CONTEXT');
    }

    return ctx;
  }

  static spread(...rest) {
    return axios.spread(...rest);
  }

  static getURL(requestGroup, requestType, params, opts = {}, outputFormat = 'json', context = 'default') {
    const ctx = this._getContext(context);
    const data = getRequestData(ctx, requestType, params, opts, 'get', outputFormat);

    return `${ctx.baseURL}${requestGroup}?${createQueryString(data)}`;
  }

  static getCsvURL(requestGroup, requestType, params, errorURL = null, context = 'default') {
    return this.getURL(requestGroup, requestType, params, { errorURL }, 'csv', context);
  }
}

function mungeSubRequestParams(params) {
  // convert js booleans to work with our 0/1 int values
  Object.keys(params).forEach(_ => {
    if (typeof params[_] === 'boolean') {
      params[_] = params[_] ? 1:  0;
    }
  });

  return params;
}

function getRequestData(ctx, requestType, params, opts, requestFormat = 'json', outputFormat = 'json') {
  const { errorURL, returnHttpErrorCode } = opts;

  switch (requestFormat) {
  case 'json':
    return {
      request: {
        ...(outputFormat !== 'json' && { outputFormat }),
        ...ctx.getAuthParams('json'),
        ...ctx.getClientData(),
        ...(errorURL && { errorURL }),
        ...(returnHttpErrorCode && { returnHttpErrorCode: 1 }),
        requestList: [
          {
            [requestType]: mungeSubRequestParams({ ...params })
          }
        ]
      }
    };

  case 'get':
  case 'post':
    return {
      outputFormat,
      requestType,
      ...(ctx.getAuthParams('get')),
      ...ctx.getClientData(),
      ...(errorURL && { errorURL }),
      ...(returnHttpErrorCode && { returnHttpErrorCode: 1 }),
      ...mungeSubRequestParams({ ...params }),
    };
  }
}
