import logger from 'Log/logger';

import createAudioMeter from './createAudioMeter';
import Exceptions from './Exceptions';

const audioOptionsConstraintMap = {
  autoGainControl: [ 'autoGainControl', 'googAutoGainControl', 'googAutoGainControl2', 'mozAutoGainControl' ],
  noiseSuppression: [ 'noiseSuppression', 'googNoiseSuppression', 'googNoiseSuppression2', 'googHighpassFilter', 'mozNoiseSuppression' ],
  echoCancellation: [ 'echoCancellation', 'googEchoCancellation', 'googEchoCancellation2' ]
};

const audioOptionsExtraConstraints = {
  googAudioMirroring: false
};

export default class UserMediaManager {
  constructor(rtcAudioElement) {
    this.log = logger('UserMediaManager');

    this._rtcAudioElement = rtcAudioElement;
    this._actualInputDeviceId = null;
    this._actualOutputDeviceId = null;
    this.localMediaStream = null;
    this._audioIn = null;
    this._currentAudioOptions = null;

    this._devicesRaw = null;
    this._inputDevices = [];
    this._outputDevices = [];

    this.AudioContext = window.AudioContext || window.webkitAudioContext;
    this._canChangeOutputDevice = typeof this._rtcAudioElement.sinkId !== 'undefined';
  }

  init() {
    if (!this._audioIn) {
      this.getAudioContext();
    }
  }

  getUserMediaInitial(deviceConfig) {
    this.log('getUserMediaInitial() | deviceConfig', this._getDeviceConfigLogObject(deviceConfig));

    return Promise.resolve()
      .then(() => {
        if (deviceConfig.inputDeviceId) {
          return this.getUserMedia(deviceConfig.inputDeviceId, deviceConfig.audioOptions)
            .catch(err => {
              if (err.code !== 'ERR_WEBCALL_DEVICE_OVERCONSTRAINED') {
                throw err;
              }
            });
        }
      })
      .then(() => this._enumerateDevices())
      .then(devices => {
        if (this.localMediaStream) {
          return devices;
        }

        if (!devices.inputDevices.length) {
          throw new Exceptions.MediaError('ERR_WEBCALL_NO_INPUT_DEVICES');
        }

        const inputDeviceId = this._getPreferredDevice('input', devices.inputDevices, deviceConfig.inputDeviceId);
        return this.getUserMedia(inputDeviceId, deviceConfig.audioOptions);
      })
      .then(() => this._enumerateDevices())
      .then(devices => {
        const outputDeviceId = this._getPreferredDevice('output', devices.outputDevices, deviceConfig.outputDeviceId);
        return this.setOutputDeviceId(outputDeviceId)
          .catch(err => {
            // ignore setOutputDeviceId for getUserMediaInitial
          });
      });
  }

  getUserMedia(inputDeviceId, audioOptions) {
    var constraints = this._getMediaConstraints(inputDeviceId, audioOptions);

    this.log('getUserMedia()', constraints);

    if (this.localMediaStream) {
      // don't call getUserMedia again if we already have a stream
      // with the requested deviceId and audioOptions

      let deviceMatch = this.getInputDeviceId() === inputDeviceId;
      if (deviceMatch) {
        for (let curSetting in this._currentAudioOptions) {
          if (this._currentAudioOptions[curSetting] !== audioOptions[curSetting]) {
            deviceMatch = false;
            break;
          }
        }
      }

      if (deviceMatch) {
        this.log('getUserMedia() | returning early, device already open');
        return Promise.resolve(this.localMediaStream);
      }
    }

    this.closeLocalMediaStream();

    return navigator.mediaDevices.getUserMedia(constraints).then(stream => {
      this.log('getUserMedia() | success');

      this.localMediaStream = stream;
      if (inputDeviceId) {
        this._actualInputDeviceId = inputDeviceId;
      }
      this._currentAudioOptions = audioOptions;

      this.attachVolumeMeter(stream);

      return stream;
    }, err => {
      this.log('getUserMedia() | error', err);

      throw new Exceptions.MediaError('ERR_WEBCALL_INPUT_DEVICE_ERROR', err);
    });
  }

  closeLocalMediaStream() {
    this.log('closeLocalMediaStream()');

    this._actualInputDeviceId = null;

    if (this.localMediaStream) {
      this.localMediaStream.getTracks().forEach(track => {
        this.log('closeLocalMediaStream() | stopping track');
        track.stop();
      });
    }

    this.localMediaStream = null;
  }

  get speakerEnabled() {
    return !this._rtcAudioElement.muted;
  }

  set speakerEnabled(flag) {
    this.log(`setting speakerEnabled = ${flag}`);

    this._rtcAudioElement.muted = !flag;
  }

  get micEnabled() {
    const track = this._getLocalAudioTrack();

    return !!(track && track.enabled);
  }

  set micEnabled(flag) {
    this.log(`setting micEnabled = ${flag}`);

    const track = this._getLocalAudioTrack();
    if (track !== null) {
      track.enabled = !!flag;
    }
  }

  setOutputStream(stream) {
    if (this._rtcAudioElement.srcObject !== undefined) {
      this._rtcAudioElement.srcObject = stream;
    } else {
      this._rtcAudioElement.src = stream
        ? window.URL.createObjectURL(stream)
        : '';
    }
  }

  getCurrentAudioOptions() {
    // return copy
    return Object.assign({}, this._currentAudioOptions);
  }

  getInputDeviceId() {
    const track = this._getLocalAudioTrack();
    if (track) {
      const trackSettings = track.getSettings();
      if ('deviceId' in trackSettings) {
        return trackSettings.deviceId;
      }
    }

    return this._actualInputDeviceId;
  }

  getOutputDeviceId() {
    return this._actualOutputDeviceId;
  }

  setOutputDeviceId(deviceId) {
    if (typeof this._rtcAudioElement.sinkId !== 'undefined') {
      return this._rtcAudioElement.setSinkId(deviceId).then(() => {
        this.log('setOutputDeviceId() | success');

        this._actualOutputDeviceId = deviceId;
      }, err => {
        this.log('setOutputDeviceId() | error', err);
        throw new Exceptions.MediaError('ERR_WEBCALL_OUTPUT_DEVICE_ERROR', err);
      });
    } else {
      this.log('setOutputDeviceId() | output device selection is not supported for this browser');

      return Promise.resolve();
    }
  }

  getAudioContext() {
    if (!this._audioIn) {
      this.log('getAudioContext()');

      this._audioIn = {};

      this._audioIn.context = new this.AudioContext();
      this._audioIn.meter   = createAudioMeter(this._audioIn.context);
    }

    return this._audioIn.context;
  }

  attachVolumeMeter(stream) {
    // remove previous source from AudioContext graph
    if (this._audioIn.source)
      this._audioIn.source.disconnect();

    this._audioIn.source = this._audioIn.context.createMediaStreamSource(stream);
    this._audioIn.source.connect(this._audioIn.meter);
  }

  getInputVolume() {
    if (this._audioIn && this._audioIn.meter)
      return this._audioIn.meter.volume;

    return 0;
  }

  get devicesRaw() {
    return this._devicesRaw;
  }

  get deviceConfig() {
    return {
      inputDevices: this._inputDevices,
      outputDevices: this._outputDevices,
      inputDeviceId: this.getInputDeviceId(),
      outputDeviceId: this.getOutputDeviceId(),
      options: this.getCurrentAudioOptions(),
    };
  }

  _getLocalAudioTrack() {
    if (!this.localMediaStream)
      return null;

    var tracks = this.localMediaStream.getAudioTracks();

    if (tracks.length > 0)
      return tracks[0];

    return null;
  }

  _getMediaConstraints(inputDeviceId, audioOptions) {
    const audioConstraints = {
      advanced : [],
      ...(inputDeviceId && {
        deviceId : {
          exact : inputDeviceId
        }
      }),
    };

    // construct contraints object using map
    for (let optionName in audioOptions) {
      if (!audioOptionsConstraintMap[optionName])
        continue; // skip option if it is not in the constraint map

      const optionValue = audioOptions[optionName];
      audioOptionsConstraintMap[optionName].forEach(constraintName => {
        audioConstraints.advanced.push({
          [constraintName]: optionValue
        });
      });
    }

    // add additional unmapped constraints
    for (let constraintName in audioOptionsExtraConstraints) {
      audioConstraints.advanced.push({
        [constraintName]: audioOptionsExtraConstraints[constraintName]
      });
    }

    return {
      video : false,
      audio : audioConstraints
    };
  }

  _enumerateDevices() {
    return navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        this.log(`_enumerateDevices() | success ${JSON.stringify(devices)}`);

        this._devicesRaw = devices;

        return this._processDevices(devices);
      })
      .then(devices => {
        this._inputDevices = devices.inputDevices;
        this._outputDevices = devices.outputDevices;

        return devices;
      })
      .catch(err => {
        this.log('_enumerateDevices() | error', err);
        throw err;
      });
  }

  _getPreferredDevice(type, devices, storedId) {
    var comm        = null, // communications device
        def         = null, // device with deviceId of "default"
        first       = null, // first device in array
        stored      = null; // found deviceId in deviceList

    devices.forEach(function(device) {
      if (device.deviceId === 'communications' && !comm)
        comm = device;

      if (device.deviceId === 'default' && !def)
        def = device;

      if (!first)
        first = device;

      if (storedId && device.deviceId === storedId) {
        stored = device;
      }
    });

    if (stored) {
      this.log(type + ': using stored device', stored);
      return stored.deviceId;
    } else if (comm) {
      this.log(type + ': using communications device', comm);
      return comm.deviceId;
    } else if (def) {
      this.log(type + ': using default device', def);
      return def.deviceId;
    } else if (first) {
      this.log(type + ': using first device', first);
      return first.deviceId;
    } else {
      this.log(type + ': not setting device');
    }

    return null;
  }

  _processDevices(devices) {
    const groupLabels = {};
    const inputDevices = [];
    const outputDevices = [];

    function setDeviceLabel(device) {
      // if there is no label, see if another device
      // with that groupId has a label
      if (!device.label && groupLabels[device.groupId]) {
        device.label = groupLabels[device.groupId];
      }
    }

    // add devices
    devices.forEach(cur => {
      const newDev = {
        deviceId: cur.deviceId,
        groupId: cur.groupId,
        kind: cur.kind,
        label: cur.label
      };

      if (cur.groupId && cur.label) {
        groupLabels[cur.groupId] = cur.label;
      }

      if (cur.kind === 'audioinput') {
        inputDevices.push(newDev);
      }

      if (cur.kind === 'audiooutput' && this._canChangeOutputDevice) {
        outputDevices.push(newDev);
      }
    });

    inputDevices.forEach(setDeviceLabel);
    outputDevices.forEach(setDeviceLabel);

    return {
      inputDevices,
      outputDevices
    };
  }

  _getDeviceConfigLogObject(deviceConfig) {
    const { inputDeviceId, outputDeviceId, audioOptions } = deviceConfig;

    return {
      inputDeviceId,
      outputDeviceId,
      ...audioOptions
    };
  }
}
