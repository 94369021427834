import EventEmitter from 'events';

export default class HashController extends EventEmitter {
  constructor() {
    super();

    this._currentValue = null;

    window.addEventListener('hashchange', () => {
      if (window.location.hash === this._currentValue) {
        return;
      }

      this.emit('change', window.location.hash);
    });
  }

  set(hash) {
    this._currentValue = '#' + hash;

    window.location.hash = hash;
  }
}
