const ZERO_LENGTH_MP3 = 'data:audio/mp3;base64,//IYzHqOAAAAAlwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//IYzINtFQAAAlwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//IYzAlNKgAAAlwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//IYzPCuPwAAAlwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//IYzJ0IVAAAAlwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA';

const isAudioSupported = () => {
  try {
    const el = new Audio();
    if (el.muted) {
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
};

const isVolumeSupported = () => {
  const el = new Audio();
  el.volume = 0.5;
  return el.volume === 0.5;
};

export default class MediaElementSupport {
  static _init() {
    this.supported = isAudioSupported();
    this.volume = !!(this.supported && isVolumeSupported());
    this.zeroLengthURL = ZERO_LENGTH_MP3;

    // init codecs
    this._codecs = {};
    if (this.supported) {
      const el = new Audio();

      this._codecs = {
        mp3: !!el.canPlayType('audio/mp3;'),
        ogg: !!el.canPlayType('audio/ogg; codecs="vorbis"'),
        wav: (!!el.canPlayType('audio/wav; codecs="1"') || !!el.canPlayType('audio/wav; codec="1"')),
      };
    }
  }

  static hasCodec(ext) {
    return !!this._codecs[ext];
  }
}

MediaElementSupport._init();
