import passiveSupported from 'Components/passiveSupported';

export default function dropper({ parent, onDragInitialClick, onDragStart, onDragMove, onDrop, onDragCancel = null }) {
  const MIN_START_DISTANCE_PX = 3;

  let data;
  let startPosition;
  let isDragging = false;

  function onMouseDownInitial(e) {
    // ignore anything other than main button (MouseEvent.button === 0)
    if (e.button) return;
    if (isDragging) return;

    data = onDragInitialClick({
      el:  e.target,
      isTouch: e.type === 'touchstart',
    });
    // rag rejected by onDragInitial handler, do nothing
    if (!data) return;

    e.stopPropagation();

    const { clientX: x, clientY: y } = e.touches ? e.touches[0] : e;
    startPosition = { x, y };

    addInitialHandlers();
  }

  function addInitialHandlers() {
    window.addEventListener('mousemove', onMoveInitial);
    window.addEventListener('touchmove', onMoveInitial, passiveSupported ? { passive: false } : false);
    window.addEventListener('mouseup', onCancelInitial);
    window.addEventListener('touchend', onCancelInitial);
  }

  function removeInitialHandlers() {
    window.removeEventListener('mousemove', onMoveInitial);
    window.removeEventListener('touchmove', onMoveInitial);
    window.removeEventListener('mouseup', onCancelInitial);
    window.removeEventListener('touchend', onCancelInitial);
  }

  function onCancelInitial() {
    removeInitialHandlers();
    startPosition = undefined;

    if (onDragCancel)
      onDragCancel({
        data,
      });
  }

  function onMoveInitial(e) {
    if (e.type === 'touchmove') e.preventDefault();

    const { clientX: x, clientY: y } = e.touches ? e.touches[0] : e;
    if (Math.abs(x - startPosition.x) >= MIN_START_DISTANCE_PX ||
        Math.abs(y - startPosition.y) >= MIN_START_DISTANCE_PX
    ) {
      removeInitialHandlers();

      isDragging = true;

      window.addEventListener('mousemove', onMove);
      window.addEventListener('touchmove', onMove, passiveSupported ? { passive: false } : false);
      window.addEventListener('mouseup', onMouseUp);
      window.addEventListener('touchend', onMouseUp);

      onDragStart({
        data,
      });
    }
  }

  function onMove(e) {
    if (e.type === 'touchmove') e.preventDefault();

    const { clientX: x, clientY: y } = e.touches ? e.touches[0] : e;

    onDragMove({
      data,
      position: { x, y },
    });
  }

  function onMouseUp(e) {
    window.removeEventListener('mousemove', onMove);
    window.removeEventListener('touchmove', onMove);
    window.removeEventListener('mouseup', onMouseUp);
    window.removeEventListener('touchend', onMouseUp);

    const { clientX: x, clientY: y } = e.changedTouches ? e.changedTouches[0] : e;
    const curData = data;

    // reset state variables
    data = undefined;
    startPosition = undefined;
    isDragging = false;

    onDrop({
      data: curData,
      position: { x, y },
    });
  }

  parent.addEventListener('mousedown', onMouseDownInitial);
  parent.addEventListener('touchstart', onMouseDownInitial);
}
