import parseQueryString from './parseQueryString';

var urlFull = document.location.href,
    hPos = urlFull.indexOf('#'),
    url = hPos != -1 ? urlFull.substring(0, hPos) : urlFull,
    qPos = url.indexOf('?');

var Location = {
  baseURL : url.substring(0, url.lastIndexOf('/') + 1),
  query : {},
  hash: null,
  get hashParams() {
    const { hash } = document.location;
    if (hash) {
      return parseQueryString(hash.substr(1));
    }

    return {};
  },
};

if (document.location.hash) {
  Location.hash = document.location.hash;
}

if (qPos != -1) {
  Location.query = parseQueryString(url.substring(qPos + 1));
}

export default Location;
