import { ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';

import Alert from 'Components/Alert';
import Modal from 'Components/Modal';
import { hide, show } from 'Components/domHelpers';
import { RefFormData, FormText, wrapSubmitHandler } from 'Components/FormComponents';

import appErrorHandler from './appErrorHandler';
import ModalAlert from './ModalAlert';

import s from './strings';
import errors from './errors';

export default class CommentDialog {
  constructor(type, success) {
    this._form = new RefFormData();
    this._modal = new Modal({
      appendToBody: true,
      title: s.lblEditReference,
      children: (
        <form onsubmit={wrapSubmitHandler(() => this._send())}>
          <div class="modal-body">
            <Alert ref={this._alert} />
            <FormText form={this._form} inline name="comment" label={s.lblConfCommentNote} />
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{s.lblSave}</button>
            <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblCancel}</button>
          </div>
        </form>
      ),
    });

    this._type = type;

    this.successCallback = success || null;
  }

  fetchByID(cdrID) {
    this.cdrID = cdrID;

    const params = {
      cdrID,
    };

    Api.get('CDR', 'getComment', params)
      .then(result => this.open(result.comment))
      .catch(err => {
        ModalAlert.display(appErrorHandler(err));
      });
  }

  open(comment) {
    hide(this._alert);
    this._form.set('comment', comment || '');

    this._modal.show();
  }

  _send() {
    const comment = this._form.get('comment');

    let requestGroup;
    let requestName;
    let params;

    if (this._type == 'active') {
      requestGroup = 'LCM';
      requestName = 'changeConference';

      params = {
        command: 'setPermDataField',
        value: '',
        params: comment,
      };
    } else {
      requestGroup = 'CDR';
      requestName = 'setComment';

      params = {
        cdrID: this.cdrID,
        comment,
      };
    }

    Api.get(requestGroup, requestName, params)
      .then(() => {
        this._modal.hide();

        if (this.successCallback)
          this.successCallback();
      })
      .catch(err => {
        let errorCode;
        if (err instanceof ApiErrorResult) {
          const { code, parameterName } = err;

          if (code === 'ERR_API_REQUEST_PARAMETER_INVALID_LENGTH' && parameterName === 'comment') {
            errorCode = 'ERR_INVALID_COMMENT_LENGTH';
          }
        }
        if (!errorCode) {
          errorCode = appErrorHandler(err);
        }
        this._displayError(errorCode);
      });
  }

  _displayError(errorCode) {
    this._alert.textContent = errors[errorCode];
    show(this._alert);
  }
}
