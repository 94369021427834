import strings from './strings';

const MEDIA_ERRORS = {
  NotAllowedError: 'ERR_WEBCALL_DEVICE_ACCESS_DENIED',
  PermissionDeniedError: 'ERR_WEBCALL_DEVICE_ACCESS_DENIED', // thrown by Edge

  OverconstrainedError: 'ERR_WEBCALL_DEVICE_OVERCONSTRAINED',
  NotFoundError: 'ERR_WEBCALL_DEVICE_OVERCONSTRAINED', // thrown by Edge
};

const SIP_STATUS_CODES = {
  486: 'ERR_CONFERENCE_CAPACITY',
  406: 'ERR_CONFERENCE_LOCKED',
  404: 'ERR_INVALID_CONFERENCE_ID',
  430: 'ERR_INVALID_PIN',
  431: 'ERR_CONFERENCE_DISABLED',
  432: 'ERR_CONFERENCE_BLOCKED',
  433: 'ERR_CONFERENCE_TOLLFREE_DISABLED',
  434: 'ERR_INVALID_USERID',
  480: 'ERR_CONFERENCE_VALIDATION'
};

class WebCallError extends Error {
  constructor(code, cause) {
    super(strings.Errors[code] || code);

    this.name = 'WebCallError';
    this.code = code;
    if (cause)
      this.cause = cause;
  }
}

class ConnectionError extends WebCallError {
  constructor(code, cause = null, sipStatusCode = null) {
    if (sipStatusCode && SIP_STATUS_CODES[sipStatusCode]) {
      code = SIP_STATUS_CODES[sipStatusCode];
    }
    super(code, cause);
    this.name = 'ConnectionError';
    this.sipStatusCode = sipStatusCode;
  }
}

class MediaError extends WebCallError {
  constructor(code, cause = null) {
    if (cause) {
      let causeCode;
      if (MEDIA_ERRORS[cause.name]) {
        causeCode = MEDIA_ERRORS[cause.name];
      } else if (cause.constructor && cause.constructor.name && MEDIA_ERRORS[cause.constructor.name]) {
        causeCode = MEDIA_ERRORS[cause.constructor.name];
      }

      if (causeCode) {
        code = causeCode;
      }
    }
    super(code, cause);
    this.name = 'MediaError';
  }
}

class CancelledError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CancelledError';
    this.cancelled = true;
  }
}

export default {
  ConnectionError,
  MediaError,
  CancelledError,
};
