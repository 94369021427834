import axios from 'axios';

import ClientStorage from 'Browser/ClientStorage';
import logger from 'Log/logger';

const CLIENT_ID_STORAGE_KEY = 'clientID';
const CLIENT_ID_LEN = 6;
const CLIENT_ID_ALPHABET = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export default class ApiContext {
  constructor(name, config = {}) {
    this.name = name;
    this.log = logger(`Api:ApiContext:${name}`);

    if (config.API_URL) {
      this.setBaseConfig(config.API_URL, config.API_TIMEOUT);
    }

    this._setupCancelTokens();

    this._setDefaultScope();
  }

  setBaseConfig(baseURL, timeout) {
    this._baseURL = baseURL;

    this.axiosInstance = axios.create({
      baseURL,
      timeout,
    });
  }

  abort(cancelID) {
    this.log(`abort(${cancelID})`);

    if (cancelID in this._namedCancelTokens) {
      this._namedCancelTokens[cancelID].cancel('abort');
    }
  }

  abortAll() {
    this.log('abortAll()');

    this._mainCancelToken.cancel('abortAll');
    Object.keys(this._namedCancelTokens).forEach(_ => {
      this._namedCancelTokens[_].cancel('abortAll');
    });

    this._setupCancelTokens();
  }

  getCancelToken(cancelID) {
    if (!cancelID) {
      return this._mainCancelToken.token;
    }

    const existingToken = this._namedCancelTokens[cancelID];
    if (existingToken) {
      // there is a request with this ID in progress. cancel it
      this.log(`getCancelToken(${cancelID}) | cancelling request`);
      existingToken.cancel('requestExists');
    }

    // store and return new token
    let token = axios.CancelToken.source();
    this._namedCancelTokens[cancelID] = token;

    return token.token;
  }

  clearCancelToken(cancelID) {
    delete this._namedCancelTokens[cancelID];
  }

  _setupCancelTokens() {
    this._mainCancelToken = axios.CancelToken.source();
    this._namedCancelTokens = {};
  }

  setAuthParams(params) {
    this._authParams = params;
  }

  getAuthParams() {
    return this._authParams;
  }

  getClientData() {
    let clientID = ClientStorage.sessionStorage.read(CLIENT_ID_STORAGE_KEY);
    if (!clientID) {
      clientID = '';
      for (let i = 0; i < CLIENT_ID_LEN; i++) {
        clientID += CLIENT_ID_ALPHABET[getRandomInt(0, CLIENT_ID_ALPHABET.length)];
      }
      ClientStorage.sessionStorage.write(CLIENT_ID_STORAGE_KEY, clientID);
    }
    return {
      clientData: JSON.stringify({
        clientID,
        clientTS: Date.now(),
      }),
    };
  }

  getScope() {
    return this._curScope;
  }

  setScope(scopeStr) {
    this._curScope = this._parseScope(scopeStr);
  }

  onSend() {
  }

  onResponse(response) {
  }

  onEnvelopeError(err) {
  }

  _setDefaultScope() {
    this._curScope = {
      write                  : true,
      showCallerID           : true,
      changeBridgeAccess     : true,
      changeNotificationList : true,
      changePin              : true,
      webCall                : true,
      showOperatorCalls      : true,
      raisedHandMove         : true,
    };
  }

  _parseScope(scopeStr) {
    if (scopeStr == '') {
      return {};
    }

    return scopeStr.split(' ').reduce(function(acc, cur) {
      acc[cur] = true;
      return acc;
    }, {});
  }

  get baseURL() {
    return this._baseURL;
  }
}
