import Api from 'Api/Api';
import ApiContextAuthNone from 'Api/ApiContextAuthNone';
import { getBuffers } from 'Log/logger';
import EventEmitter from 'events';

import cloneError from './cloneError';

const CONTEXT_NAME = 'report';

const context = new ApiContextAuthNone(CONTEXT_NAME);
Api.addContext(context);

let configured = false;
let maxErrors = 15;
let appName = null;

function tryGetScriptContents(el) {
  try {
    return el.innerHTML && el.innerHTML.substr(0, 50);
  } catch (e) {
    return 'tryGetScriptContents err: ' + e.toString();
  }
}

function getNavigatorData() {
  const ret = {};

  for (let name in navigator) {
    const type = typeof navigator[name];
    if (type != 'object' && type != 'function')
      ret[name] = navigator[name];
  }

  ret.plugins = 'unknown';

  if (navigator.plugins && navigator.plugins.length) {
    ret.plugins = [];
    // for loop is used here because navigator.plugins is not a real
    // JS Array
    for (let i = 0; i < navigator.plugins.length; i++) {
      const cur = navigator.plugins[i];

      ret.plugins.push({
        name: cur.name || null,
        description: cur.description || null
      });
    }
  }

  return ret;
}

function send(errorString, errorData, jsErrorObject) {
  if (!configured) {
    return;
  }

  const reportObject = {
    timestamp: Date.now(),
    location: {},
    errorData: errorData || null,
    jsErrorObject: null,
    appData: null,
    logs: null,
    navigator: {},
    scripts: [],
    additionalErrors: []
  };

  if (!maxErrors) {
    return;
  }

  maxErrors--;

  if (appName) {
    errorString = appName + ' - ' + errorString;
  }

  // clone jsErrorObject

  if (jsErrorObject) {
    try {
      reportObject.jsErrorObject = cloneError(jsErrorObject);
    } catch (e) {
      reportObject.additionalErrors.push({
        errorType: 'cloneError() error',
        message: e.toString()
      });
    }
  }

  // copy location fields

  [ 'href', 'hash', 'protocol', 'port', 'origin' ].forEach(field => {
    if (document.location[field] !== undefined)
      reportObject.location[field] = document.location[field];
  });

  // emit event/collect appData

  try {
    report.emit('preSend', {
      reportObject
    });
  } catch (e) {
    reportObject.additionalErrors.push({
      errorType: 'preSend event error',
      message: e.toString()
    });
  }

  // collect browser data

  try {
    reportObject.navigator = getNavigatorData();
  } catch (e) {
    reportObject.additionalErrors.push({
      errorType: 'Error collecting browser data',
      message: e.toString()
    });
  }

  // collect <script>s

  try {
    const scriptEls = document.getElementsByTagName('script');
    for (let el of scriptEls) {
      reportObject.scripts.push({
        parentNodeType: el.parentNode.tagName,
        baseURI: el.baseURI,
        src: el.src,
        innerHTML: tryGetScriptContents(el)
      });
    }
  } catch (e) {
    reportObject.additionalErrors.push({
      errorType: 'Error collecting loaded <script> info',
      message: e.toString()
    });
  }

  try {
    reportObject.logs = getBuffers();
  } catch (e) {
    reportObject.additionalErrors.push({
      errorType: 'Error collecting log buffers',
      message: e.toString()
    });
  }

  // serialize reportObject

  let errorJSON;
  try {
    errorJSON = JSON.stringify(reportObject);
  } catch (e) {
    errorJSON = 'Error with JSON.stringify: ' + e.toString();
  }

  // send request

  Api.get('ErrorReport', 'clientErrorReport', { errorString, errorJSON }, {}, CONTEXT_NAME)
    .catch(err => {});// swallow all errors
}

function setAppName(name) {
  appName = name;
}

function configure(url, timeout) {
  context.setBaseConfig(url, timeout);

  configured = true;
}

const report = {
  send,
  setAppName,
  configure,
};

Object.assign(report, EventEmitter.prototype);

export default report;
