import { PagingController } from './PagingController';

const getPagingParams = subpage => subpage.getStateValues([
  'resultCount',
  'page',
]);

export function initPaging(subpage) {
  const ctrl = new PagingController({
    onPageChange: page => {
      subpage.redispatch({
        page
      });
    },

    onResultCountChange: resultCount => {
      subpage.redispatch({
        resultCount,
        page: 1,
      });
    },
  });

  subpage._metadata.params.page = {
    defaultVal: 1,
    validateFunc: val => {
      val = subpage.validateUInt(val);
      if (val === undefined) {
        return;
      }

      if (!(val > 0)) {
        return;
      }

      return val;
    },
  };

  subpage._metadata.params.resultCount = {
    defaultVal: ctrl.resultCountDefault,
    validateFunc: subpage.validateEnumUIntFactory(ctrl.resultCountOpts),
  };

  subpage.addDerivedParam('startOffset', () => ctrl.startOffset);

  subpage.addPreActivateFunc(() => {
    ctrl.update(getPagingParams(subpage));
  });

  return ctrl;
}

export function updatePaging(subpage, totalResults) {
  subpage.pagingController.update({
    ...getPagingParams(subpage),
    totalResults,
  });

  if (!subpage.pagingController.isPageInBounds) {
    subpage._params.page = subpage.pagingController.totalPages;
    setTimeout(() => subpage.redispatch(), 0);
    return false;
  }

  return true;
}
