import Alert from 'Components/Alert';
import HookedAlert from 'Components/HookedAlert';
import { RefFormData, FormGroup, FormText, FormPassword, FormCheckbox, Cond, wrapSubmitHandler } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';

import {
  LOGIN_LOGGED_OUT,
  LOGIN_ENTER_NAME,
  LOGIN_FORGOT_PASSWORD,
} from './LoginController';

import s from './strings';
import errors from './errors';

// TODO: use LoadingIndicator instead of disabling submit buttons

export default class LoginControl {
  static isClassComponent = true;

  constructor({ ctrl }) {
    const TITLE_MAP = new Map([
      [ LOGIN_LOGGED_OUT,      s.lblLogin ],
      [ LOGIN_ENTER_NAME,      s.lblLogin ],
      [ LOGIN_FORGOT_PASSWORD, s.LoginControl.forgotPasswordTitle ],
    ]);

    this.ctrl = ctrl;
    this.ctrl.on('update', () => this.render());

    const { useAccountAuth } = ctrl;

    this._form = new RefFormData();
    this._enterNameForm = new RefFormData();
    this._forgotPasswordForm = new RefFormData();

    const hooks = this.hooks = new Hooks();

    this.root = (
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title" use:hook={hooks.textContent('state', val => TITLE_MAP.get(val))} />
        </div>
        <div class="panel-body">
          <HookedAlert hooks={hooks} showProp="errorCode" textContentProp="errorCode" textContentTransform={val => errors[val]} />
          <form
            class="form-horizontal"
            use:hook={hooks.show('state', val => val === LOGIN_LOGGED_OUT)}
            onsubmit={wrapSubmitHandler(() => ctrl.login(this._form.getAllValues()))}
          >
            <FormText form={this._form} type={useAccountAuth ? 'text' : 'tel'} name="username" label={useAccountAuth ? s.lblEmail : s.lblConferenceID} inputAttributes={{ autocomplete: 'username' }} />
            <FormPassword form={this._form} name="password" label={useAccountAuth ? s.lblPassword : s.lblHostPIN} inputAttributes={{ autocomplete: 'current-password' }} />
            <FormText form={this._form} name="name" label={s.lblName} inputAttributes={{ autocomplete: 'name' }} />
            <FormCheckbox form={this._form} name="saveLoginInfo" labelRight={s.lblSaveMyLoginInfo} />

            <FormGroup>
              <div class="btn-toolbar-discrete">
                <button type="submit" class="btn btn-primary" use:hook={hooks.prop('loading', 'disabled')}>{s.lblLogin}</button>
                <Cond test={useAccountAuth}>
                  <button type="button" class="btn btn-link ml-2" onclick={() => ctrl.toggleForgotPassword()}>{s.LoginControl.forgotPassword}</button>
                </Cond>
              </div>
            </FormGroup>
          </form>
          <form
            class="form-horizontal"
            use:hook={hooks.show('state', val => val === LOGIN_ENTER_NAME)}
            onsubmit={wrapSubmitHandler(() => ctrl.submitEnterName(this._enterNameForm.getAllValues()))}
          >
            <FormText form={this._enterNameForm} name="name" label={s.lblName} inputAttributes={{ autocomplete: 'name' }} />

            <FormGroup>
              <div class="btn-toolbar-discrete">
                <button type="submit" class="btn btn-primary">{s.LoginControl.submitEnterName}</button>
                <button type="button" class="btn btn-link" onclick={() => ctrl.logout()}>{s.lblLogout}</button>
              </div>
            </FormGroup>
          </form>
          <form
            class="form-horizontal"
            use:hook={hooks.show('state', val => val === LOGIN_FORGOT_PASSWORD)}
            onsubmit={wrapSubmitHandler(() => ctrl.submitForgotPassword(this._forgotPasswordForm.getAllValues()))}
          >
            <div use:hook={hooks.hide('forgotPasswordSuccess')}>
              <FormText form={this._forgotPasswordForm} name="username" label={s.lblEmail} inputAttributes={{ autocomplete: 'username' }} />

              <FormGroup>
                <div class="btn-toolbar-discrete">
                  <button type="submit" class="btn btn-primary" use:hook={hooks.prop('loading', 'disabled')}>{s.LoginControl.forgotPasswordSend}</button>
                  <button type="button" class="btn btn-primary" onclick={() => ctrl.toggleForgotPassword()}>{s.LoginControl.forgotPasswordCancel}</button>
                </div>
              </FormGroup>
            </div>
            <div use:hook={hooks.show('forgotPasswordSuccess')}>
              <Alert type="success">{s.LoginControl.forgotPasswordSuccess}</Alert>
              <div class="btn-toolbar-discrete">
                <button type="button" class="btn btn-primary ml-auto" onclick={() => ctrl.toggleForgotPassword()}>{s.lblOK}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  render() {
    const { loginState } = this.ctrl;

    this.hooks.run(loginState);
    this._form.setVisibility('name', loginState.showName);
    this._form.setVisibility('saveLoginInfo', loginState.useStoredFields);
    this._form.setValues(loginState.data);
    this._enterNameForm.setValues(loginState.enterNameData);
    this._forgotPasswordForm.setValues(loginState.forgotPasswordData);
  }
}
