import MediaElementController from 'Components/MediaElementController';

import { emptyNode, toggleClass, toggle, show, hide } from 'Components/domHelpers';

import appErrorHandler from './appErrorHandler';
import ModalAlert from './ModalAlert';

import s from './strings';

export default class AudioSelect {
  constructor({ select, items = [], showAlways = false } = {}) {
    this._refs = {
      select,
    };

    this.root = (
      <div class="audio-control d-flex justify-content-between align-items-center mt-2">
        <div ref={this._refs.supported}>
          {s.lblPreview}
          <button type="button" class="btn btn-primary btn-play ml-3" ref={this._refs.play}>
            <span class="sr-only">{s.lblPlay}</span>
          </button>
        </div>

        <div ref={this._refs.noaudio}>
          {s.lblNoPreviewAvailable}
        </div>
      </div>
    );

    this._showAlways = showAlways;

    const { play } = this._refs;

    select.after(this.root);

    select.onchange = () => this._onchange();
    play.onclick = () => this._onclick();

    this.setItems(items || []);
    this.update();
  }

  setItems(items) {
    this.items = items;

    const { select } = this._refs;

    emptyNode(select);

    this._showSelf = this._showAlways;

    this.items.forEach(item => {
      const opt = new Option(item.label, item.value);
      select.appendChild(opt);

      if (!('uri' in item)) {
        return;
      }

      this._showSelf = true;
    });

    toggle(this.root, this._showSelf);
  }

  getSelected() {
    const value = this._refs.select.value;
    return this.items.find(item => item.value == value);
  }

  update() {
    if (!this._showSelf) {
      return;
    }

    const selected = this.getSelected();
    const hasAudio = selected && selected.uri;

    toggleClass(this._refs.play, 'playing', this._playing);

    hide(this._refs.noaudio);
    hide(this._refs.supported);

    if (!hasAudio) {
      show(this._refs.noaudio);
    } else {
      show(this._refs.supported);
    }

    return this;
  }

  _onchange() {
    if (this._playing) {
      MediaElementController
        .stop()
        .catch(() => {
          // swallow errors
        });
    }

    this.update();
  }

  _onclick() {
    const stopOnly = this._playing;

    const selected = this.getSelected();
    let uri = selected && selected.uri;
    if (typeof uri === 'function')
      uri = uri();

    MediaElementController
      .stop()
      .then(() => {
        if (!stopOnly && uri) {
          this._playing = true;
          this.update();
          return MediaElementController.play(uri, s.lblRecording);
        }
      })
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        ModalAlert.display(appErrorHandler(err));
      })
      .then(() => {
        this._playing = false;
        this.update();
      });
  }
}
