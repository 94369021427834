import { KEYCODE_ESCAPE } from 'Browser/KeyCodes';
import { focusFirstDescendant } from 'Components/FocusUtils';

import { createPopper } from '@popperjs/core/dist/esm/popper-lite';
import flip from '@popperjs/core/dist/esm/modifiers/flip';

const POPUP_HIDE_TIMEOUT = 250;

export class Popup {
  static isClassComponent = true;

  constructor({ anchor, children, extraClass = null, placement = 'bottom-start', onShow = null, onHide = null, onBeforePosition = null, autoHide = false, withBackground = true, focusFirst = true, ref }) {
    if (ref) ref(this);

    this._anchor = anchor;
    this._placement = placement;
    this._onShow = onShow;
    this._onHide = onHide;
    this._onBeforePosition = onBeforePosition;

    this._onKeydownListener = this._onKeydown.bind(this);
    this._onBodyClickListener = this._onBodyClick.bind(this);

    this._autoHide = autoHide;
    this._autoHideTimeout = null;
    this._onMouseEnterListener = this._onMouseEnter.bind(this);
    this._onMouseLeaveListener = this._onMouseLeave.bind(this);

    this._focusFirst = focusFirst;

    this.root = (
      <div
        class="popup"
        class:popup-bg={withBackground}
      >
        {children}
      </div>
    );

    if (extraClass)
      this.root.classList.add(extraClass);
  }

  _addRemoveListeners(add = true) {
    const addRemove = (el, type, listener) => {
      el[add ? 'addEventListener' : 'removeEventListener'](type, listener);
    };

    if (this._autoHide) {
      addRemove(this._anchor, 'mouseenter', this._onMouseEnterListener);
      addRemove(this.root, 'mouseenter', this._onMouseEnterListener);
      addRemove(this._anchor, 'mouseleave', this._onMouseLeaveListener);
      addRemove(this.root, 'mouseleave', this._onMouseLeaveListener);
    }

    addRemove(document.body, 'click', this._onBodyClickListener);
    addRemove(this.root, 'keydown', this._onKeydownListener);
  }

  _onMouseEnter(e) {
    this._clearAutoHideTimer();
  }

  _onMouseLeave(e) {
    this._clearAutoHideTimer();

    this._autoHideTimeout = setTimeout(() => {
      this._autoHideTimeout = null;
      this.hide();
    }, POPUP_HIDE_TIMEOUT);
  }

  _clearAutoHideTimer() {
    if (this._autoHideTimeout)
      clearTimeout(this._autoHideTimeout);
    this._autoHideTimeout = null;
  }

  _onBodyClick(e) {
    const anchorParentClicked = this._anchor.parentElement.contains(e.target);
    const popupClicked = this.root.contains(e.target);

    if (anchorParentClicked) return;
    if (popupClicked) return;

    this.hide();
  }

  _onKeydown(e) {
    switch (e.keyCode) {
    case KEYCODE_ESCAPE:
      this.hide();
      break;

    default:
      break;
    }
  }

  show() {
    if (this.isOpen)
      return;

    this.isOpen = true;

    this._addRemoveListeners();

    this.root.setAttribute('data-popup-show', '');

    if (this._onBeforePosition)
      this._onBeforePosition();

    this._popperInstance = createPopper(this._anchor, this.root, {
      placement: this._placement,
      strategy: 'fixed',
      modifiers: [
        { ...flip, }
      ],
    });

    if (this._focusFirst) focusFirstDescendant(this.root);

    if (this._onShow)
      this._onShow();
  }

  hide() {
    if (!this.isOpen)
      return;

    this.isOpen = false;

    this._addRemoveListeners(false);
    this.root.removeAttribute('data-popup-show');
    this._destroyPopper();

    if (this._onHide)
      this._onHide();
  }

  toggle() {
    if (this.isOpen) {
      this.hide();
    } else {
      this.show();
    }
  }

  _destroyPopper() {
    if (this._popperInstance) {
      this._popperInstance.destroy();
      this._popperInstance = null;
    }
  }
}
