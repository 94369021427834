import ApiContext from './ApiContext';

export default class ApiContextAuthRecording extends ApiContext {
  constructor(name, config) {
    super(name, config);
  }

  getAuthParams(requestFormat = 'json') {
    const {
      accountID,
      cdrID,
      signature,
      recID,
      passcode,
    } = this._authParams;

    switch (requestFormat) {
    case 'json':
      return {
        authRecording: {
          accountID,
          cdrID,
          signature,
          ...(recID !== undefined && { recID }),
          ...(passcode !== undefined && { passcode }),
        }
      };

    case 'get':
      return {
        authRecordingAccountID: accountID,
        authRecordingCdrID: cdrID,
        authRecordingSignature: signature,
        ...(recID !== undefined && { authRecordingRecID: recID }),
        ...(passcode !== undefined && { authRecordingPasscode: passcode }),
      };
    }
  }
}
