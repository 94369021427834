import JsSIP from './JsSIPWrapper';
import RTCSession from 'jssip/RTCSession';
import { InviteServerTransaction } from 'jssip/Transactions';
import EventEmitter from 'events';

import ClientStorage from 'Browser/ClientStorage';

import Exceptions from './Exceptions';

import logger from 'Log/logger';

import StatsMonitor from './StatsMonitor';

import sdp_transform from 'sdp-transform';

const SESSION_STATUS = RTCSession.C;

const STATE = {
  DISCONNECTED: 0,
  CONNECTING:   1,
  TRYING:       2,
  RINGING:      3,
  CONNECTED:    4,
};

const STATE_NAMES = {
  [STATE.DISCONNECTED]: 'DISCONNECTED',
  [STATE.CONNECTING]:   'CONNECTING',
  [STATE.TRYING]:       'TRYING',
  [STATE.RINGING]:      'RINGING',
  [STATE.CONNECTED]:    'CONNECTED'
};

const C = {
  RENEGOTIATE_TIMEOUT: 1500,
  ICE_DISCONNECTED_TIMEOUT: 5000,
};

const statsHeaderMap = {
  PS: {
    total: true,
    field: 'packetsSent',
  },
  OS: {
    total: true,
    field: 'bytesSent'
  },
  OR: {
    total: true,
    field: 'bytesReceived'
  },
  PL: {
    total: true,
    field: 'packetsLost'
  },
  JI: {
    total: true,
    field: 'maxJitter'
  },
  PR: {
    total: true,
    field: 'packetsReceived'
  },
  LA: {
    total: true,
    field: 'maxLatency'
  },
  EN: {
    field: 'encodeCodec'
  },
  DE: {
    field: 'decodeCodec'
  }
};

const DISCONNECT_REASONS = {
  normal: {
    status_code: 200,
    reason_phrase: 'User Triggered',
  },
  mediaError: {
    status_code: 400,
    reason_phrase: 'Get User Media Failure',
  },
  iceError: {
    status_code: 408,
    reason_phrase: 'ICE Error',
    connectionErrorCode: 'ERR_WEBCALL_RTP_CONNECTION',
  },
  renegotiationError: {
    status_code: 500,
    reason_phrase: 'Media Renegotiation Failed',
  },
};

export default class Client extends EventEmitter {
  constructor(instanceName) {
    super();

    // init member vars
    this.ua                    = null;
    this._session              = null;

    this._peerConnection       = null;
    this._peerConnectionQueue  = Promise.resolve();
    this._rtcReady             = true;
    this._iceState             = 'new';
    this._iceDisconnectedTimer = null;
    this._mediaStream          = null;

    this._setupStatsMonitor();
    this._lastStatsSample = null;

    this._state                = null;

    this._connectParams        = null;

    this.log = logger(`WebCall:${instanceName}:Client`);

    this._sessionEventHandlers = {
      remotemedia: e => this._onSIPRemoteMedia(e),
      failed: e => this._onSIPFailed(e),
      progress: e => this._onSIPProgress(e),
      byeReceived: e => this._onSIPByeReceived(e),
      ended: e => this._onSIPEnded(e),
      ackReceived: e => this._onSIPAckReceived(e),
    };

    // browser feature detection

    this._hasTransceivers = JsSIP.hasTransceivers();

    if (this._hasTransceivers) {
      this.log('_replaceStream - using replaceTrack strategy');
      this._replaceStream = this._replaceStream_replaceTrack;
    } else {
      this.log('_replaceStream - using remove/addTrack strategy');
      this._replaceStream = this._replaceStream_removeAddTrack;
    }
  }

  getState() {
    return this._state;
  }

  isConnected() {
    return this._state === STATE.CONNECTED;
  }

  isConnecting() {
    return [
      STATE.CONNECTING,
      STATE.TRYING,
      STATE.RINGING,
    ].includes(this._state);
  }

  isDisconnected() {
    return this._state === null || this._state === STATE.DISCONNECTED;
  }

  connect() {
    this.log('connect()', this._connectParams);

    return Promise.resolve()
      .then(() => {
        if (!this._connectParams) {
          throw new Exceptions.ConnectionError('ERR_WEBCALL_CLIENT_UNCONFIGURED');
        }
      })
      .then(() => {
        this._setState(STATE.CONNECTING);

        return Promise.resolve()
          .then(() => this._connectWS())
          .catch(err => {
            this._disconnected();
            throw new Exceptions.ConnectionError('ERR_WEBCALL_WS_CONNECTION', err);
          });
      })
      .then(() => {
        return Promise.resolve()
          .then(() => this._call())
          .catch(err => {
            this._disconnected();
            throw new Exceptions.ConnectionError('ERR_WEBCALL_SIP_CONNECTION', err);
          });
      });
  }

  setConnectParams(connectParams) {
    this._connectParams = connectParams;
  }

  _setState(state, error = null) {
    const emit = state !== this._state,
        stateName = STATE_NAMES[state];

    this.log(`_setState(${stateName})`);

    this._state = state;

    if (emit) {
      this._emitEvent('stateChange', {
        state,
        stateName,
        error
      });
    }
  }

  get hasSenderTrack() {
    if (!this._peerConnection)
      return false;

    return !!this._peerConnection
      .getSenders()
      .find(sender => sender.track);
  }

  sendDTMF(digit) {
    this.log(`sendDTMF(${digit})`);

    this._session.sendDTMF(digit);
  }

  _connectWS() {
    return new Promise((resolve, reject) => {
      const connectParams = this._connectParams;

      const configuration = {
        uri: connectParams.fromSipURI,
        register: false
      };

      if (connectParams.fromName)
        configuration.display_name = connectParams.fromName;

      configuration.sockets = [ new JsSIP.WebSocketInterface(connectParams.wsSipURI) ];

      this.ua = new JsSIP.UA(configuration);

      this.ua
        .on('connected', e => {
          this.log('ua.connected');

          resolve();
        })
        .on('disconnected', e => {
          this.log('ua.disconnected', e && e.error);

          if (e && e.error) {
            this._disconnected(new Exceptions.ConnectionError('ERR_WEBCALL_WS_CONNECTION'));
            return;
          }

          this.ua.stop();
        })
        .on('newMessage', e => {
          this.log('ua.newMessage', e);

          e.parsedBody = null;

          if (e.originator == 'remote' && e.request && e.request.getHeader('content-type').toLowerCase() == 'application/json') {
            // attempt to parse response
            try {
              e.parsedBody = JSON.parse(e.request.body);
            } catch (error) {
              this.log('_newMessage() | error parsing response JSON', error);
            }
          }

          this._emitEvent('newMessage', e);
        })
        .on('newTransaction', e => {
          const transaction = e.transaction,
              request = transaction.request;

          if (request.method === 'INVITE' || request.method === 'BYE' ||
              (request.method === 'UPDATE' && request.method.body)) {
            this.log(`transaction started:\n\n${request.toString()}\n`);

            if (transaction.eventHandlers && transaction.eventHandlers.onReceiveResponse) {
              const origOnReceiveResponse = transaction.eventHandlers.onReceiveResponse;
              transaction.eventHandlers.onReceiveResponse = response => {
                this.log(`transaction onReceiveResponse:\n\n${response.toString()}\n`);
                origOnReceiveResponse(response);
              };
            }

            if (transaction instanceof InviteServerTransaction) {
              const origReceiveResponse = transaction.receiveResponse;
              transaction.receiveResponse = (status_code, response, onSuccess, onFailure) => {
                this.log(`transaction sendResponse:\n\n${response.toString()}\n`);
                origReceiveResponse.call(transaction, status_code, response, onSuccess, onFailure);
              };
            }
          }
        })
        .on('newAckClientTransaction', e => {
          const request = e.transaction.request;
          this.log(`transaction AckClientTransaction:\n\n${request.toString()}\n`);
        })
        .start();
    });
  }

  _call() {
    this.log('_call()');

    this._teardownPeerConnection();
    this._createPeerConnection();

    const options = {
      eventHandlers: this._sessionEventHandlers,
    };

    return this._createLocalDescription('offer')
      .then(desc => {
        this._session = this.ua.call(this._connectParams.toSipURI, desc, options);
      });
  }

  changeMediaStream(stream) {
    this.log(`changeMediaStream() | stream = ${!!stream}`);

    return Promise.resolve()
      .then(() => {
        this._mediaStream = stream;

        if (!this.isConnected())
          return;

        // don't do replaceStream/renegotiate if we are clearing the
        // stream and we aren't sending anything
        if (!stream && !this.hasSenderTrack)
          return;

        return this._replaceStream(stream)
          .then(() => {
            if (!this._hasTransceivers || ClientStorage.localStorage.read('webCall_renegotiate')) {
              return this.renegotiate();
            }
          });
      })
      .catch(err => {
        this.log('changeMediaStream() | error', err);

        if (this.isConnected()) {
          this.disconnect('renegotiationError', err);
        }

        throw err;
      });
  }

  _replaceStream_replaceTrack(stream) {
    this.log(`_replaceStream(${stream}) | hasSenderTrack = ${this.hasSenderTrack}`);

    const track = stream ? stream.getAudioTracks()[0] : null;

    return Promise.all(
      this._peerConnection.getSenders().map(sender => sender.replaceTrack(track))
    ).then(() => {
      this.log(`_replaceStream() | complete. hasSenderTrack = ${this.hasSenderTrack}`);
    });
  }

  _replaceStream_removeAddTrack(stream) {
    this.log(`_replaceStream(${stream}) | hasSenderTrack = ${this.hasSenderTrack}`);

    return Promise.all(
      // remove existing streams
      this._peerConnection.getSenders().map(sender => new Promise((resolve, reject) => {
        this.log('_replaceStream() | removing track from sender');

        this._peerConnection.removeTrack(sender);

        resolve();
      }))
    ).then(() => {
      if (stream) {
        this.log('_replaceStream() | adding track to RTCPeerConnection');
        this._peerConnection.addTrack(stream.getAudioTracks()[0], stream);
      }
      this.log(`_replaceStream() | complete. hasSenderTrack = ${this.hasSenderTrack}`);
    });
  }

  renegotiate() {
    this.log('renegotiate()');

    if (this._state !== STATE.CONNECTED) {
      throw new Error('not connected');
    }

    if (!this._rtcReady) {
      throw new Error('peer connection not ready');
    }

    if (!this._session.isReadyToReOffer()) {
      throw new Error('RTCSession not ready');
    }

    let timeout = null;
    const timeoutPromise = new Promise((resolve, reject) => {
      timeout = setTimeout(() => {
        reject(new Error('renegotiation timeout'));
      }, C.RENEGOTIATE_TIMEOUT);
    });

    return Promise.race([
      timeoutPromise,
      this._renegotiate(),
    ])
      .then(() => {
        clearTimeout(timeout);
      })
      .catch(err => {
        clearTimeout(timeout);

        throw err;
      });
  }

  _renegotiate() {
    this._peerConnectionQueue = this._peerConnectionQueue
      .then(() => this._createLocalDescription('offer'))
      .then(sdpOffer => {
        return new Promise((resolve, reject) => {
          this._session.sendReinvite({
            sdpOffer,
            eventHandlers: {
              succeeded: response => resolve(response.body),
              failed: () => {
                reject(new Error('sendReinvite() failed'));
              }
            },
          });
        });
      })
      .then(sdpAnswer => this._processRemoteDescription('answer', sdpAnswer))
      .then(({ desc }) => this._setRemoteDescription(desc));

    return this._peerConnectionQueue;
  }

  _createPeerConnection() {
    this.log('_createPeerConnection()');

    const pcConfig = this._connectParams.rtcConfiguration
      ? this._connectParams.rtcConfiguration
      : { iceServers: [] };
    const rtcConstraints = {
      optional: [ { googIPv6: false }]
    };

    const peerConnection = new RTCPeerConnection(pcConfig, rtcConstraints);

    peerConnection.ontrack = e => {
      this.log('pc.ontrack');

      this._emitEvent('track', e);
    };

    peerConnection.onicegatheringstatechange = () => {
      this.log('pc.icegatheringstatechange', peerConnection.iceGatheringState);
    };

    peerConnection.oniceconnectionstatechange = () => {
      this.log('pc.iceconnectionstatechange', peerConnection.iceConnectionState);

      this._onIceConnectionStateChange(peerConnection.iceConnectionState);
    };

    peerConnection.onconnectionstatechange = () => {
      this.log('pc.connectionstatechange', peerConnection.connectionState);

      this._onIceConnectionStateChange(peerConnection.connectionState);
    };

    peerConnection.onsignalingstatechange = () => {
      this.log(`pc.signalingstatechange: ${JSON.stringify(getPCSummary(peerConnection))}`);
    };

    if (this._mediaStream) {
      this._mediaStream.getTracks().forEach(track => {
        peerConnection.addTrack(track, this._mediaStream);
      });
    }

    this._statsMonitor.start(peerConnection);

    this._peerConnection = peerConnection;
  }

  _teardownPeerConnection() {
    this.log('_teardownPeerConnection()');

    if (this._peerConnection) {
      this._peerConnection.ontrack = null;
      this._peerConnection.onicegatheringstatechange = null;
      this._peerConnection.oniceconnectionstatechange = null;
      this._peerConnection.onconnectionstatechange = null;
      this._peerConnection.onsignalingstatechange = null;

      this.log('_teardownPeerConnection() | calling close()');
      try {
        this._peerConnection.close();
      } catch (err) {
        this.log('_teardownPeerConnection() | error closing the RTCPeerConnection', err);
      }
    }
    this._peerConnection = null;
    this._peerConnectionQueue = Promise.resolve();
    this._rtcReady = true;
    this._iceState = 'new';

    this._clearIceDisconnectedTimer();
  }

  _createLocalDescription(type) {
    this.log(`createLocalDescription() | type = ${type}`);

    const connection = this._peerConnection;

    this._rtcReady = false;

    return Promise.resolve()
      // Create Offer or Answer.
      .then(() => {
        const allowedStates = type === 'answer'
          ? [ 'have-remote-offer' ]
          : [ 'stable' ];

        this._checkSignalingState(allowedStates);

        if (type === 'offer') {
          let constraints = null;
          if (!this._mediaStream) {
            if (this._hasTransceivers) {
              if (!connection.getTransceivers().length) {
                this.log('createLocalDescription() | adding transceiver');

                connection.addTransceiver('audio');
              }
            } else {
              constraints = {
                offerToReceiveAudio: true,
              };
            }
          }

          return connection.createOffer(constraints);
        } else {
          if (this._hasTransceivers) {
            const transceiver = connection.getTransceivers()[0];
            if (transceiver.direction !== 'sendrecv') {
              this.log('createLocalDescription() | forcing transceiver direction to "sendrecv"');

              transceiver.direction = 'sendrecv';
            }
          }

          return connection.createAnswer();
        }
      })
      .then(desc => {
        if (type === 'offer') {
          const sdp = sdp_transform.parse(desc.sdp);
          this._offerMids = sdp.media.map(mediaSection => {
            return mediaSection.mid;
          });
        }

        return desc;
      })
      // Set local description.
      .then(desc => connection.setLocalDescription(desc))
      .then(() => {
        // Resolve right away if 'pc.iceGatheringState' is 'complete'.
        if (connection.iceGatheringState === 'complete') {
          this._rtcReady = true;

          return connection.localDescription.sdp;
        }

        // Add 'pc.onicencandidate' event handler to resolve on last candidate.
        return new Promise(resolve => {
          let listener;

          const ready = () => {
            connection.removeEventListener('icecandidate', listener);

            this._rtcReady = true;

            resolve(connection.localDescription.sdp);
          };

          connection.addEventListener('icecandidate', listener = event => {
            const candidate = event.candidate;

            if (!candidate) {
              ready();
            }
          });
        });
      })
      .catch(err => {
        this._rtcReady = true;
        this._emitEvent('error', err);
        err.errorType = 'local';
        throw err;
      });
  }

  _setRemoteDescription(desc) {
    this.log(`setRemoteDescription() | type = ${desc.type}`);

    const allowedStates = desc.type === 'answer'
      ? [ 'have-local-offer' ]
      : [ 'stable' ];

    this._checkSignalingState(allowedStates);

    return this._peerConnection.setRemoteDescription(desc)
      .catch(err => {
        this._emitEvent('error', err);
        err.errorType = 'remote';
        throw err;
      });
  }

  _processRemoteDescription(type, origSdp) {
    const sdpParsed = sdp_transform.parse(origSdp);

    this._offerMids.forEach((mid, idx) => {
      if (sdpParsed.media[idx] && sdpParsed.media[idx].mid === undefined) {
        this.log(`explicitly setting mid for media section ${idx}`);
        sdpParsed.media[idx].mid = mid;
      }
    });

    const sdp = sdp_transform.write(sdpParsed);

    return {
      desc: new RTCSessionDescription({ type, sdp }),
      fingerprint: getFingerprint(sdpParsed),
    };
  }

  _checkSignalingState(allowedStates) {
    if (this._state !== STATE.CONNECTING) {
      if (!this._session || this._session.status === SESSION_STATUS.STATUS_TERMINATED) {
        throw new Exceptions.CancelledError('session terminated');
      }
    }

    const { signalingState } = this._peerConnection;

    if (!allowedStates.includes(signalingState)) {
      this._emitEvent('error', new Error(`unexpected signalingState "${signalingState}"`));
    }

    if (signalingState === 'closed') {
      throw new Exceptions.CancelledError('peerConnection closed');
    }
  }

  _onSIPRemoteMedia(e) {
    this.log(`_onSIPRemoteMedia() | type = ${e.type}`);

    let mediaObj = null;
    if (e.sdp)
      mediaObj = this._processRemoteDescription(e.type, e.sdp);

    if (e.type === 'answer') {
      this._peerConnectionQueue = this._peerConnectionQueue
        .then(() => {
          if (this._peerConnection.signalingState === 'stable') {
            this.log('_onSIPRemoteMedia() | ignore additional sdp');
          } else {
            return this._setRemoteDescription(mediaObj.desc);
          }
        })
        .then(() => e.success())
        .catch(err => {
          this.log('_onSIPRemoteMedia() | error', err);

          if (err instanceof Exceptions.CancelledError) {
            return;
          }

          e.failure(err.errorType);
        });
    } else {
      let createNewPeerConnection = false;
      if (mediaObj) {
        const currentRemoteDescription = this._peerConnection.currentRemoteDescription || this._peerConnection.remoteDescription;
        const currentSdp = sdp_transform.parse(currentRemoteDescription.sdp);
        const currentFingerprint = getFingerprint(currentSdp);

        if (currentFingerprint !== mediaObj.fingerprint) {
          this.log('_onSIPRemoteMedia() | DTLS fingerprint changed');
          createNewPeerConnection = true;
        }
      } else {
        this.log('_onSIPRemoteMedia() | remote did not send sdp');
        createNewPeerConnection = true;
      }

      if (createNewPeerConnection) {
        this.log('_onSIPRemoteMedia() | creating new RTCPeerConnection');
        this._teardownPeerConnection();
        this._createPeerConnection();
      }

      this._peerConnectionQueue = this._peerConnectionQueue
        .then(() => {
          if (mediaObj) {
            return this._setRemoteDescription(mediaObj.desc);
          }
        })
        .then(() => this._createLocalDescription(mediaObj ? 'answer' : 'offer'))
        .then(desc => e.success(desc))
        .catch(err => {
          this.log('_onSIPRemoteMedia() | error', err);

          if (err instanceof Exceptions.CancelledError) {
            return;
          }

          e.failure(err.errorType);
        });
    }
  }

  _onSIPFailed(e) {
    this.log('_onSIPFailed()', e);

    let canceled = e.cause && e.cause === JsSIP.C.causes.CANCELED,
        error = null;

    if (!canceled) {
      const sipStatusCode = e.message && e.message.status_code || null;

      error = new Exceptions.ConnectionError('ERR_WEBCALL_SIP_CONNECTION', null, sipStatusCode);
    }

    this._disconnected(error);
  }

  _onSIPProgress(e) {
    if (e.originator === 'remote') {
      switch (e.response.status_code) {
      case 100:
        this._setState(STATE.TRYING);
        break;

      case 180:
        this._setState(STATE.RINGING);
        break;
      }
    }
  }

  _onSIPByeReceived(e) {
    this.log('_onSIPByeReceived()', e);

    var statsHeader = this._getStatsHeader();

    if (statsHeader) {
      e.responseExtraHeaders.push(statsHeader);
    }
  }

  _onSIPEnded(e) {
    this.log('_onSIPEnded()', e);

    let error = null;

    if (e.cause instanceof Error) {
      error = e.cause;
    } else {
      let errorCode = null;

      switch (e.cause) {
      case JsSIP.C.causes.BYE:
      case JsSIP.C.causes.CANCELED:
        // these should not emit an error
        break;

      default:
        errorCode = 'ERR_WEBCALL_SIP_CONNECTION';
        break;
      }

      if (errorCode) {
        error = new Exceptions.ConnectionError(errorCode);
      }
    }

    this._disconnected(error);
  }

  _onSIPAckReceived(e) {
    this.log(`ackReceived:\n\n${e.request.toString()}\n`);
  }

  _onIceConnectionStateChange(newState) {
    const prevState = this._iceState;

    if (newState === prevState)
      return;

    // do nothing for states we don't care about (new, checking, completed, closed)
    if (!(newState === 'connected' || newState === 'disconnected' || newState === 'failed'))
      return;

    this._iceState = newState;

    switch (this._iceState) {
    case 'connected':
      if (prevState === 'disconnected' || prevState === 'failed') {
        this.log('ICE connection recovered');
        this._clearIceDisconnectedTimer();
      } else {
        this.log('ICE initial connection');
        this._setState(STATE.CONNECTED);
      }

      break;

    case 'disconnected':
      this.log('ICE disconnected');
      this._startIceDisconnectedTimer();

      break;

    case 'failed':
      this.log('ICE failed');
      this._onIceFailed();

      break;
    }
  }

  _startIceDisconnectedTimer() {
    this._clearIceDisconnectedTimer();

    this._iceDisconnectedTimer = setTimeout(() => {
      this.log('iceDisconnectedTimer fired');
      this._onIceFailed();
    }, C.ICE_DISCONNECTED_TIMEOUT);
  }

  _clearIceDisconnectedTimer() {
    if (this._iceDisconnectedTimer) {
      clearTimeout(this._iceDisconnectedTimer);
    }

    this._iceDisconnectedTimer = null;
  }

  _onIceFailed() {
    this.log('_onIceFailed()');

    this.disconnect('iceError');
  }

  _getStatsHeader() {
    if (!this._lastStatsSample) {
      return;
    }

    const sample = this._lastStatsSample;
    let ret = '';

    for (let key in statsHeaderMap) {
      const cur = statsHeaderMap[key];
      const val = cur.total ? sample.totals[cur.field] : sample[cur.field];

      if (val !== undefined) {
        if (ret) {
          ret += ', ';
        }

        ret += key + '=' + val;
      }
    }

    return 'P-RTP-Stat: ' + ret;
  }

  disconnect(reasonCode = 'normal', causeError = null) {
    this.log(`disconnect(${reasonCode})`);

    if (this._session) {
      const terminateOptions = {};

      const statsHeader = this._getStatsHeader();
      if (statsHeader) {
        terminateOptions.extraHeaders = [ statsHeader ];
      }

      let errorCode = null;

      if (reasonCode in DISCONNECT_REASONS) {
        const reason = DISCONNECT_REASONS[reasonCode];
        terminateOptions.status_code = reason.status_code;
        terminateOptions.reason_phrase = reason.reason_phrase;
        if (reason.connectionErrorCode) {
          errorCode = reason.connectionErrorCode;
        }
      }

      if (errorCode || causeError) {
        terminateOptions.cause = new Exceptions.ConnectionError(errorCode || 'ERR_WEBCALL_SIP_CONNECTION', causeError);
      }

      this._session.terminate(terminateOptions);
    } else { // no RTCSession has been created yet. cancel call
      this._disconnected();
    }
  }

  _disconnected(err) {
    this.log('_disconnected()');
    if (err) {
      this.log('_disconnected() | error', err);
      if (err.cause)
        this.log('_disconnected() | error cause', err.cause);
    }

    this._teardownPeerConnection();
    this._mediaStream = null;

    this._statsMonitor.stop();
    this._lastStatsSample = null;

    if (this._session) {
      this._session.removeAllListeners();
    }
    this._session = null;

    if (this.ua) {
      this.ua.stop();
      this.ua.removeAllListeners();
    }
    this.ua = null;

    // Emit state event
    this._setState(STATE.DISCONNECTED, err);
  }

  sendJsonINFO(jsonObject) {
    if (!this._session)
      return;

    this._session.sendRequest(JsSIP.C.INFO, {
      extraHeaders: [
        'Content-Type: application/json'
      ],
      body: JSON.stringify(jsonObject)
    });
  }

  _setupStatsMonitor() {
    this._statsMonitor = new StatsMonitor();

    this._statsMonitor.on('sample', sample => {
      this._lastStatsSample = sample;

      this._emitEvent('statsSample', sample, true);
    });

    this._statsMonitor.on('warningUpdate', warnings => {
      this._emitEvent('statsWarningUpdate', warnings);
    });

    this._statsMonitor.on('error', error => {
      this.log('StatsMonitor Error', error);
      this._emitEvent('error', error);
    });
  }

  _emitEvent(eventName, event, noLog) {
    if (!noLog)
      this.log(`_emitEvent(${eventName})`);

    this.emit(eventName, event);
  }

  static get STATE() {
    return STATE;
  }
}

function getFingerprint(sdp) {
  let { fingerprint } = sdp;

  sdp.media.forEach(mediaSection => {
    if (mediaSection.fingerprint)
      fingerprint = mediaSection.fingerprint;
  });

  if (!fingerprint)
    return null;

  return `${fingerprint.type} ${fingerprint.hash}`;
}

function getPCSummary(pc) {
  const snapshot = getPCSnapshot(pc);
  return {
    signalingState: snapshot.pc.signalingState,
    transceivers: snapshot.transceivers.map(t => ({
      direction: t.direction,
      mid: t.mid,
      stopped: t.stopped,
      senderTrack: !!t.senderTrack,
      receiverTrack: !!t.receiverTrack,
    })),
  };
}

function getPCSnapshot(pc) {
  const getTrackInfo = t => {
    return {
      enabled: t.enabled,
      id: t.id,
      kind: t.kind,
      label: t.label,
      muted: t.muted,
      readyState: t.readyState,
    };
  };

  let transceivers = [];
  if (pc.getTransceivers) {
    transceivers = pc.getTransceivers().map(t => ({
      currentDirection: t.currentDirection,
      direction: t.direction,
      mid: t.mid,
      stopped: t.stopped,
      sender: !!t.sender,
      senderTrack: t.sender ? t.sender.track && getTrackInfo(t.sender.track) : null,
      receiver: !!t.receiver,
      receiverTrack: t.receiver ? t.receiver.track && getTrackInfo(t.receiver.track) : null,
    }));
  }

  return {
    pc: {
      signalingState: pc.signalingState,
      connectionState: pc.connectionState,
      iceConnectionState: pc.iceConnectionState,
      iceGatheringState: pc.iceGatheringState,
    },
    transceivers,
  };
}
