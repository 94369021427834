export class LoadError extends Error {
  constructor(message = null) {
    super();
    this.name = 'LoadError';
    if (message)
      this.message = message;
  }
}

export class NotFoundError extends Error {
  constructor(message = null) {
    super();
    this.name = 'NotFoundError';
    if (message)
      this.message = message;
  }
}

export class InvalidRequestError extends Error {
  constructor(message = null) {
    super();
    this.name = 'InvalidRequestError';
    if (message)
      this.message = message;
  }
}

export class AuthError extends Error {
  constructor(message = null) {
    super();
    this.name = 'AuthError';
    if (message)
      this.message = message;
  }
}
