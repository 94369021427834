import passiveSupported from './passiveSupported';

// IE11 does not suport new Event()
let eventConstructorSupported = false;

try {
  const event = new Event('change'); // eslint-disable-line no-unused-vars
  eventConstructorSupported = true;
} catch (err) {
  eventConstructorSupported = false;
}

function listenerOptionsEqual(listener, type, callback, tag, options) {
  if (listener.type !== type || listener.callback !== callback || listener.tag !== tag) {
    return false;
  }

  if (typeof options === 'object') {
    return listener.options.passive === options.passive &&
      listener.options.capture === options.capture;
  } else {
    return listener.options === options;
  }
}

export default class DOMEventEmitter {
  constructor(element) {
    this._element = element;
    this._listeners = [];
  }

  get element() {
    return this._element;
  }

  toggleListener(event, callback, toggle = false, { tag = false, passive = true, capture = false, onceWrapper = false } = {}) {
    const events = event.split(' ');
    let options = capture;
    if (passiveSupported) {
      options = {
        passive,
        capture,
      };
    }

    events.forEach(type => {
      if (this.eventMap && type in this.eventMap) {
        type = this.eventMap[type];
      }

      if (toggle) {
        this._listeners.push({
          type,
          callback,
          tag,
          onceWrapper,
          options,
        });

        this._element.addEventListener(type, onceWrapper || callback, options);
      } else {
        this._listeners = this._listeners.filter(l => {
          if (!listenerOptionsEqual(l, type, callback, tag, options)) {
            return true;
          }

          this._element.removeEventListener(type, l.onceWrapper || callback, options);

          return false;
        });
      }
    });

    return this;
  }

  on(event, callback, { tag = false, passive = true, capture = false } = {}) {
    return this.toggleListener(event, callback, true, { tag, passive, capture });
  }

  off(event, callback, { tag = false, passive = true, capture = false } = {}) {
    return this.toggleListener(event, callback, false, { tag, passive, capture });
  }

  once(event, callback, { tag = false, passive = true, capture = false } = {}) {
    const onceWrapper = (...args) => {
      this.toggleListener(event, callback, false, { tag, passive, capture, onceWrapper });
      callback.apply(undefined, args);
    };

    return this.toggleListener(event, callback, true, { tag, passive, capture, onceWrapper });
  }

  removeAllListeners(tagFilter = false) {
    this._listeners = this._listeners.filter(l => {
      const { type, callback, onceWrapper, tag, options } = l;
      if (tag !== tagFilter) {
        return true;
      }

      this._element.removeEventListener(type, onceWrapper || callback, options);

      return false;
    });

    return this;
  }

  emit(eventType) {
    let event;

    if (eventConstructorSupported) {
      event = new Event(eventType);
    } else {
      event = document.createEvent('Event');
      event.initEvent('change', true, true);
    }

    event.isSynthetic = true;

    this._element.dispatchEvent(event);
  }
}
