export function secondsToHHMMSS(secs) {
  let hours = Math.floor(secs / 3600);
  let mins = Math.floor(secs / 60) - hours * 60;
  let seconds = secs - (hours * 3600 + mins * 60);

  if (hours < 10)
    hours = '0' + hours;

  if (mins < 10)
    mins = '0' + mins;

  if (seconds < 10)
    seconds = '0' + seconds;

  return `${hours}:${mins}:${seconds}`;
}

export function secondsToMMSS(secs) {
  let mins = Math.floor(secs / 60);
  let seconds = secs - mins * 60;

  if (mins < 10)
    mins = '0' + mins;

  if (seconds < 10)
    seconds = '0' + seconds;

  return `${mins}:${seconds}`;
}

export function formatTimestamp(val) {
  const secs = Math.floor(val / 1000);
  const ms = val % 1000;

  let ret;
  if (secs >= 3600) {
    // hh:mm:ss.sss
    ret = secondsToHHMMSS(secs);
  } else {
    // mm:ss.sss
    ret = secondsToMMSS(secs);
  }

  if (ms) {
    ret += `.${ms.toString().padStart(3, '0')}`;
  }

  return ret;
}

export function parseTimestamp(val) {
  if (val === '') {
    return 0;
  }
  if (val === '.') {
    return false;
  }
  const match = val.match(/^(?:(\d+):)?(?:(\d+):)?(\d+)?(?:\.(\d{0,3})\d*)?$/);
  if (!match) {
    return false;
  }

  const [ , h, m, s, ms ] = match;

  let ret = 0;

  if (h !== undefined) {
    if (m !== undefined) {
      // hh:mm:ss
      ret += parseInt(h, 10) * 3600000;
    } else {
      // mm:ss
      ret += parseInt(h, 10) * 60000;
    }
  }

  if (m !== undefined) {
    ret += parseInt(m, 10) * 60000;
  }

  if (s !== undefined) {
    ret += parseInt(s, 10) * 1000;
  }

  if (ms !== undefined) {
    ret += parseInt(ms.padEnd(3, '0'));
  }

  return ret;
}

import timezones from './timezones.json';

const timezonesIndexed = {};
timezones.forEach(_ => {
  timezonesIndexed[_.value] = _.label;
});

export function getTimezoneLabel(tz) {
  return timezonesIndexed[tz] || tz;
}

export function isTimezoneValid(val) {
  return val in timezonesIndexed;
}

export function getTimezoneOptions() {
  // copy
  return timezones
    .map(_ => ({..._}));
}
