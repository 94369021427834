import EventEmitter from 'events';

import Overlay from 'Components/Overlay';

class Dropzone extends EventEmitter {
  constructor(element, label) {
    super();

    this.element = element;
    this.element.append(
      <div class="dropzone-target-overlay overlay"><div class="dropzone-target-overlay-inner">{label}</div></div>
    );
    this.overlay = new Overlay(element);
  }
}

export default class DropzoneFactory {
  static createDropzone(element, label) {
    this._init();

    this._currentDropzone = new Dropzone(element, label);

    return this._currentDropzone;
  }

  static _init() {
    if (this._initDone) {
      return;
    }

    this._dragTargets = [];

    document.documentElement.addEventListener('dragenter', e => {
      e.preventDefault();
      e.stopPropagation();

      this._dragTargets.push(e.target);

      if (this._dragTargets.length > 1) {
        return;
      }

      this._currentDropzone.overlay.show();
    });

    document.documentElement.addEventListener('dragleave', e => {
      e.stopPropagation();

      const targetIdx = this._dragTargets.indexOf(e.target);
      if (targetIdx !== -1) {
        this._dragTargets.splice(targetIdx, 1);
      }

      if (this._dragTargets.length > 0) {
        return;
      }

      this._currentDropzone.overlay.hide();
    });

    document.documentElement.addEventListener('dragover', e => {
      e.preventDefault();
      e.stopPropagation();

      if (this._currentDropzone.element.contains(e.target)) {
        e.dataTransfer.effectAllowed = 'copy';
        e.dataTransfer.dropEffect = 'copy';
      } else {
        e.dataTransfer.effectAllowed = 'none';
        e.dataTransfer.dropEffect = 'none';
      }
    });

    document.documentElement.addEventListener('drop', e => {
      e.preventDefault();
      e.stopPropagation();

      this._dragTargets = [];

      this._currentDropzone.overlay.hide();
      this._currentDropzone.emit('drop', e);
    });

    this._initDone = true;
  }

  static useDropzone(dropzone) {
    this._currentDropzone = dropzone;
  }
}
