import { Cond } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';

import Subpage from './Subpage';
import CallList from './CallList';
import { BroadcastPanel } from './Broadcast';
import ConfPendingPanel from './ConfPendingPanel';
import ConfSettings from './ConfSettings';
import HandRaising from './HandRaising';
import NameTranscribe from './NameTranscribe';
import WebCallLoader from './WebCallLoader';

export default class Lcm extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
        subConfID: {
          key: 'id',
        },
      },

      options: {
        back: {
          defaultVal: '',
        },
      },
    };
  }

  init(config) {
    const hooks = this.hooks = new Hooks();

    const root = (
      <div
        class="subpage subpage-lcm"
        use:hook={hooks.toggleClass('isConfActive', 'state-conf-active')}
        use:hook={hooks.toggleClass('isSubConfActive', 'state-in-sub-conf')}
        use:hook={hooks.toggleClass('makeCallAllowed', 'forbid-make-call', val => !val)}
      >
        <div class="subpage-content">
          <ConfSettings ref={this._confSettings} ctrl={this.ctrl} />
          <Cond test={WebCallLoader.playerAppUI}>
            {WebCallLoader.playerAppUI}
          </Cond>
          <BroadcastPanel ctrl={this.ctrl.broadcastController} />
          <ConfPendingPanel ctrl={this.ctrl} ref={this._confPendingPanel} />
          <NameTranscribe ctrl={this.ctrl} ref={this._nameTranscribe} />
          <HandRaising
            ctrl={this.ctrl}
            ref={this._handRaising} />
          <CallList
            config={config}
            onRetry={() => this.ctrl.retry()}
            ctrl={this.ctrl}
            ref={this._callList} />
        </div>
      </div>
    );

    super.init(root);

    this.ctrl.on('update', () => this.render());
  }

  processParams(rawParams, fromHash) {
    super.processParams(rawParams, fromHash);
    this.ctrl.setSubConfID(this._params.subConfID);
  }

  getActiveTabKey() {
    let key = 'lcm';
    if (this._params.subConfID)
      key += `/${this._params.subConfID}`;

    return key;
  }

  activate() {
    return this.ctrl.fetchBridgeData();
  }

  detach() {
    this.ctrl.setSubConfID(null);
  }

  render() {
    if (!this.isActive) return;

    this.hooks.run(this.ctrl);

    this._confSettings.render();
    this._confPendingPanel.render();
    this._nameTranscribe.render();
    this._handRaising.render();
    this._callList.render();
  }
}
