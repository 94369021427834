export default function(error) {
  const ret = {
    ...error
  };

  ret.toString = error.toString();

  Object.getOwnPropertyNames(error).forEach(function(name) {
    const type = typeof error[name];

    if (ret[name] === undefined && type !== 'object' && type !== 'function')
      ret[name] = error[name];
  });

  if (ret.message === undefined && error.message)
    ret.message = error.message;

  if (ret.stack === undefined && error.stack)
    ret.stack = error.stack;

  return ret;
}
