import { hook, Hooks } from 'Components/Hooks';

import {
  Backdrop,
  ViewConnected,

  PlayerPlaybackStatus,
  PlayerAudioFileName,
  PlayerPlaybackPosition,
  PlayerTogglePauseButton,
  InputVolumeMeter,

  DisconnectIconButton,

  EmbeddedPlayerViewConnecting,

  registerDrawLoop,
} from './UIComponents';

import s from './strings';

export default function EmbeddedPlayerCallUI({ ctrl }) {
  const hooks = new Hooks();

  const ctx = {
    hooks,
    ctrl,
  };

  const root = (
    <div>
      <Backdrop ctx={ctx} />

      <div
        class="alert alert-success"
        use:hook={hooks.show('isActive')}
      >
        <ViewConnected ctx={ctx}>
          <PlayerPlaybackStatus ctx={ctx} />

          <PlayerAudioFileName ctx={ctx} />

          <strong class="ml-auto">{s.lblPlaybackPosition}</strong>
          <PlayerPlaybackPosition ctx={ctx} />

          <PlayerTogglePauseButton ctx={ctx} />

          <InputVolumeMeter ctx={ctx} alwaysShow />

          <DisconnectIconButton ctx={ctx} />
        </ViewConnected>

        <EmbeddedPlayerViewConnecting ctx={ctx} />
      </div>
    </div>
  );

  registerDrawLoop(ctrl, root);

  ctrl.on('update', () => hooks.run(ctrl));
  hooks.run(ctrl);

  return root;
}
