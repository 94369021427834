import Api from 'Api/Api';

import { formatTimestamp } from 'DateTime';
import formatSize from 'formatSize';

export function getRecordingInfo(params) {
  const allParams = {
    ...params,
    dateFormat: 'shortDateTime12Hr',
    fields: 'cdrID, recID, accountID, s, conferenceID, conferenceIDFormatted, customID, startedDate, endedDate, duration, size, passcode, playBackSelected, playBackDefault, playBackMode, playBackRecordingID, playBackNumber, playBackNumberFormatted, playBackStartMode, playBackStartOffset, recordingNumber, activationDate, expirationDate, ',
  };

  return Api.get('CDR', 'getRecordingInfo', allParams)
    .then(result => {
      const items = result.conferenceRecording || [];
      const { totalResults } = result;

      items.forEach(item => {
        item.durationDisplay = formatTimestamp(item.duration * 1000);
        item.sizeDisplay = formatSize(item.size);
      });

      return { items, totalResults };
    });
}
