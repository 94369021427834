import Alert from 'Components/Alert';
import { show, hide } from 'Components/domHelpers';
import { Cond, RefFormData, FormTextarea } from 'Components/FormComponents';
import Modal from 'Components/Modal';

import Subpage from './Subpage';
import BridgeInfoTable from './BridgeInfoTable';
import EmailBridgeInfoForm from './EmailBridgeInfoForm';

import s from './strings';

export default class Info extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
      },

      options: {
      }
    };
  }

  init(config) {
    const openEmailInfoModal = () => {
      hide(this._successMessage);
      this._emailInfoModal.show();
    };

    const root = (
      <div class="subpage subpage-info">
        <div class="subpage-content">
          <Alert type="success" ref={this._successMessage}>{s.lblEmailSent}</Alert>

          <BridgeInfoTable ctrl={this.ctrl} hideConferenceID={config.hideConferenceID} />

          <p class="note">{s.lblBridgeInfoHostComment}</p>

          <div class="btn-toolbar-discrete">
            <Cond test={config.showEmailMyInfoFlag}>
              <button type="button" class="btn btn-primary" onclick={() => openEmailInfoModal()}>{s.lblEmailMyInfo}</button>
            </Cond>
            <Cond test={config.showPrintWalletCardsFlag}>
              <button type="button" class="btn btn-primary" onclick={() => this.openSubpage('wallet')}>{s.lblPrintWalletCards}</button>
            </Cond>
            <button type="button" class="btn btn-primary" onclick={() => this._inviteModal.show()}>{s.lblInviteText}</button>
          </div>
        </div>
      </div>
    );

    super.init(root);

    this._emailInfoModal = new EmailInfoModal({
      onSuccess: () => show(this._successMessage),
    });

    this._inviteForm = new RefFormData();
    this._inviteModal = new Modal({
      appendToBody: true,
      title: s.lblConferenceInvitation,
      children: (
        <>
          <div class="modal-body">
            <FormTextarea form={this._inviteForm} inline name="inviteText" label={s.lblInviteTextNote} inputAttributes={{rows: 5}} />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" onclick={() => this._inviteModal.hide()}>{s.lblClose}</button>
          </div>
        </>
      ),
    });
  }

  activate() {
    return this.ctrl.fetchBridgeData()
      .then(bridgeData => {
        hide(this._successMessage);
        this._inviteForm.set('inviteText', this._getInviteText(bridgeData));
      });
  }

  _getInviteText(bridgeData) {
    let str = s.lblInviteTextTop;

    str += '\r\n\r\n' + '1. ' + s.lblInviteTextDial;

    var tollNumberExists = false;

    if (bridgeData.tollNumber) {
      tollNumberExists = true;
      str += ' ' + bridgeData.tollNumberFormatted;
    }

    if (bridgeData.tollFreeNumber) {
      if (tollNumberExists)
        str += ' ' + s.lblInviteTextOr;

      str += ' ' + bridgeData.tollFreeNumberFormatted;
      str += ' ' + s.lblInviteTextTollFree;
    }

    str += '.\r\n\r\n';

    str += '2. ' + s.lblInviteTextEnterConferenceID + ' ' + bridgeData.conferenceIDFormatted + ' ';
    str += s.lblInviteTextAndPressPound;

    return str;
  }
}

class EmailInfoModal {
  constructor({ onSuccess }) {
    const _onSuccess = () => {
      this._modal.hide();
      onSuccess();
    };

    this._modal = new Modal({
      appendToBody: true,
      title: s.lblEmailMyInfo,
      children: (
        <>
          <div class="modal-body">
            <EmailBridgeInfoForm onSuccess={_onSuccess} ref={this._form} />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" onclick={() => this._form.send()}>{s.lblSend}</button>
            <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblCancel}</button>
          </div>
        </>
      ),
    });
  }

  show() {
    this._form.render();
    this._modal.show();
  }
}
