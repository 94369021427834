import ApiContext from './ApiContext';

export default class ApiContextAuthNone extends ApiContext {
  constructor(name, config) {
    super(name, config);
  }

  getAuthParams() {
    return {
      authNone: 1
    };
  }
}
