import JsSIP from 'jssip/JsSIP';
import Transport from 'jssip/Transport';

// Add browser userAgent to JsSIP

JsSIP.C.USER_AGENT += ' Browser - ' + navigator.userAgent;

// Override JsSIP Transport _reconnect to disable auto-reconnect

Transport.prototype._reconnect = () => {};

// Do Browser feature detection
//
// browser  versions   hasTransceivers
// chrome   67-68      no addTransceiver
// chrome   69-72      has addTransceiver, requires sdpSemantics: 'unified-plan'
// chrome   73-        yes
// firefox  52-58      no addTransceiver
// firefox  59-        yes
// safari   11-        yes

function isAddTransceiverReal() {
  const tempPc = new RTCPeerConnection();
  let canAddTransceiver = false;
  try {
    tempPc.addTransceiver('audio');
    canAddTransceiver = true;
  } catch (e) {
  }
  tempPc.close();
  return canAddTransceiver;
}

const hasTransceivers =
  window.RTCRtpSender &&
  window.RTCPeerConnection &&
  window.RTCPeerConnection.prototype &&
  window.RTCPeerConnection.prototype.addTransceiver &&
  isAddTransceiverReal();

JsSIP.hasTransceivers = function() {
  return hasTransceivers;
};

export default JsSIP;
