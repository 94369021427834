import { hook } from 'Components/Hooks';
import TbIcons from 'Components/TbIcons';
import s from './strings';

export function BroadcastTogglePauseButton({ hooks, onclick }) {
  return (
    <button
      type="button"
      class="btn-flat"
      use:hook={hooks.textContent('isPlaying', isPlaying => isPlaying ? TbIcons.HOLD : TbIcons.PLAY)}
      use:hook={hooks.attr('isPlaying', 'title', isPlaying => isPlaying ? s.Broadcast.pause : s.Broadcast.play)}
      onclick={onclick}
    >
      {TbIcons.HOLD}
    </button>
  );
}

export function BroadcastDisconnectButton({ onclick }) {
  return (
    <button
      type="button"
      class="btn-flat"
      title={s.Broadcast.stop}
      onclick={onclick}
    >
      {TbIcons.STOP}
    </button>
  );
}

export function EqSpectrumSvgDynamic({ hooks }) {
  const numOfBars = 4;
  const speed = 1;

  const ratio = 1.618;
  const outerHeight = 100;
  const outerWidth = outerHeight * ratio;

  const barWidth = (outerWidth - numOfBars) / numOfBars;
  const barMargin = 1;
  const barDefs = [
    {
      max: .95,
      min: .40,
    },
    {
      max: .75,
      min: .40,
    },
    {
      max: .90,
      min: .45,
    },
    {
      max: .80,
      min: .60,
    },
  ];

  const maxDistance = Math.max(...barDefs.map(def => def.max - def.min)) * outerHeight;

  const root = (
    <svg viewBox={`0 0 ${outerWidth} ${outerHeight}`}>
      {barDefs.map((bar, i) => {
        const maxHeight = bar.max * outerHeight;
        const minHeight = bar.min * outerHeight;
        const duration = ((maxHeight - minHeight) / maxDistance) / speed;

        return (
          <rect
            key={i}
            className="eq-bar"
            x={barWidth * i + barMargin * i}
            y={outerHeight - maxHeight}
            width={barWidth}
            height={outerHeight}
          >
            <animate
              attributeName="y"
              dur={`${duration}s`}
              repeatCount="indefinite"
              values={`${outerHeight - minHeight};${outerHeight - maxHeight};${outerHeight - minHeight};`}
              keyTimes="0;0.1;1"
            />
          </rect>
        );
      })}
    </svg>
  );

  hooks.add('isPlaying', val => val ? root.unpauseAnimations() : root.pauseAnimations());

  return root;
}
