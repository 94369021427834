import Api from 'Api/Api';
import EventEmitter from 'events';
import { AudioRecorderController, ARC_PLAYING } from './AudioRecorder';
import simpleTemplate from './simpleTemplate';
import getPromptPreviewUrl from './getPromptPreviewUrl';

import { secondsToMMSS } from 'DateTime';

import s from './strings';

export const BSC_DEFAULT = 0;

export const BSC_WELCOME_PROMPT_CHOOSE_ACTION = 1;
export const BSC_WELCOME_PROMPT_FILE = 2;
export const BSC_WELCOME_PROMPT_FILE_UPLOADING = 3;
export const BSC_WELCOME_PROMPT_TTS = 4;
export const BSC_WELCOME_PROMPT_TTS_UPLOADING = 5;
export const BSC_WELCOME_PROMPT_RECORD = 6;
export const BSC_WELCOME_PROMPT_RECORD_UPLOADING = 7;
export const BSC_WELCOME_PROMPT_DIAL_IN = 8;
export const BSC_WELCOME_PROMPT_CONFIRM = 9;

export const BSC_MOH_UPLOAD = 10;
export const BSC_MOH_UPLOADING = 11;

const BSC_CANCEL_ID_MOH = 'BridgeSettingsControllerMOH';
const BSC_CANCEL_ID_WELCOME_PROMPT = 'BridgeSettingsControllerWelcomePrompt';

const WELCOME_PROMPT_MAX_DURATION_MS_DEFAULT = 60000;

const TTS_DESCRIPTION_LENGTH = 150;

function isPromptID(val) {
  if (!val)
    return false;

  return !!val.match(/^[\da-f]{64}$/);
}

function truncate(text, maxLength, truncatedSuffix = '') {
  // collapse all whitespaces into single space
  let str = text.replace(/\s+/g, ' ');

  if (str.length <= maxLength)
    return str;

  str = str.substring(0, maxLength - truncatedSuffix.length);
  const lastSpace = str.lastIndexOf(' ');
  if (lastSpace !== -1) {
    str = str.substring(0, lastSpace);
  }

  return `${str}${truncatedSuffix}`;
}

export default class BridgeSettingsController extends EventEmitter {
  constructor(config) {
    super();

    this._welcomePrompt = null;
    this._welcomePromptOptions = this._getWelcomePromptOptions(config);
    this._welcomePromptItems = [];
    this._welcomePromptDescription = null;
    this._welcomePromptNewDescription = null;
    this._welcomePromptRecordingMaxDuration = config.welcomePromptRecordingMaxDuration || WELCOME_PROMPT_MAX_DURATION_MS_DEFAULT;
    this._welcomePromptTtsText = '';

    this._dialInInstructions = null;
    if (config.welcomePromptRecordingNumber) {
      this._dialInInstructions = simpleTemplate(s.Settings.promptDialInBody, {
        welcomePromptRecordingNumber: config.welcomePromptRecordingNumber,
        welcomePromptRecordingMaxDuration: Math.floor(this._welcomePromptRecordingMaxDuration / 1000),
      }, true);
    }

    this._musicOnHold = null;
    this._musicOnHoldOptions = this._getMusicOnHoldOptions(config);
    this._musicOnHoldItems = [];
    this._musicOnHoldPromptDescription = null;

    this._state = BSC_DEFAULT;
    this._welcomePromptAction = null;

    this.audioRecorderController = new AudioRecorderController({ maxRecordingDurationMS: this._welcomePromptRecordingMaxDuration});

    this.audioRecorderController.on('update', () => this.emit('update'));

    this._ttsVoices = [];
    if (config.ttsVoices) {
      let parsed;
      try {
        parsed = JSON.parse(config.ttsVoices);
      } catch (err) {
      }
      if (parsed && Array.isArray(parsed))
        this._ttsVoices = parsed;
    }
    this._isTtsEnabled = !!this._ttsVoices.length;
    this._ttsVoiceOptions = this._ttsVoices.map(val => {
      return {
        value: val.voiceName,
        label: val.voiceName,
      };
    });
  }

  _getWelcomePromptOptions(config) {
    let opts;

    if (config.welcomePromptOptions) {
      try {
        opts = JSON.parse(config.welcomePromptOptions);
        opts.forEach(item => {
          if (isPromptID(item.value)) {
            item.uri = () => getPromptPreviewUrl(item.value);
          }
        });
      } catch (err) {
      }
    }

    return opts || [];
  }

  _getMusicOnHoldOptions(config) {
    let opts;

    if (config.musicOnHoldOptions) {
      try {
        opts = JSON.parse(config.musicOnHoldOptions);
        opts.forEach(item => {
          if (isPromptID(item.value)) {
            item.uri = () => getPromptPreviewUrl(item.value);
          }
        });
      } catch (err) {
      }
    }

    return opts || [
      {
        value: 'music',
        label: s.lblYes,
      },
      {
        value: 'beep',
        label: s.lblNo,
      },
    ];
  }

  _updatePromptItems(options, value, description) {
    const items = [
      ...options,
    ];

    if (!items.find(cur => cur.value === value)) {
      // if the current value setting is not in the base set of
      // options, add it
      let label = `${s.Settings.promptOptionPrefix}${value}`;
      let uri;

      if (isPromptID(value)) {
        uri = () => getPromptPreviewUrl(value);

        if (description) {
          label = description;
        } else {
          label = s.Settings.promptDescriptionFallback;
        }
      }

      items.push({
        value,
        label,
        uri,
      });
    }

    return items;
  }

  _welcomePromptUploadFinished(result) {
    const { promptID } = result.audioPrompt;

    this._welcomePromptNew = promptID;

    this._state = BSC_WELCOME_PROMPT_CONFIRM;
    this.emit('update');
  }

  welcomePromptOpen() {
    this._state = BSC_WELCOME_PROMPT_CHOOSE_ACTION;
    this.emit('update');
  }

  uploadPromptDismiss() {
    this._uploadPromptAbortRequests();

    this._state = BSC_DEFAULT;
    this.emit('update');
  }

  uploadPromptBack() {
    this._uploadPromptAbortRequests();

    switch (this._state) {
    case BSC_WELCOME_PROMPT_FILE:
    case BSC_WELCOME_PROMPT_TTS:
    case BSC_WELCOME_PROMPT_RECORD:
    case BSC_WELCOME_PROMPT_DIAL_IN:
      this._state = BSC_WELCOME_PROMPT_CHOOSE_ACTION;
      break;

    case BSC_WELCOME_PROMPT_FILE_UPLOADING:
      this._state = BSC_WELCOME_PROMPT_FILE;
      break;

    case BSC_WELCOME_PROMPT_TTS_UPLOADING:
      this._state = BSC_WELCOME_PROMPT_TTS;
      break;

    case BSC_WELCOME_PROMPT_RECORD_UPLOADING:
      this._state = BSC_WELCOME_PROMPT_RECORD;
      break;

    case BSC_MOH_UPLOADING:
      this._state = BSC_MOH_UPLOAD;
      break;

    case BSC_WELCOME_PROMPT_CONFIRM:
      this._state = this._welcomePromptAction;
      break;
    }

    this.emit('update');
  }

  _uploadPromptAbortRequests() {
    switch (this._state) {
    case BSC_WELCOME_PROMPT_FILE_UPLOADING:
    case BSC_WELCOME_PROMPT_TTS_UPLOADING:
    case BSC_WELCOME_PROMPT_RECORD_UPLOADING:
      Api.defaultContext.abort(BSC_CANCEL_ID_WELCOME_PROMPT);
      break;

    case BSC_MOH_UPLOADING:
      Api.defaultContext.abort(BSC_CANCEL_ID_MOH);
      break;
    }
  }

  welcomePromptChooseUpload() {
    this._state = BSC_WELCOME_PROMPT_FILE;
    this._welcomePromptAction = this._state;
    this._welcomePromptTtsText = '';
    this.emit('update');
  }

  welcomePromptChooseTTS() {
    this._state = BSC_WELCOME_PROMPT_TTS;
    this._welcomePromptAction = this._state;
    this._welcomePromptTtsText = '';
    this.emit('update');
  }

  welcomePromptChooseRecord() {
    this._state = BSC_WELCOME_PROMPT_RECORD;
    this._welcomePromptAction = this._state;
    this._welcomePromptTtsText = '';
    this.emit('update');

    this.audioRecorderController.clear();
  }

  welcomePromptChooseDialIn() {
    this._state = BSC_WELCOME_PROMPT_DIAL_IN;
    this._welcomePromptAction = this._state;
    this._welcomePromptTtsText = '';
    this.emit('update');
  }

  welcomePromptUploadFile(fileInput, onUploadProgress) {
    const { files } = fileInput;
    const [ file ] = files;

    this._state = BSC_WELCOME_PROMPT_FILE_UPLOADING;
    this._welcomePromptNewDescription = fileInput.filename;
    this.emit('update');

    const params = {};
    const opts = {
      cancelID: BSC_CANCEL_ID_WELCOME_PROMPT,
      timeout: 0,
      files: {
        audioPrompt: file,
      },
      onUploadProgress,
    };

    return Api.get('Prompt', 'uploadAudioPrompt', params, opts)
      .then(result => this._welcomePromptUploadFinished(result));
  }

  welcomePromptUploadTTS(ttsVoice, text, onUploadProgress) {
    this._state = BSC_WELCOME_PROMPT_TTS_UPLOADING;
    this._welcomePromptNewDescription = this._getTtsDescription(ttsVoice, text);
    this._welcomePromptTtsText = text;
    this.emit('update');

    const params = {
      voiceName: ttsVoice,
      text,
    };

    const opts = {
      cancelID: BSC_CANCEL_ID_WELCOME_PROMPT,
      timeout: 0,
      onUploadProgress,
    };

    return Api.get('Prompt', 'createTextPrompt', params, opts)
      .then(result => this._welcomePromptUploadFinished(result));
  }

  welcomePromptUploadRecord(file, onUploadProgress) {
    if (!this.audioRecorderController.hasAudioData) {
      return Promise.resolve();
    }

    this._state = BSC_WELCOME_PROMPT_RECORD_UPLOADING;
    this._welcomePromptNewDescription = s.Settings.welcomePromptAudioRecording;
    this.emit('update');

    if (this.audioRecorderController.state === ARC_PLAYING) {
      this.audioRecorderController.stop();
    }

    const params = {};
    const opts = {
      cancelID: BSC_CANCEL_ID_WELCOME_PROMPT,
      timeout: 0,
      files: {
        audioPrompt: file,
      },
      onUploadProgress,
    };

    return Api.get('Prompt', 'uploadAudioPrompt', params, opts)
      .then(result => this._welcomePromptUploadFinished(result));
  }

  welcomePromptConfirmFinish(description) {
    this._welcomePrompt = this._welcomePromptNew;
    this._welcomePromptDescription = description;

    this._welcomePromptItems = this._updatePromptItems(
      this._welcomePromptOptions,
      this._welcomePrompt,
      this._welcomePromptDescription
    );

    this._state = BSC_DEFAULT;

    this.emit('update');
  }

  mohPromptOpen() {
    this._state = BSC_MOH_UPLOAD;
    this.emit('update');
  }

  mohPromptUploadFile(file, description, onUploadProgress) {
    this._state = BSC_MOH_UPLOADING;
    this.emit('update');

    const params = {};
    const opts = {
      cancelID: BSC_CANCEL_ID_MOH,
      timeout: 0,
      files: {
        audioPrompt: file,
      },
      onUploadProgress,
    };

    return Api.get('Prompt', 'uploadAudioPrompt', params, opts)
      .then(result => {
        const { promptID } = result.audioPrompt;

        this._musicOnHold = promptID;
        this._musicOnHoldPromptDescription = description;
        this._musicOnHoldItems = this._updatePromptItems(
          this._musicOnHoldOptions,
          promptID,
          description
        );

        this._state = BSC_DEFAULT;

        this.emit('update');
      });
  }

  update(bridgeData) {
    this._state = BSC_DEFAULT;

    return Promise.resolve(bridgeData)
      .then(bridgeData => {

        this._musicOnHoldItems = this._updatePromptItems(
          this._musicOnHoldOptions,
          bridgeData.musicOnHold,
          bridgeData.musicOnHoldPromptDescription
        );

        if (bridgeData.welcomePrompt === null)
          bridgeData.welcomePrompt = '';

        this._welcomePromptItems = this._updatePromptItems(
          this._welcomePromptOptions,
          bridgeData.welcomePrompt,
          bridgeData.welcomePromptDescription
        );

        this._welcomePrompt = bridgeData.welcomePrompt;
        this._welcomePromptDescription = bridgeData.welcomePromptDescription;

        this._musicOnHold = bridgeData.musicOnHold;
        this._musicOnHoldPromptDescription = bridgeData.musicOnHoldPromptDescription;

        this.emit('update');

        return bridgeData;
      });
  }

  _getTtsDescription(voice, text) {
    const prefix = `${s.Settings.welcomePromptTtsDescriptionPrefix} ${voice}: `;
    const str = truncate(text, TTS_DESCRIPTION_LENGTH - prefix.length, s.Settings.welcomePromptTtsDescriptionSuffix);
    return `${prefix}${str}`;
  }

  get isWelcomePromptEnabled() {
    return !!this._welcomePromptOptions.length;
  }

  get isTtsEnabled() {
    return this._isTtsEnabled;
  }

  get ttsVoiceOptions() {
    return this._ttsVoiceOptions;
  }

  get state() {
    return this._state;
  }

  get musicOnHoldItems() {
    return this._musicOnHoldItems;
  }

  get isWelcomePromptVisible() {
    return [
      BSC_WELCOME_PROMPT_CHOOSE_ACTION,
      BSC_WELCOME_PROMPT_FILE,
      BSC_WELCOME_PROMPT_FILE_UPLOADING,
      BSC_WELCOME_PROMPT_TTS,
      BSC_WELCOME_PROMPT_TTS_UPLOADING,
      BSC_WELCOME_PROMPT_RECORD,
      BSC_WELCOME_PROMPT_RECORD_UPLOADING,
      BSC_WELCOME_PROMPT_DIAL_IN,
      BSC_WELCOME_PROMPT_CONFIRM,
    ].includes(this._state);
  }

  get isMohPromptVisible() {
    return [
      BSC_MOH_UPLOAD,
      BSC_MOH_UPLOADING,
    ].includes(this._state);
  }

  get audioRecorderHasData() {
    return this.audioRecorderController.hasAudioData;
  }

  get isAudioRecorderSupported() {
    return AudioRecorderController.isSupported;
  }

  get audioRecorderDuration() {
    return secondsToMMSS(this.audioRecorderController.duration);
  }

  get welcomePrompt() {
    return this._welcomePrompt;
  }

  get welcomePromptItems() {
    return this._welcomePromptItems;
  }

  get welcomePromptDescription() {
    return this._welcomePromptDescription;
  }

  get welcomePromptNewDescription() {
    return this._welcomePromptNewDescription;
  }

  get welcomePromptNewPreviewUrl() {
    return getPromptPreviewUrl(this._welcomePromptNew);
  }

  get welcomePromptTtsText() {
    return this._welcomePromptTtsText;
  }

  get dialInInstructions() {
    return this._dialInInstructions;
  }

  get musicOnHold() {
    return this._musicOnHold;
  }

  get musicOnHoldPromptDescription() {
    return this._musicOnHoldPromptDescription;
  }
}
