import EventEmitter from 'events';

import Api from 'Api/Api';

import s from './strings';

export default class ChatController extends EventEmitter {
  constructor() {
    super();

    this._isActive = false;
    this._name = null;
    this._chats = [];
    this._curChat = null;
    this._lastRead = {};
    this._lastVer = {};
  }

  update({ isActive, chats }) {
    this._isActive = isActive;
    this._chats = chats;

    if (!isActive) {
      this._curChat = null;
      this._lastRead = {};
      this._lastVer = {};
    }

    let newMessage = false;
    this._chats.forEach(chat => {
      const { chatID } = chat;

      if (this._curChat) {
        chat.isSelected = chatID === this._curChat.chatID;
      } else {
        // no current chat, select this one
        chat.isSelected = true;
      }

      if (chat.isSelected) {
        this._curChat = chat;
      }

      if (this._updateChatFields(chat))
        newMessage = true;
    });

    if (newMessage)
      this.emit('newMessage');

    this.emit('update');
  }

  updateName(name) {
    this._name = name;
    this.emit('update');
  }

  setName(name) {
    this.emit('nameChange', {
      name,
    });
  }

  setChatID(chatID) {
    if (this._curChat && chatID === this._curChat.chatID)
      return;

    let curChat;
    this._chats.forEach(chat => {
      chat.isSelected = chat.chatID === chatID;
      if (chat.isSelected)
        curChat = chat;

      this._updateChatFields(chat);
    });

    if (!curChat) {
      throw new Error('unknown chatID');
    }

    this._curChat = curChat;

    this.emit('update');
  }

  _updateChatFields(chat) {
    const { chatID } = chat;
    const lastVer = this._lastVer[chatID] || -1;
    const lastReadVer = this._lastRead[chatID] || -1;

    let newCount = 0;
    let unreadCount = 0;

    chat.msgs.forEach(msg => {
      if (msg.data.isSelf)
        return;

      if (msg.ver > lastVer)
        newCount++;

      if (!chat.isSelected && msg.ver > lastReadVer)
        unreadCount++;
    });

    if (chat.msgs.length) {
      this._lastVer[chatID] = chat.msgs[chat.msgs.length - 1].ver;
      if (chat.isSelected)
        this._lastRead[chatID] = chat.msgs[chat.msgs.length - 1].ver;
    }

    chat.unreadCount = unreadCount;

    return newCount;
  }

  sendMessage(message) {
    if (!this._isActive || !this._curChat || !message) {
      return Promise.resolve();
    }

    const params = {
      chatID: this._curChat.chatID,
      fromName: this.name,
      message,
    };

    return Api.get('LCM', 'sendMessage', params);
  }

  getChatText() {
    const allLines = this._chats.map(chat => {
      const lines = chat.msgs.map(msg => {
        const { data, tsDateDisplay } = msg;
        const { from, text } = data;
        const { name } = from;

        return `(${tsDateDisplay}) ${name}: ${text}`;
      });

      return `${s.Chat.saveChatLabelPrefix}${chat.label}\r\n${lines.join('\r\n\r\n')}`;
    });

    return allLines.join('\r\n\r\n');
  }

  get isActive() {
    return this._isActive;
  }

  get isDisabled() {
    return !!(this._isActive && !this._chats.length);
  }

  get name() {
    return this._name;
  }

  get chats() {
    return this._chats;
  }

  get chatLabel() {
    return this._curChat && this._curChat.label || '';
  }

  get messages() {
    return this._curChat && this._curChat.msgs || [];
  }
}
