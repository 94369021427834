import report from './report';

function configure(url, timeout) {
  report.configure(url, timeout);

  window.onerror = function(msg, url, line, col, jsErrorObject) {
    var errorData = {
      message : msg,
      url     : url,
      line    : line,
      col     : null
    };

    if (col !== undefined)
      errorData.col = col;

    report.send('window.onerror', errorData, jsErrorObject);
  };

  window.onunhandledrejection = function(event) {
    var jsErrorObject = null,
        reasonType,
        errorData = {
          eventToString : event.toString()
        };

    if (event.reason) {
      try {
        reasonType = typeof event.reason;

        if (reasonType == 'object') {
          if (event.reason instanceof Error) {
            jsErrorObject = event.reason;
          }
        } else if (reasonType != 'function') {
          errorData.reason = event.reason;
        }
      } catch (e) {
        errorData.message = 'error in unhandledrejection handler: ' + e.toString();
      }
    }

    report.send('promise unhandledrejection', errorData, jsErrorObject);
  };
}

export default {
  setAppName: report.setAppName,
  configure,
};
