import cloneError from 'Error/cloneError';

function isPrimitive(val) {
  const type = typeof val;
  return (val === null || type === 'string' || type === 'number' || type === 'boolean');
}

function isObject(val) {
  return typeof val === 'object';
}

function serializeDataField(field) {
  if (field instanceof Error) {
    return cloneError(field);
  }

  // pass thru null values, strings and numbers
  if (isPrimitive(field)) {
    return field;
  }

  // shallow copy objects
  if (isObject(field)) {
    const copy = {};

    Object.keys(field).forEach(_ => {
      const val = field[_];

      if (isPrimitive(val)) {
        copy[_] = val;
      } else if (isObject(val)) {
        copy[_] = val.toString && val.toString() || '[]';
      }
    });

    return copy;
  }
}

export default class LogBuffer {
  constructor(size) {
    this._size   = size;
    this._buffer = new Array(this._size);
    this._start  = 0;
    this._count  = 0;
  }

  write(namespace, msg, ...args) {
    const dataField = args && args[0];
    const end = (this._start + this._count) % this._size;

    this._buffer[end] = [
      Date.now(),
      namespace,
      msg,
      ...(dataField ? [ dataField ] : [])
    ];

    if (this._count === this._size)
      this._start = (this._start + 1) % this._size;
    else
      this._count++;
  }

  read() {
    const ret = [];

    for (let i = 0; i < this._count; i++) {
      const [ ts, namespace, msg, df ] =  this._buffer[(this._start + i) % this._size];
      const dataField = serializeDataField(df);

      ret.push([
        ts,
        namespace,
        msg,
        ...(dataField ? [ dataField ] : [])
      ]);
    }

    return ret;
  }
}
