import EventEmitter from 'events';
import { clamp } from 'MathUtil';

export const DEFAULT_RESULT_COUNT = 25;
export const DEFAULT_RESULT_COUNT_OPTS = [ 25, 50, 100 ];

export class PagingController extends EventEmitter {
  constructor({ onPageChange, onResultCountChange = null, resultCountDefault = DEFAULT_RESULT_COUNT, resultCountOpts = DEFAULT_RESULT_COUNT_OPTS }) {
    super();

    this._resultCountDefault = resultCountDefault;
    this._resultCountOpts = resultCountOpts;

    this._onPageChange = onPageChange;
    this._onResultCountChange = onResultCountChange;

    this.update({
      resultCount: this._resultCountDefault,
      page: 1,
      totalResults: 0,
    });
  }

  update({
    resultCount,
    page,
    totalResults = 0,
  }) {
    this._resultCount = resultCount;
    this._page = page;
    this._totalResults = totalResults;

    this._totalPages = Math.max(
      1,
      Math.ceil(this._totalResults / this._resultCount)
    );

    this._prevPage = clamp(this._page - 1, 1, this._totalPages),
    this._nextPage = clamp(this._page + 1, 1, this._totalPages),

    this._startOffset = this._resultCount * (this._page - 1);
    this._isPageInBounds = this._page <= this._totalPages;

    this.emit('update');
  }

  setResultCount(resultCount) {
    if (resultCount === this._resultCount) {
      return;
    }

    this._onResultCountChange(resultCount);
  }

  pageFirst() {
    this._emitPageChange(1);
  }

  pagePrev() {
    this._emitPageChange(this._prevPage);
  }

  pageNext() {
    this._emitPageChange(this._nextPage);
  }

  pageLast() {
    this._emitPageChange(this._totalPages);
  }

  pageSet(val) {
    let page = null;

    if (typeof val === 'string') {
      const str = val.trim();
      if (str.match(/^\d+$/)) {
        const parsed = parseInt(str, 10);
        if (!isNaN(parsed))
          page = parsed;
      }
    } else if (typeof val === 'number') {
      page = val;
    }

    if (Number.isInteger(page) && page >= 1 && page <= this._totalPages) {
      this._emitPageChange(page);
      return;
    }

    // val is not valid, emit update so page <input /> value get reverted
    this.emit('update');
  }

  get resultCountDefault() {
    return this._resultCountDefault;
  }

  get resultCountOpts() {
    return this._resultCountOpts;
  }

  get resultCount() {
    return this._resultCount;
  }

  get page() {
    return this._page;
  }

  get totalResults() {
    return this._totalResults;
  }

  get totalPages() {
    return this._totalPages;
  }

  get startOffset() {
    return this._startOffset;
  }

  get isPageInBounds() {
    return this._isPageInBounds;
  }

  _emitPageChange(newPage) {
    if (newPage === this._page) {
      return;
    }

    this._onPageChange(newPage);
  }
}
