import { toggle } from 'Components/domHelpers';

import ConfCommand from './ConfCommand';
import { LcmActionPanel } from './LcmComponents';
import s from './strings';

export default class ConfPendingPanel extends LcmActionPanel {
  static isClassComponent = true;

  constructor({ ctrl, ref }) {
    super({
      buttonLabel: s.ConfPendingPanel.buttonLabel,
      onClick: () => ConfCommand.start(),
      msg: s.ConfPendingPanel.msg,
    });

    ref(this);

    this._ctrl = ctrl;

    this._confPending = null;
  }

  render() {
    const { confPending } = this._ctrl;

    if (this._confPending === confPending)
      return;

    this._confPending = confPending;
    toggle(this.root, confPending);
  }
}
