import Api from 'Api/Api';

export default function getPromptPreviewUrl(promptID) {
  const params = {
    promptID,
    downloadPreview: true,
  };

  return Api.getURL('Prompt', 'getAudioPrompt', params, { returnHttpErrorCode: true });
}
