import { isTimezoneValid } from 'DateTime';

export default function validateTimezone(val) {
  val = this.validateString(val);
  if (val === undefined) {
    return;
  }

  if (!isTimezoneValid(val)) {
    return;
  }

  return val;
}
