import EventEmitter from 'events';

import { ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';

// the following are all in seconds
const POLL_DELAY = 10;
const DATA_SESSION_EXPIRES = POLL_DELAY * 3;
const RETRY_INITIAL = 3;
const RETRY_MAX = 60;
const RETRY_MAX_DRIFT = 3;

export default class ConferencePoller extends EventEmitter {
  constructor() {
    super();
    this._initState();
  }

  _initState() {
    this._state = 'pending';
    this._prevRequestError = false;
    this._sendRequestTimer = null;
    this._emitConfUpdateTimer = null;
    this._timezone = null;
    this._identity = null;

    this._resetRetry();
    this._resetResult();
  }

  start(identity) {
    this._identity = identity;
    this.sendRequest();
  }

  stop() {
    this._stopSendRequestTimer();
    this._stopRetryTimer();
    this._stopConfUpdateTimer();

    this._initState();
  }

  sendRequest(immediate = false) {
    this._stopSendRequestTimer();
    this._stopRetryTimer();

    // don't send requests when not logged in
    if (!this._identity)
      return;

    const delay = this._state === 'active' && !immediate
      ? POLL_DELAY
      : 0;

    const params = {
      delay,
      version: this._curVersion,
      ...(this._timezone && { timezone: this._timezone }),
      ...(this._identity.name && {
        dataSession: {
          expires: DATA_SESSION_EXPIRES,
          data: {
            name: this._identity.name,
          },
        }
      }),
    };

    Api.get('LCM', 'getConferenceInfo', params, { cancelID: 'ConferencePoller' })
      .then(result => {
        this._prevRequestError = false;

        this._state = 'active';
        this._resetRetry();
        this._processUpdate(result.conference);
      })
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        if (err instanceof ApiErrorResult) {
          const { code } = err;
          if (code === 'ERR_API_CONFERENCE_NOT_FOUND') {
            this._state = 'pending';
            this._resetRetry();
            this._resetResult();

            this._emitConfUpdate();

            this._prevRequestError = false;
            this._startSendRequestTimer(POLL_DELAY * 1000);
            return;
          }
        }

        if (this._prevRequestError) {
          this._retryStart();
          this._state = 'error';
          this._resetResult();

          this._emitConfUpdate();
        } else {
          this._prevRequestError = true;
          this.sendRequest(true);
        }
      });
  }

  _resetRetry() {
    this._retryLast = null;
    this._retryCtr = 0;
  }

  _resetResult() {
    this._curVersion = 0;
    this._curCdrID = null;
    this._lastResult = {};
  }

  // public retry method
  retry() {
    this._resetRetry();

    // force update so display is correct
    this._emitConfUpdate();

    this.sendRequest();
  }

  _retryStart() {
    this._stopRetryTimer();

    if (this._retryLast !== null) {
      this._retryLast = Math.min(this._retryLast * 2, RETRY_MAX);
      this._retryCtr = this._retryLast - Math.round(Math.random() * RETRY_MAX_DRIFT);
    } else {
      this._retryLast = this._retryCtr = RETRY_INITIAL;
    }

    this._retryTimer = setInterval(() => {
      this._retryCtr--;

      this._emitConfUpdate();

      if (this._retryCtr === 0) {
        this.sendRequest();
      }
    }, 1000);
  }

  _stopRetryTimer() {
    clearInterval(this._retryTimer);
  }

  _processUpdate(conference) {
    var tcsVersion = conference.version;
    if (this._curCdrID !== conference.cdrID || tcsVersion > this._curVersion)
      this._curVersion = tcsVersion;

    this._curCdrID = conference.cdrID;
    this._lastResult = conference;

    this._emitConfUpdate();

    this._startSendRequestTimer();
  }

  _stopSendRequestTimer() {
    if (this._sendRequestTimer)
      clearTimeout(this._sendRequestTimer);
  }

  _startSendRequestTimer(timeout = 0) {
    this._sendRequestTimer = setTimeout(() => this.sendRequest(), timeout);
  }

  _stopConfUpdateTimer() {
    if (this._emitConfUpdateTimer) {
      clearTimeout(this._emitConfUpdateTimer);
      this._emitConfUpdateTimer = null;
    }
  }

  _emitConfUpdate() {
    this._stopConfUpdateTimer();
    this._emitConfUpdateTimer = setTimeout(() => {
      this.emit('update');
    }, 0);
  }

  setTimezone(timezone) {
    this._timezone = timezone;
  }
}
