import { ApiErrorNetwork } from 'Api/ApiErrors';
import HashController from 'Browser/HashController';
import createQueryString from 'Browser/createQueryString';
import parseQueryString from 'Browser/parseQueryString';
import Alert from 'Components/Alert';
import { hide, show, hideAll } from 'Components/domHelpers';
import MediaElementController from 'Components/MediaElementController';
import errorReport from 'Error/report';
import logger from 'Log/logger';

import appErrorHandler from './appErrorHandler';
import strings from './strings';
import errors from './errors';

const SUBPAGE_DEFAULT = 'lcm';

export default class SubpageDispatcher {
  constructor({ onSubpageChange, onDispatchError }) {
    this.log = logger('SubpageDispatcher');

    this._hashController = new HashController();

    this._subpages = {};
    this._currentSubpage = null;
    this._onSubpageChange = onSubpageChange;
    this._onDispatchError = onDispatchError;

    this.root = (
      <div>
        <div class="portal-loading loading-indicator-img" role="alert" aria-busy="true" ref={this._loading}></div>
        <Alert ref={this._alert}>
          <span ref={this._alertMessage}></span>
          <button type="button" class="btn btn-primary ml-3" onclick={() => this._reload()}>{strings.lblRetry}</button>
        </Alert>
      </div>
    );
  }

  addSubpage(subpageName, module, hash) {
    this._subpages[subpageName] = {
      module,
      hash,
    };
  }

  start() {
    this._hashController.on('change', hash => this._hashChange(hash));
    this._hashChange(window.location.hash);
  }

  stop() {
    this._detachCurrentSubpage();

    this._hashController.removeAllListeners();
    this._hashController.set('');

    hideAll('.subpage');
    document.body.classList.remove('body-print');
  }

  openDefaultSubpage() {
    this.log('openDefaultSubpage()');

    this.openSubpage(SUBPAGE_DEFAULT);
  }

  openSubpage(subpageName, subpageParams = {}, fromHash = false) {
    this.log(`openSubpage(${subpageName}) | params`, subpageParams);

    hideAll('.subpage');
    document.body.classList.remove('body-print');

    const currentSubpage = this._currentSubpage;

    this._detachCurrentSubpage();

    if (subpageName === null) {
      this._currentSubpage = currentSubpage;
    } else {
      this._currentSubpage = this._subpages[subpageName];
    }

    hide(this._alert);
    show(this._loading);

    const subpageModule = this._currentSubpage.module;

    Promise.resolve()
      .then(() => {
        subpageModule.processParams(subpageParams, fromHash);
        this._updateHash();
        this._onSubpageChange(subpageModule.getActiveTabKey());
      })
      .then(() => subpageModule.dispatch())
      .then(() => {
        this._updateHash();
        hide(this._loading);
        subpageModule.show();
      })
      .catch(err => {
        const handled = this._onDispatchError(err);
        if (handled)
          return;

        if (err.cancelled) {
          return;
        }

        this.log('openSubpage() | caught error', err);

        hide(this._loading);

        this._alertMessage.textContent = errors.ERR_UNKNOWN;
        show(this._alert);

        if (err instanceof ApiErrorNetwork) {
          // ignore ApiErrorNetwork
          return;
        }

        setTimeout(() => {
          errorReport.send('openSubpage() error', null, err);
        }, 0);
      });
  }

  _detachCurrentSubpage() {
    if (this._currentSubpage) {
      MediaElementController
        .stop()
        .catch(err => {
          if (err.cancelled) {
            return;
          }

          appErrorHandler(err);
        });

      this._currentSubpage.module.detach();
      this._currentSubpage = null;
    }
  }

  _reload() {
    this.openSubpage(null, this._currentSubpage.module.getCanonicalParams());
  }

  _updateHash() {
    this._hashController.set(this.getCanonicalHash());
  }

  get currentSubpage() {
    if (!this._currentSubpage) return null;
    return this._currentSubpage.module;
  }

  getCanonicalHash() {
    return `${this._currentSubpage.hash}${this._getCanonicalHashParams()}`;
  }

  _hashChange(hash) {
    this.log(`_hashChange(${hash})`);

    let { subpageHash = null, subpageParams = {} } = this._parseHash(hash);

    let subpageName;
    if (subpageHash) {
      subpageName = Object.keys(this._subpages).find(_ => this._subpages[_].hash === subpageHash);
    }

    if (!subpageName) {
      subpageName = SUBPAGE_DEFAULT;
      subpageParams = {};
    }

    this.openSubpage(subpageName, subpageParams, true);
  }

  _parseHash(hash) {
    const test = hash.match(/^#([a-zA-Z0-9_]+)(([/?])(.*))?$/);

    if (!test) {
      return false;
    }

    const [ , subpageHash, , delim, queryString ] = test;

    let subpageParams = {};
    if (delim && queryString) {
      if (delim === '/') {
        subpageParams = { id: decodeURIComponent(queryString) };
      } else {
        subpageParams = parseQueryString(queryString);
      }
    }

    return {
      subpageHash,
      subpageParams
    };
  }

  _getCanonicalHashParams() {
    const subpageParams = this._currentSubpage.module.getCanonicalParams();
    const paramKeys = Object.keys(subpageParams);
    // special case: empty params gets converted into
    // #subpageHash
    if (paramKeys.length === 0) {
      return '';
    }
    // special case: single 'id' param gets converted into
    // #subpageHash/<id> form
    if (paramKeys.length === 1 && subpageParams.id !== undefined) {
      return `/${encodeURIComponent(subpageParams.id)}`;
    }

    return `?${createQueryString(subpageParams)}`;
  }
}
