import { ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';
import Alert from 'Components/Alert';
import { FormCheckbox, FormSelect, FormText, RefFormData, wrapSubmitHandler } from 'Components/FormComponents';
import Modal from 'Components/Modal';
import { show, hide } from 'Components/domHelpers';
import { parseTimestamp, formatTimestamp } from 'DateTime';

import appErrorHandler from './appErrorHandler';
import ModalAlert from './ModalAlert';
import ModalConfirm from './ModalConfirm';

import errors from './errors';
import s from './strings';

export class RecordingDeleteModal extends ModalConfirm {
  constructor({ onSuccess }) {
    super({
      title: s.lblDialogTitleDeleteRecording,
      message: s.lblAreYouSureDeleteRecording,
      confirmLabel: s.lblDelete,
      confirm: () => {
        this._sendRequest();
      },
    });

    this._onSuccess = onSuccess;
  }

  _sendRequest() {
    const params = {
      cdrID: this._data,
    };

    Api.get('CDR', 'deleteRecording', params)
      .then(() => this._onSuccess())
      .catch(err => {
        ModalAlert.display(appErrorHandler(err));
      });
  }
}

export class RecordingOptionsModal {
  constructor({ onSuccess }) {
    const PLAYBACK_STARTMODE_OPTS = [
      {
        value: 'trimSilenceMode0',
        label: s.lblRecordingPlaybackOptionsStartMode_trimSilenceMode0,
      },
      {
        value: 'trimSilenceMode1',
        label: s.lblRecordingPlaybackOptionsStartMode_trimSilenceMode1,
      },
      {
        value: 'trimSilenceMode2',
        label: s.lblRecordingPlaybackOptionsStartMode_trimSilenceMode2,
      },
      {
        value: 'startAtOffset',
        label: s.lblRecordingPlaybackOptionsStartMode_startAtOffset,
      },
    ];

    this._form = new RefFormData();
    this._modal = new Modal({
      appendToBody: true,
      title: s.lblRecordingPlaybackOptions,
      children: (
        <form onsubmit={wrapSubmitHandler(() => this._saveOptions())}>
          <div class="modal-body">
            <Alert ref={this._alert} />
            <div class="form-horizontal">
              <FormText form={this._form} name="customID" label={s.conferenceRecording.customID} />
              <FormCheckbox form={this._form} name="playBackSelected" label={s.lblRecordingPlaybackOptionsSelected} />
              <FormCheckbox form={this._form} name="playBackDefault" label={s.lblRecordingPlaybackOptionsDefault} />
              <FormSelect form={this._form} name="playBackStartMode" label={s.lblRecordingPlaybackOptionsStartMode} options={PLAYBACK_STARTMODE_OPTS} />
              <FormText form={this._form} name="playBackStartOffset" label={s.lblRecordingPlaybackOptionsStartOffset} comment={s.lblRecordingPlaybackOptionsStartOffsetNote} />
              <FormText form={this._form} name="passcode" label={s.lblRecordingPlaybackOptionsPasscode} comment={s.lblRecordingPlaybackOptionsPasscodeNote} />
              <FormText form={this._form} name="activationDate" label={s.conferenceRecording.activationDate} comment={s.conferenceRecording.activationDateComment} />
              <FormText form={this._form} name="expirationDate" label={s.conferenceRecording.expirationDate} comment={s.conferenceRecording.expirationDateComment} />
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{s.lblSave}</button>
            <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblCancel}</button>
          </div>
        </form>
      ),
    });

    this._form
      .getInput('playBackStartMode')
      .onchange = () => this._setPlayBackStartMode();

    this._onSuccess = onSuccess;
  }

  display(recordingData, timezone) {
    this._cdrID = recordingData.cdrID;
    this._timezone = timezone;

    hide(this._alert);

    let { customID, playBackSelected, playBackDefault, playBackStartMode, playBackStartOffset, passcode, activationDate, expirationDate } = recordingData;

    if (playBackStartMode !== 'startAtOffset') {
      playBackStartOffset = 0;
    }

    this._form.setValues({
      customID,
      playBackSelected,
      playBackDefault,
      playBackStartMode,
      playBackStartOffset: formatTimestamp(playBackStartOffset),
      passcode,
      activationDate,
      expirationDate,
    });
    this._setPlayBackStartMode();
    this._modal.show();
  }

  _saveOptions() {
    const data = this._form.getAllValues();

    const { passcode, playBackStartMode, playBackStartOffset } = data;
    if (passcode.length && !passcode.match(/^\d{4,32}$/)) {
      this._showOptionsError('ERR_API_RECORDING_PASSCODE_INVALID');
      return;
    }

    let offset = 0;
    if (playBackStartMode === 'startAtOffset') {
      offset = parseTimestamp(playBackStartOffset);
      if (offset === false) {
        this._showOptionsError('ERR_API_RECORDING_START_OFFSET_INVALID');
        return;
      }
    }

    const parseDate = str => {
      if (!str)
        return null;

      const parsed = Date.parse(str);
      if (isNaN(parsed))
        return '';

      const date = new Date(parsed);
      const pad = val => val.toString().padStart(2, '0');
      return `${date.getFullYear()}-${pad(date.getMonth()+1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
    };

    const activationDate = parseDate(data.activationDate.trim());
    if (activationDate === '') {
      this._showOptionsError('ERR_API_RECORDING_ACTIVATION_DATE_INVALID');
      return;
    }

    const expirationDate = parseDate(data.expirationDate.trim());
    if (expirationDate === '') {
      this._showOptionsError('ERR_API_RECORDING_EXPIRATION_DATE_INVALID');
      return;
    }

    const params = {
      cdrID: this._cdrID,
      timezone: this._timezone,
      ...data,
      playBackStartOffset: offset,
      activationDate,
      expirationDate,
    };

    Api.get('CDR', 'setRecording', params)
      .then(() => this._modal.hide())
      .then(() => this._onSuccess())
      .catch(err => {
        let errorCode;
        if (err instanceof ApiErrorResult && err.isInvalidParamError()) {
          switch (err.parameterName) {
          case 'activationDate':
            errorCode = 'ERR_API_RECORDING_ACTIVATION_DATE_INVALID';
            break;

          case 'expirationDate':
            errorCode = 'ERR_API_RECORDING_EXPIRATION_DATE_INVALID';
            break;
          }
        }

        if (!errorCode)
          errorCode = appErrorHandler(err);

        this._showOptionsError(errorCode);
      });
  }

  _setPlayBackStartMode() {
    const { playBackStartMode } = this._form.getAllValues();
    this._form.setDisabled('playBackStartOffset', playBackStartMode !== 'startAtOffset');
  }

  _showOptionsError(code) {
    this._alert.textContent = errors[code];
    show(this._alert);
  }
}
