import DOMEventEmitter from './DOMEventEmitter';

const isAbortError = err => {
  if (!(err instanceof DOMException)) {
    return false;
  }
  if (err.name === 'AbortError') {
    return true;
  }
  // legacy test
  if ('ABORT_ERR' in DOMException && err.code === DOMException.ABORT_ERR) {
    return true;
  }
  return false;
};

export class MediaElement extends DOMEventEmitter {
  constructor(type = 'audio') {
    let el;

    switch (type) {
    case 'audio':
      el = new Audio();
      break;

    default:
      throw new Error('unknown type');
    }

    super(el);

    this.on('ended', () => {
      if (!this._element.paused) {
        // make browser behavior consistent. IE11 does not pause playback on ended event.
        this._element.pause();
      }
    });
  }

  load(url) {
    this._element.src = url;
    this._element.load();

    return this;
  }

  play() {
    if (this.playing) {
      return Promise.resolve();
    }

    const play = this._element.play();

    if (play && (play instanceof Promise || typeof play.then === 'function')) {
      return play
        .catch(err => {
          if (isAbortError(err)) {
            return;
          }

          throw err;
        });
    } else {
      return Promise.resolve();
    }
  }

  pause() {
    if (!this.playing) {
      return;
    }

    this._element.pause();
  }

  setSinkId(sinkId) {
    if (!('setSinkId' in this._element)) {
      return Promise.resolve();
    }

    return this._element.setSinkId(sinkId);
  }

  get playing() {
    return !this._element.paused && !this._element.ended;
  }

  get currentTime() {
    return this._element.currentTime;
  }

  set currentTime(value) {
    if (!this.duration) {
      // Ignore currentTime update if duration is falsey.
      //
      // IE throws InvalidStateError when duration is NaN. duration is
      // NaN prior to media load.
      // Regardless, it does not make much sense to set currentTime if
      // duration is 0/NaN/etc.
      return;
    }

    if (this._element.currentTime === value) {
      return;
    }

    this._element.currentTime = value;
  }

  get duration() {
    const duration = this._element.duration;

    if (typeof duration !== 'number' || isNaN(duration) || duration === Infinity) {
      return 0;
    }

    return duration;
  }

  get seeking() {
    return this._element.seeking;
  }

  get volume() {
    return this._element.volume;
  }

  set volume(value) {
    if (this._element.volume === value) {
      return;
    }

    this._element.volume = value;
  }

  get title() {
    return this._element.title;
  }

  set title(value) {
    if (this._element.title === value) {
      return;
    }
    this._element.title = value;
  }

  get bufferedTotal() {
    const { buffered } = this._element;

    if (buffered && buffered.length && this.duration > 0) {
      let total = 0;
      for (let i = 0; i < buffered.length; i++) {
        total += buffered.end(i) - buffered.start(i);
      }

      return total / this.duration;
    }

    return 0;
  }

  get error() {
    return this._element.error;
  }
}
