import Api from 'Api/Api';

import getRedirectErrorURL from './getRedirectErrorURL';

export default function getRecordingURLs(url, requestGroup, methodName, params) {
  if (url !== '') {
    return {
      srcURL: url,
      downloadURL: url,
    };
  }

  return {
    srcURL: Api.getURL(requestGroup, methodName, params, { returnHttpErrorCode: true }),
    downloadURL: Api.getURL(requestGroup, methodName, params, { returnHttpErrorCode: true, errorURL: getRedirectErrorURL() }),
  };
}
