import { NotFoundError } from 'CommonExceptions';
import Api from 'Api/Api';
import { ApiErrorResult } from 'Api/ApiErrors';
import Alert from 'Components/Alert';
import { show, hide } from 'Components/domHelpers';
import { RefFormData, FormGroup, FormHidden, FormText, FormCheckbox, FormTextarea, Cond, wrapSubmitHandler } from 'Components/FormComponents';

import Subpage from './Subpage';
import SubpagePanel from './SubpagePanel';
import appErrorHandler from './appErrorHandler';
import s from './strings';
import errors from './errors';

export default class UserID extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
        entryID: {
          key: 'id',
          defaultVal: 0,
          validateFunc: this.validateUInt,
        },
      },

      options: {
        back: {
          defaultVal: '',
        },
      }
    };
  }

  init() {
    const root = (
      <div class="subpage">
        <div class="subpage-content">
          <SubpagePanel title={s.lblUserID} onDismiss={() => this.back()}>
            <UserIDForm ctrl={this.ctrl} ref={this._userIDForm} onFinished={() => this.back()} />
          </SubpagePanel>
        </div>
      </div>
    );

    super.init(root, {
      defaultBackSubpage: 'users',
    });
  }

  activate() {
    return Promise.resolve()
      .then(() => {
        if (!this._params.entryID) {
          this._userIDForm.addEntry();
          return;
        }

        return this._userIDForm.editEntry(this._params.entryID);
      })
      .catch(err => {
        if (err instanceof NotFoundError) {
          this.displayError('ERR_USER_ID_NOT_FOUND', true);
          return;
        }

        throw err;
      });
  }
}

class UserIDForm extends RefFormData {
  static isClassComponent = true;

  constructor({ ctrl, onFinished, ref }) {
    super();

    if (ref) {
      ref(this);
    }

    this._ctrl = ctrl;
    this._notesFields = ctrl.notesFields;

    this._callDataPermForm = new RefFormData();

    this.root = (
      <form onsubmit={wrapSubmitHandler(() => this.save())}>
        <Alert ref={this._alert}/>
        <div class="form-horizontal">
          <FormHidden form={this} name="entryID" />
          <FormText form={this} name="userID" label={s.lblUserID} />
          <FormText form={this} name="name" label={s.lblName} inputAttributes={{ autocomplete: 'off' }} />
          <FormCheckbox form={this} name="blockedFlag" label={s.lblBlock} labelRight={s.lblUserBlockNote} />
          <FormCheckbox form={this} name="hostFlag" label={s.lblJoinAsHost} labelRight={s.lblUserHostNote} />
          <FormCheckbox form={this} name="holdFlag" label={s.lblJoinOnHold} />
          <Cond test={this._notesFields}>
            {Object.keys(this._notesFields).map(fieldName => <FormText form={this._callDataPermForm} name={fieldName} label={fieldName} inputAttributes={{ autocomplete: 'off' }} />)}
          </Cond>
          <FormTextarea form={this} name="notes" label={s.lblNotes} />
          <FormGroup>
            <div class="btn-toolbar-discrete">
              <button type="submit" class="btn btn-primary">{s.lblSave}</button>
              <button type="button" class="btn btn-primary" onclick={onFinished}>{s.lblCancel}</button>
            </div>
          </FormGroup>
        </div>
      </form>
    );

    this.onFinished = onFinished;
  }

  save() {
    const params = this.getAllValues();

    if (params.entryID) {
      params.editMode = 'modifyOnly';
    } else {
      params.editMode = 'createOnly';
      delete params.entryID;
    }

    if (this._notesFields) {
      this._callDataPermForm.getAllFields().forEach(field => {
        const { name } = field;
        if (!this._permissions[name].write)
          return;

        const val = this._callDataPermForm.get(name);
        if (val) {
          this._callDataPermOrig[name] = val;
        } else {
          delete this._callDataPermOrig[name];
        }
      });

      params.callDataPerm = Object.keys(this._callDataPermOrig).length
        ? this._callDataPermOrig
        : null;
    }

    Api.get('Bridge', 'setBridgeUserID', params)
      .then(() => {
        this.onFinished();
      })
      .catch(err => {
        let errorCode;
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError()) {
            switch (err.parameterName) {
            case 'userID':
              errorCode = 'ERR_INVALID_USERID';
              break;
            }
          }
        }

        if (!errorCode) {
          errorCode = appErrorHandler(err);
        }

        this.displayError(errorCode);
      });
  }

  _render(data) {
    hide(this._alert);

    this.getAllFields().forEach(field => {
      this.set(field.name, data && data[field.name] || '');
    });

    if (this._notesFields) {
      this._permissions = this._ctrl.notesFieldsPermissions;
      this._callDataPermOrig = data && data.callDataPerm || {};

      this._callDataPermForm.getAllFields().forEach(field => {
        const permission = this._permissions[field.name];

        if (permission.read)
          this._callDataPermForm.set(field.name, this._callDataPermOrig[field.name] || '');

        this._callDataPermForm.setVisibility(field.name, permission.read);
        this._callDataPermForm.getInput(field.name).disabled = !permission.write;
      });
    }
  }

  addEntry() {
    this._render();
  }

  editEntry(entryID) {
    const params = {
      entryID,
    };

    return Api.get('Bridge', 'getBridgeUserIDs', params)
      .then(res => {
        const bridgeUserID = res.bridgeUserID && res.bridgeUserID[0];
        if (!bridgeUserID) {
          throw new NotFoundError();
        }
        this._render(bridgeUserID);
      });
  }

  displayError(errorCode) {
    this._alert.textContent = errors[errorCode];
    show(this._alert);
  }
}
