import Modal from 'Components/Modal';
import TbIcons from 'Components/TbIcons';

import s from './strings';
import errors from './errors';

let _instance = null;
let _message;

export default class ModalAlert {
  static display(errCode) {
    // lazy instantiate modal
    if (!_instance) {
      _instance = new Modal({
        appendToBody: true,
        title: s.lblError,
        dialogClass: 'modal-icon modal-dialog-danger',
        children: (
          <>
            <div class="modal-body">
              <div class="icon">{TbIcons.ALERT_CIRCLE}</div>
              <div class="content" ref={_message}></div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" onclick={() => _instance.hide()}>{s.lblOK}</button>
            </div>
          </>
        ),
      });
    }

    _message.textContent = errors[errCode];
    _instance.show();
  }
}
