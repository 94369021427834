import { emptyNode, isScrolledToBottom, scrollToBottom } from 'Components/domHelpers';
import { Cond, RefFormData, FormCheckbox } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import { AudioAlertController } from 'Components/AudioUtils';
import TbIcons from 'Components/TbIcons';

import appErrorHandler from './appErrorHandler';
import ModalAlert from './ModalAlert';
import { SingleInputModal } from './ModalForm';
import { Table } from './Tables';
import s from './strings';
import errors from './errors';

const MAX_MESSAGE_LENGTH = 1024;

const AUDIO_ALERT_URL = 'audio/newMessage.wav';

export default class Chat {
  static isClassComponent = true;

  constructor({ ctrl }) {
    const form = new RefFormData();

    this.ctrl = ctrl;
    this.ctrl.on('update', () => this.render());

    const audioAlertController = new AudioAlertController(AUDIO_ALERT_URL, s.Chat.AudioAlertTitle);
    this.ctrl.on('newMessage', () => {
      if (form.get('enableAlert'))
        audioAlertController.play();
    });

    this._nameModal = new SingleInputModal({
      label: s.Chat.enterName,
      invalidErrorMsg: errors.ERR_INVALID_NAME_REQUIRED,
      onSave: data => this.ctrl.setName(data),
    });

    const openNameModal = () => {
      this._nameModal.display(this.ctrl.name);
    };

    const hooks = this.hooks = new Hooks();

    this.root = (
      <div class="app-chat">
        <div class="my-2">
          <div class="text-center" use:hook={hooks.hide('name')}>
            <button type="button" class="btn btn-primary w-100" onclick={openNameModal}>{s.Chat.openNameModal}</button>
          </div>
          <div class="app-chat-top-bar" use:hook={hooks.show('name')}>
            <div>
              <strong>{s.lblName}:</strong>
              {' '}
              <button
                type="button"
                class="btn btn-link"
                use:hook={hooks.textContent('name')}
                onclick={openNameModal}
              />
            </div>
            <span class="app-chat-top-bar-alert">
              <span class="tbicon">{TbIcons.VOLUME_UP}</span>
              <FormCheckbox inline form={form} name="enableAlert" />
            </span>
          </div>
        </div>
        <div class="panel panel-danger mt-3" use:hook={hooks.show('isDisabled')}>
          <div class="panel-heading">
            <h3 class="panel-title">{s.Chat.disabledTitle}</h3>
          </div>
          <div class="panel-body">
            {s.Chat.disabledMsg}
          </div>
        </div>
        <div class="my-2" use:hook={hooks.hide('isDisabled')}>
          <div class="app-chat-list-container">
            <div class="form-control">
              <ChatList ctrl={ctrl} ref={this._chatList} />
            </div>
          </div>
          <h4 use:hook={hooks.textContent('chatLabel')} />
          <div class="app-chat-body">
            <div class="app-chat-messages form-control" ref={this._messages}>
            </div>
            <div use:hook={hooks.show('name')}>
              <textarea class="form-control" name="messageInput" onkeyup={e => this._keyHandler(e)} onkeypress={e => this._keyHandler(e)} ref={this._messageInput} maxlength={MAX_MESSAGE_LENGTH}>
              </textarea>
              <div class="btn-toolbar-full">
                <button type="button" class="btn btn-primary" onclick={() => this._sendMessage()} ref={this._sendButton}>
                  {s.Chat.send}
                </button>
                <button type="button" class="btn btn-primary" onclick={() => this._saveChat()} use:hook={hooks.prop('isActive', 'disabled', val => !val)}>
                  {s.Chat.save}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { chats } = this.ctrl;

    this._chatList.render(chats);
    this._setButtonState();
    this._updateMessages();

    this.hooks.run(this.ctrl);
  }

  _sendMessage() {
    if (!this.ctrl.isActive || !this._isComposedMessageValid()) {
      return;
    }

    this.ctrl.sendMessage(this._composedMessage)
      .then(res => {
        this._messageInput.value = '';
      })
      .catch(err => {
        ModalAlert.display(appErrorHandler(err));
      });
  }

  _addMessage(msg) {
    const { data, tsDateDisplay } = msg;
    const { from, text } = data;
    const { type, name } = from;

    this._messages.append(
      <div class="message">
        <div class={`message-sender-${type}`}>
          ({tsDateDisplay}) <strong>{name}</strong> says:
        </div>
        <div>{text}</div>
      </div>
    );
  }

  _updateMessages() {
    emptyNode(this._messages);

    const msgs = this.ctrl.messages;
    const doScroll = isScrolledToBottom(this._messages);

    msgs.forEach(msg => {
      this._addMessage(msg);
    });

    if (doScroll) {
      scrollToBottom(this._messages);
    }
  }

  _saveChat() {
    const text = this.ctrl.getChatText();
    const href = `data:text/plain;chatset=utf-8,${encodeURIComponent(text)}`;

    const el = <a href={href} download="chat.txt" style={{display: 'none'}}></a>;
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  }

  _setButtonState() {
    this._sendButton.disabled = !this.ctrl.isActive || !this._isComposedMessageValid();
  }

  _isComposedMessageValid() {
    const message = this._composedMessage;
    return !!message;
  }

  get _composedMessage() {
    return this._messageInput.value.trim();
  }

  _keyHandler(e) {
    switch (e.type) {
    case 'keypress':
      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault();
        this._sendMessage();
      }
      break;

    case 'keyup':
      this._setButtonState();
      break;
    }
  }
}

class ChatList extends Table {
  constructor({ ctrl, ref }) {
    super({
      ref,
      className: 'app-chat-list',
      thead: false,
      itemKey: 'chatID',
      columns: [
        {
          colKey: [ 'label', 'isSelected', 'unreadCount' ],
          create(chat, extra, chatID) {
            return (
              <div classList={{'app-chat-list-item': true, 'app-chat-list-item-selected': chat.isSelected}} onclick={() => ctrl.setChatID(chatID)}>
                <div class="d-flex">
                  <Cond test={!chat.unreadCount}>
                    <span>{chat.label}</span>
                  </Cond>
                  <Cond test={chat.unreadCount}>
                    <strong>{chat.label}</strong>
                    <strong class="ml-auto">{chat.unreadCount}</strong>
                  </Cond>
                </div>
              </div>
            );
          },
        },
      ],
    });
  }
}
