// passive support detection adapted from
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Usage_notes

export default (() => {
  let passiveSupported = false;

  try {
    const options = {
      // This function will be called when the browser attempts to access
      // the passive property.
      get passive() {
        passiveSupported = true;
        return false;
      }
    };

    window.addEventListener('test', null, options);
    window.removeEventListener('test', null, options);
  } catch (err) {
    passiveSupported = false;
  }

  return passiveSupported;
})();
