import Api from 'Api/Api';
import { Hooks } from 'Components/Hooks';
import TbIcons from 'Components/TbIcons';

import ModalAlert from './ModalAlert';
import ModalConfirmDelete from './ModalConfirmDelete';
import Subpage from './Subpage';
import { initPaging, updatePaging } from './SubpagePaging';
import { SortableTable, Sorter } from './Tables';
import { PagingToolbar } from './Paging';
import { TableToolbar, SubpageSearchInput } from './LcmComponents';
import appErrorHandler from './appErrorHandler';
import s from './strings';

export default class Users extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
        search: {
          defaultVal:  '',
        },
      },

      sortColumnDefault: 'name',
      sortColumns: [
        'userID', 'name', 'blockedFlag', 'hostFlag'
      ],

      options: {
      }
    };

    this.pagingController = initPaging(this);

    this._modalDelete = new ModalConfirmDelete({
      title: s.lblDialogTitleDeleteUserID,
      messageTextPost: s.lblAreYouSureDeleteUserPost,

      onConfirm: params => {
        Api.get('Bridge', 'deleteBridgeUserID', params)
          .then(() => this.redispatch())
          .catch(err => {
            ModalAlert.display(appErrorHandler(err));
          });
      },
    });
  }

  init() {
    const hooks = this.hooks = new Hooks();

    const ctx = {
      hooks,
      ctrl: this,
    };

    const root = (
      <div class="subpage subpage-crudlist">
        <div class="subpage-content">
          <TableToolbar>
            <button type="button" class="btn btn-primary" onclick={() => this._openEntry()}>{s.lblAdd}</button>

            <SubpageSearchInput ctx={ctx} />
          </TableToolbar>

          <UsersTable ref={this._table} onSort={e => this._onSort(e)} onCellButtonClick={e => this._onCellButtonClick(e)} />
          <PagingToolbar ctrl={this.pagingController} />
        </div>
      </div>
    );

    super.init(root, {
      enableSortingParams: true,
    });

    this._notesFields = this.ctrl.notesFields;
  }

  activate() {
    return Api.get('Bridge', 'getBridgeUserIDs', {
      ...this.getStateValues([
        'startOffset', 'resultCount', 'search', 'sortColumn', 'sortDirection'
      ]),
    }).then(result => {
      this._result = result;
      this._result.items = result.bridgeUserID || [];

      if (!updatePaging(this, result.totalResults)) {
        return;
      }

      this.hooks.run({
        formData: {
          search: this._params.search,
        },
      });

      this.render();
    });
  }

  render() {
    const { items } = this._result;
    items.forEach(cur => {
      this._mungeBridgeUserID(cur);
    });

    const extra = {
    };

    this._table.clear();
    this._table.render(items, extra, this.sortProps);
  }

  _onSort({ sortColumn, sortDirection }) {
    this.changeSort(sortColumn, sortDirection);
  }

  _onCellButtonClick({ key: entryID, itemIdx, colId }) {
    const item = this._result.items[itemIdx];

    switch (colId) {
    case 'edit':
      this._openEntry(entryID);
      break;

    case 'delete':
      this._modalDelete.display({ entryID }, item.userID);
      break;
    }
  }

  _openEntry(entryID = null) {
    this.openSubpage('userID', {
      ...(entryID && { id: entryID }),
      back: this.getCanonicalHash(),
    });
  }

  _mungeBridgeUserID(obj) {
    let notesLabel = '';

    if (this._notesFields && obj.callDataPerm && typeof obj.callDataPerm === 'object') {
      const showFields = [];

      for (let field in this._notesFields) {
        const opts = this._notesFields[field];

        if (obj.callDataPerm[field] && ((this.ctrl.operatorFlag && opts.operatorShowInHandQueue) || (!this.ctrl.operatorFlag && opts.hostShowInHandQueue)))
          showFields.push(field);
      }

      if (showFields.length) {
        notesLabel +=
          ' [' + showFields.map(_ => obj.callDataPerm[_]).join(' ') + ']';
      }
    }

    obj.nameDisplay = obj.name + notesLabel;
  }
}

class UsersTable extends SortableTable {
  constructor({ ref, onSort, onCellButtonClick }) {
    super({
      ref,
      onCellButtonClick,
      className: 'nowrap striped dataTable subpage-table',
      itemKey: 'entryID',
      noDataString: s.lblNoData,
      onHeaderClick: e => {
        this._sorter.onHeaderClick(e);

        const [ sortProp ] = this._sorter.getSortProps();

        onSort({
          sortColumn: sortProp.colId,
          sortDirection: sortProp.order,
        });
      },
      columns: [
        {
          id: 'userID',
          colKey: 'userID',
          title: s.lblUserID,
          className: 'column-userID',
          sorting: true,
        },
        {
          id: 'name',
          colKey: 'nameDisplay',
          title: s.lblAssignedName,
          className: 'column-name',
          sorting: true,
        },
        {
          id: 'blockedFlag',
          colKey: 'blockedFlag',
          title: s.lblBlock,
          className: 'shrink',
          sorting: true,
          create(cell) {
            return cell ? s.lblYes : '';
          },
        },
        {
          id: 'hostFlag',
          colKey: 'hostFlag',
          title: s.lblHost,
          className: 'shrink',
          sorting: true,
          create(cell) {
            return cell ? s.lblYes : '';
          },
        },
        {
          id: 'edit',
          className: 'hover-cell shrink',
          create() {
            return <button type="button" class="btn-table-icon" title={s.lblEdit}>{TbIcons.EDIT}</button>;
          },
        },
        {
          id: 'delete',
          className: 'hover-cell shrink',
          create() {
            return <button type="button" class="btn-table-icon" title={s.lblDelete}>{TbIcons.TRASH}</button>;
          },
        },
      ],
    });

    this._sorter = new Sorter({
      table: this,
    });
  }

  render(data, extra, sortProps) {
    this._sorter.setSortProps(sortProps);
    this.renderSort(this._sorter.getSortProps());
    super.render(data, extra);
  }
}
