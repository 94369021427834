import { MediaElement } from './MediaElement';
import MediaElementSupport from 'Components/MediaElementSupport';

const LOADED_EVENT = 'canplaythrough';

function createCancellablePromise(executor) {
  let promiseReject;
  const promise = new Promise((resolve, reject) => {
    promiseReject = reject;
    executor(resolve, reject);
  });

  promise.cancel = () => {
    const err = new Error('cancelled');
    err.cancelled = true;
    promiseReject(err);
  };

  return promise;
}

export default class MediaElementController {
  static init() {
    if (!this.element) {
      this.element = new MediaElement();
    }
    return this;
  }

  static play(url, title = null, sinkId = null) {
    this.init();

    const { element } = this;

    if (title) {
      element.title = title;
    }

    if (this._pendingPromise) {
      this._pendingPromise.cancel();
      this._cleanup();
    }

    this._pendingPromise = createCancellablePromise((resolve, reject) => {
      Promise.resolve()
        .then(() => this._load(url))
        .then(() => {
          if (sinkId) {
            return element.setSinkId(sinkId)
              .catch(() => {
                // swallow errors
              });
          }
        })
        .then(() => element.play())
        .then(() => this._waitForEvent('ended'))
        .then(() => {
          this._cleanup();
          resolve();
        })
        .catch(err => {
          this._cleanup();
          reject(err);
        });
    });

    return this._pendingPromise;
  }

  static stop() {
    if (!this._pendingPromise) {
      return Promise.resolve();
    }

    this._pendingPromise.cancel();
    this._cleanup();

    this._pendingPromise = createCancellablePromise((resolve, reject) => {
      Promise.resolve()
        .then(() => this._load(MediaElementSupport.zeroLengthURL))
        .then(() => {
          this._cleanup();
          resolve();
        })
        .catch(err => {
          this._cleanup();
          reject(err);
        });
    });

    return this._pendingPromise;
  }

  static _load(url) {
    const { element } = this;

    return new Promise((resolve, reject) => {
      element.once(`${LOADED_EVENT} error`, e => {
        if (e.type === 'error') {
          reject(element.error);
          return;
        }

        resolve();
      }, { tag: this });
      element.load(url);
    });
  }

  static _waitForEvent(event) {
    const { element } = this;

    return new Promise(resolve => {
      element
        .once(event, () => {
          resolve();
        }, { tag: this });
    });
  }

  static _cleanup() {
    const { element } = this;

    this._pendingPromise = null;

    element.removeAllListeners(this);
  }
}
