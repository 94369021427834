import Overlay from 'Components/Overlay';

export default class LoadingIndicator extends Overlay {
  constructor(parent, { label = '' } = {}) {
    super(parent, true);

    this.root = (
      <div class="loading-indicator overlay">
        <div class="loading-indicator-img" role="alert" aria-busy="true"></div>
        {label && <strong>{label}</strong>}
      </div>
    );

    this._parent.appendChild(this.root);
  }
}
