import { ApiErrorResult } from 'Api/ApiErrors';
import { toggle } from 'Components/domHelpers';

import { CallerInfoModal } from './CallerInfoModal';
import { LcmActionPanel } from './LcmComponents';
import ModalAlert from './ModalAlert';
import appErrorHandler from './appErrorHandler';
import s from './strings';

export default class NameTranscribe extends LcmActionPanel {
  static isClassComponent = true;

  constructor({ ctrl, ref }) {
    super({
      buttonLabel: s.NameTranscribe.buttonLabel,
      onClick: () => this._modal.selectNext(),
    });

    ref(this);

    this._count = null;

    this._modal = new NameTranscribeModal({ ctrl });

    this._ctrl = ctrl;
  }

  render() {
    const { nameTranscribeCount: count } = this._ctrl;
    if (this._count === count)
      return;

    this._count = count;
    this.setMsg(`${s.NameTranscribe.msgPre}${count}${s.NameTranscribe.msgPost}`);
    toggle(this.root, this._count);
  }
}

class NameTranscribeModal {
  constructor({ ctrl }) {
    this._ctrl = ctrl;

    const cancel = () => this.cancel();

    this._modal = new CallerInfoModal({
      ctrl,
      footer: (
        <>
          <button type="submit" class="btn btn-primary">{s.NameTranscribe.saveAndSelectNext}</button>
          <button type="button" class="btn btn-primary" onclick={() => this.save()}>{s.lblSave}</button>
          <button type="button" class="btn btn-primary" onclick={cancel}>{s.lblCancel}</button>
        </>
      ),
      onSubmit: () => this.save(true),
      onClose: cancel,
    });
  }

  selectNext() {
    this._modal.showStart();

    this._ctrl.getNameTranscribe()
      .then(call => this._modal.showFinish(call))
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        this._modal.hide();

        let errorCode;
        if (err instanceof ApiErrorResult && err.code === 'ERR_API_EMPTY') {
          errorCode = 'ERR_NAME_TRANSCRIBE_EMPTY';
        }

        if (!errorCode) {
          errorCode = appErrorHandler(err);
        }

        ModalAlert.display(errorCode);
      });
  }

  cancel() {
    this._modal.hide();

    this._ctrl.putNameTranscribe(this._modal.form.get('callID'))
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        const errorCode = appErrorHandler(err);
        ModalAlert.display(errorCode);
      });
  }

  save(selectNext = false) {
    const data = this._modal.form.getData();

    this._modal.loading.show();

    Promise.resolve()
      .then(() => this._ctrl.saveCallerInfo(data.callID, data))
      .then(() => {
        this._modal.hide();
        if (selectNext)
          this.selectNext();
      })
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        const errorCode = appErrorHandler(err);
        this._modal.displayError(errorCode);
      })
      .then(() => this._modal.loading.hide());
  }
}
