/**
 * Gets an internal errorCode for a MediaError. Currently, the only
 * useful or consistent MediaError.code is MEDIA_ERR_NETWORK.
 *
 * @param {MediaError} err
 * @returns {string} internal errorCode
 */

export default function getMediaErrorCode(err) {
  if (err instanceof MediaError) {
    switch (err.code) {
    case MediaError.MEDIA_ERR_NETWORK:
      return 'ERR_NETWORK';
    }
  }

  return 'ERR_MEDIA_ERROR';
}
