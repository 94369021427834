import debug from 'debug';
import LogBuffer from './LogBuffer';

const bufferMap = new Map();

let buffers = {};
let rules = [];

export function configure(logConfig) {
  buffers = {};
  logConfig.buffers.forEach(_ => {
    buffers[_.name] = new LogBuffer(_.size);
  });

  rules = logConfig.rules.map(_ => {
    const splits = _.pattern.split(/[\s,]+/);
    const keep = [];
    const skip = [];

    splits.forEach(split => {
      if (!split)
        return;

      const newPattern = split.replace(/\*/g, '.*?');
      if (newPattern[0] === '-') {
        skip.push(new RegExp('^' + newPattern.substr(1) + '$'));
      } else {
        keep.push(new RegExp('^' + newPattern + '$'));
      }
    });

    return {
      ..._,
      keep,
      skip
    };
  });

  for (let namespace of bufferMap.keys()) {
    const destBuf = getDestinationBuffer(namespace);
    if (destBuf)
      bufferMap.set(namespace, destBuf);
  }
}

function getDestinationBuffer(namespace) {
  const match = rules.find(_ => {
    if (_.skip.find(pat => !!pat.test(namespace))) {
      return false;
    }

    if (_.keep.find(pat => !!pat.test(namespace))) {
      return true;
    }

    return false;
  });

  if (match)
    return buffers[match.buffer];

  return false;
}

export default function logger(namespace) {
  const destBuf = getDestinationBuffer(namespace);
  bufferMap.set(namespace, destBuf || null);

  const debugFn = debug(namespace);

  return function(...args) {
    const buffer = bufferMap.get(namespace);
    if (buffer) {
      buffer.write(namespace, ...args);
    }

    debugFn(...args);
  };
}

export function getBuffers() {
  const ret = {};

  Object.keys(buffers).forEach(_ => {
    ret[_] = buffers[_].read();
  });

  return ret;
}
