import { Cond } from 'Components/FormComponents';

import { PanelDismissButton } from './LcmComponents';

export default class SubpagePanel {
  static isClassComponent = true;

  constructor({ title = '', onDismiss = null, children, small, ref }) {
    if (ref) {
      ref(this);
    }

    this.root = (
      <div class={`panel panel-primary subpage-panel${small ? ' subpage-panel-sm' : ''}`}>
        <div class="panel-heading">
          <Cond test={onDismiss}>
            <PanelDismissButton onclick={onDismiss} />
          </Cond>
          <h3 class="panel-title">{title}</h3>
        </div>
        <div class="panel-body">
          {children}
        </div>
      </div>
    );
  }
}
