export function isWebRtcSupported() {
  return getBrowserFeatures().webRtc;
}

export function isWebAudioSupported() {
  if (window.webkitAudioContext && !window.AudioContext) {
    const matches = navigator.userAgent.match(/AppleWebKit\/\d+\..* Version\/(\d+)\./);
    if (matches && matches[1] && matches[1] === '11') {
      return false;
    }
  }

  const AudioContext = window.AudioContext || window.webkitAudioContext;
  if (AudioContext.prototype.createMediaStreamDestination) {
    return true;
  }

  return false;
}

export function getBrowserFeatures() {
  const ret = {};

  ret.https = window.location.protocol === 'https:';

  ret.AudioContext = !!(window.AudioContext || window.webkitAudioContext);
  ret.RTCPeerConnection = !!window.RTCPeerConnection;
  ret.MediaStream = !!window.MediaStream;
  ret.MediaStream_getTrackById = ret.MediaStream && 'getTrackById' in window.MediaStream.prototype;
  ret.MediaStream_active = ret.MediaStream && 'active' in window.MediaStream.prototype; // Firefox >= 52
  ret.RTCPeerConnection_valid = !!(window.RTCPeerConnection && window.RTCPeerConnection.prototype.addTrack);
  ret.getUserMedia = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);

  ret.webRtc = !!(
    ret.AudioContext &&
    ret.RTCPeerConnection &&
    ret.MediaStream &&
    ret.MediaStream_getTrackById &&
    ret.MediaStream_active &&
    ret.RTCPeerConnection_valid &&
    ret.getUserMedia &&
    ret.https
  );

  ret.webAudio = ret.webRtc && isWebAudioSupported();

  return ret;
}
