export default function parseQueryString(str) {
  var match;
  var search = /([^&=]+)=?([^&]*)/g;
  var decode = function (s) { return decodeURIComponent(s.replace(/\+/g, ' ')); };
  var ret = {};

  while ((match = search.exec(str)))
    ret[decode(match[1])] = decode(match[2]);

  return ret;
}
